import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form, Checkbox } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import Password from "antd/lib/input/Password";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { redirectIfLoggedIn } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";
class Login extends React.Component {
  state = {
    btnLoading: false,
  };

  componentDidMount() {
    redirectIfLoggedIn();
  }

  onFinish = (values) => {
    let { username, password } = this.state;
    this.setState({ btnLoading: true });
    let cognitoResponse = null;

    Auth.signIn(username, password)
      .then((response) => {
        cognitoResponse = response;
        localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          cognitoResponse.signInUserSession.idToken.jwtToken
        );
        localStorage.setItem(
          "auth_data",
          JSON.stringify(cognitoResponse.attributes)
        );

        SuccessNotificationMsg(
          "Succesfully logged in!",
          "Redirecting you in a few!"
        );
        window.location.href = "/dashboard";
        return false;
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <Row className="login_inr_wrp">
        <Col className="formdiv">
          <div className="pagename">
            <span>Login</span>
          </div>
          {/* <p>Log in to save your favourite products.</p> */}

          <Form onFinish={this.onFinish}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid e-mail!",
                    },
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input E-mail!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email Address *"
                    onChange={this.handleInputChange("username")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input Password!",
                    },
                  ]}
                >
                  <Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password *"
                    onChange={this.handleInputChange("password")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item className="checkwrap">
                  <Checkbox>Keep me logged in on this computer</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="loginbtn"
                  loading={this.state.btnLoading}
                >
                  Login
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <div className="btnparent">
                  <Link to="/register" className="button-link">
                    New ! - Regsiter Here
                  </Link>
                  <Link to="/verify-account" className="button-link">
                    Verify Account
                  </Link>
                  <Link to="/forgot-password" className="button-link">
                    Forgot your password?
                  </Link>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col className="loginimg_div"></Col>
      </Row>
    );
  }
}

export default withApollo(Login);
