import gql from "graphql-tag";

export const ADD_PAY_CHANGE = gql`
  mutation createPayChangeRecord(
    $personId: String!
    $changeReason: String
    $jobTitle: String
    $effectiveDate: String
    $compensation: String
    $per: String
    $frequency: String
    $periods: String
    $annual: String
    $changeAmount: String
    $changePercent: String
    $currency: String
    $notes: String
  ) {
    createPayChangeRecord(
      personId: $personId
      data: {
        changeReason: $changeReason
        jobTitle: $jobTitle
        effectiveDate: $effectiveDate
        compensation: $compensation
        per: $per
        frequency: $frequency
        periods: $periods
        annual: $annual
        changeAmount: $changeAmount
        changePercent: $changePercent
        currency: $currency
        notes: $notes
      }
    )
  }
`;

export const PAY_CHANGE_LIST_QUERY = gql`
  query getEmployeePayChangeRecords($personId: String!) {
    getEmployeePayChangeRecords(personId: $personId) {
      payChangeId
      personId
      changeReason
      jobTitle
      effectiveDate
      compensation
      per
      frequency
      periods
      annual
      changeAmount
      changePercent
      currency
      notes
    }
  }
`;

export const GET_PAY_CHANGE_QUERY = gql`
  query getEmployeePayChangeRecordById($recordId: String!) {
    getEmployeePayChangeRecordById(recordId: $recordId) {
      personId
      changeReason
      jobTitle
      effectiveDate
      compensation
      per
      frequency
      periods
      annual
      changeAmount
      changePercent
      currency
      notes
    }
  }
`;

export const UPDATE_PAY_CHANGE = gql`
  mutation updatePayChangeRecord(
    $personId: String!
    $changeReason: String
    $jobTitle: String
    $effectiveDate: String
    $compensation: String
    $per: String
    $frequency: String
    $periods: String
    $annual: String
    $changeAmount: String
    $changePercent: String
    $currency: String
    $notes: String
  ) {
    updatePayChangeRecord(
      personId: $personId
      data: {
        changeReason: $changeReason
        jobTitle: $jobTitle
        effectiveDate: $effectiveDate
        compensation: $compensation
        per: $per
        frequency: $frequency
        periods: $periods
        annual: $annual
        changeAmount: $changeAmount
        changePercent: $changePercent
        currency: $currency
        notes: $notes
      }
    )
  }
`;

export const GET_PAY_CHANGE_HISTORY = gql`
  query getEmployeePayChangeRecordHistoryById($recordId: String!) {
    getEmployeePayChangeRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        changeReason
        jobTitle
        effectiveDate
        compensation
        per
        frequency
        periods
        annual
        changeAmount
        changePercent
        currency
        notes
        recordSource
      }
    }
  }
`;

export const DELETE_PAY_CHANGE = gql`
  mutation deletePayChangeRecord($recordId: String!) {
    deletePayChangeRecord(recordId: $recordId)
  }
`;
