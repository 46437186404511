import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { GET_APPLICANT_QUERY, UPDATE_APPLICANT } from "./ApplicantQuery";

const { Content } = Layout;

class AddApplicant extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "applicantFirstName",
      "applicantLastName",
      "applicantMiddleName",
      "applicantZip",
      "applicantCountry",
      "applicantState",
      "applicantCity",
      "applicantPhone",
      "applicantEmail",
      "whereDidYouHearAboutThisCompany",
      "applicantGender",
      "applicantEthnicOrigin",
      "applicantCitizenship",
      "jobScore",
      "applicantStatus",
      "rejectedReason",
      "changeStatus",
    ],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getApplicantDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    this.updateApplicant();
  };
  
  updateApplicant = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_APPLICANT,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Applicant updated successfully.");
        this.formRef.current.resetFields();
        this.props.history.push("/manage-applicant", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getApplicantDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_APPLICANT_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeeApplicantRecordById);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeApplicantRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const { fieldArr, currentRoute, apiLoading, btnLoading, personId } =
      this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-applicant" ? "Add" : "Edit"} Applicant :{" "}
                {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Applicant
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-applicant" ? "Add" : "Edit"}{" "}
                    Applicant
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddApplicant);
