import React, { Component } from "react";
import { jsonToCSV } from "react-papaparse";
import { Link } from "react-router-dom";
import { Spin, Space } from "antd";
import { withApollo } from "react-apollo";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";
import { VIEW_REPORT_QUERY } from "./ReportingToolQuery";
class ReportPDF extends Component {
  state = {
    showReport: false,
    apiLoading: false,
    reportFields: [],
    reportData: [],
    columnArr: [],
    analysisData: [],
    analysisColumnArr: [],
    exportType: "",
  };

  getReport = (reportType) => {
    const exportType = reportType;
    this.setState({ apiLoading: true });

    this.props.client
      .mutate({
        mutation: VIEW_REPORT_QUERY,
        variables: { reportId: this.props.reportId },
      })
      .then((response) => {
        if (
          response.data.viewReport.rows &&
          response.data.viewReport.rows.length > 0
        ) {
          let colArr = Object.keys(response.data.viewReport.rows[0]);
          let columnArr = [];
          colArr.forEach((element) => {
            columnArr.push({
              title: CamelCaseToSpaceLetter(element),
              dataIndex: element,
              key: element,
            });
          });

          let analysisColumnArr = [];

          if (
            response.data.viewReport.analysis &&
            response.data.viewReport.analysis.length > 0
          ) {
            let analysisArr = Object.keys(response.data.viewReport.analysis[0]);
            analysisArr.forEach((element) => {
              analysisColumnArr.push({
                title: CamelCaseToSpaceLetter(element),
                dataIndex: element,
                key: element,
              });
            });
          }

          this.setState({
            reportData: response.data.viewReport.rows,
            analysisData: response.data.viewReport.analysis,
            reportFields: Object.keys(response.data.viewReport.rows[0]),
            apiLoading: false,
            columnArr,
            analysisColumnArr,
          });

          if (exportType === "pdf") {
            this.exportPDF();
          } else {
            const ss = jsonToCSV(this.state.reportData);
            let read = ss.toString("utf8");
            const url = window.URL.createObjectURL(new Blob([read]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              this.props.title.replace(/ /g, "_") + ".csv"
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        } else {
          this.setState({
            apiLoading: false,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  exportPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
    });

    doc.setFontSize(14);
    doc.setTextColor(100);
    doc.text(this.props.title, 0.4, 0.3);

    let fsize = 11;
    if (this.state.columnArr.length > 15) {
      fsize = 4;
    } else if (
      this.state.columnArr.length <= 15 ||
      this.state.columnArr.length <= 8
    ) {
      fsize = 7;
    } else {
      fsize = 11;
    }

    doc.autoTable({
      bodyStyles: { fontSize: fsize, overflow: "linebreak" },
      headStyles: { fontSize: fsize },
      margin: { top: 0.5, left: 0.4 },
      columns: this.state.columnArr,
      body: this.state.reportData,
    });

    doc.autoTable({
      bodyStyles: { fontSize: fsize, overflow: "linebreak" },
      headStyles: { fontSize: fsize },
      margin: { top: 0.2, left: 0.4 },
      columns: this.state.analysisColumnArr,
      body: this.state.analysisData,
    });

    doc.save(this.props.title.replace(/ /g, "_") + ".pdf");
  };

  render() {
    const { apiLoading } = this.state;
    return (
      <>
        {apiLoading ? (
          <Spin />
        ) : (
          <Space>
            <Link
              to="#"
              onClick={() => this.getReport("pdf")}
              title="Export Report PDF"
              className="action"
            >
              <FilePdfOutlined />
            </Link>
            <Link
              to="#"
              onClick={() => this.getReport("csv")}
              title="Export Report CSV"
              className="action"
            >
              <FileExcelOutlined />
            </Link>
          </Space>
        )}
      </>
    );
  }
}

export default withApollo(ReportPDF);
