import gql from "graphql-tag";

export const ADD_SAFETY = gql`
  mutation createSafetyRecord(
    $personId: String!
    $oshaLocation: String
    $policeReport: String
    $injuryType: String
    $initialTreatment: String
    $injury: String
    $skinDisorder: String
    $poisoning: String
    $hearingLoss: String
    $otherIllness: String
    $death: String
    $otherRecordableCases: String
    $respiratoryCondition: String
    $timeEmployeeBeganWork: String
    $dateOfDeath: String
    $policeReport2: String
    $dayOfWeek: String
    $weatherCondition: String
    $incidentLocation: String
    $incidentCity: String
    $incidentCause: String
    $hoursLost: String
    $dateReceived: Date
    $reportedBy: String
    $incidentDescription: String
    $osha: String
    $dot: String
    $privacyCase: String
    $strikethrough: String
    $incidentState: String
    $description: String
    $physicianClaimID: String
    $physicianSreet2: String
    $facilityClaimID: String
    $facilityStreet1: String
    $facilityStreet2: String
    $treatment2: String
    $treatment3: String
    $accidentType: String
    $equipment: String
    $superIntendent: String
    $foreman: String
    $operations: String
    $job: String
    $causes: String
    $towed: String
    $facilityCity: String
    $facilityStateOrProvince: String
    $facilityCountryOrRegion: String
    $facilityZipOrPostalCode: String
    $physicianCountry: String
    $caseNumber: String
    $incidentDate: Date
    $medicalCosts: String
    $incidentTime: String
    $advisor: String
    $caseClosedOn: String
    $completedBy: String
    $jobTitle: String
    $workPhone: String
    $filedOn: String
    $employeeTreatedInAnEmergencyRoom: String
    $employeeHospitalizedOvernightAsAnInPatient: String
    $physician: String
    $facility: String
    $physicianStateOrProvince: String
    $physicianStreet1: String
    $physicianCity: String
    $physicianZipOrPostalCode: String
    $claimType: String
    $outcome: String
    $jobTransferOrRestriction: String
    $daysAwayFromWork: String
    $notReported: String
  ) {
    createSafetyRecord(
      personId: $personId
      data: {
        oshaLocation: $oshaLocation
        policeReport: $policeReport
        injuryType: $injuryType
        initialTreatment: $initialTreatment
        injury: $injury
        skinDisorder: $skinDisorder
        poisoning: $poisoning
        hearingLoss: $hearingLoss
        otherIllness: $otherIllness
        death: $death
        otherRecordableCases: $otherRecordableCases
        respiratoryCondition: $respiratoryCondition
        timeEmployeeBeganWork: $timeEmployeeBeganWork
        dateOfDeath: $dateOfDeath
        policeReport2: $policeReport2
        dayOfWeek: $dayOfWeek
        weatherCondition: $weatherCondition
        incidentLocation: $incidentLocation
        incidentCity: $incidentCity
        incidentCause: $incidentCause
        hoursLost: $hoursLost
        dateReceived: $dateReceived
        reportedBy: $reportedBy
        incidentDescription: $incidentDescription
        osha: $osha
        dot: $dot
        privacyCase: $privacyCase
        strikethrough: $strikethrough
        incidentState: $incidentState
        description: $description
        physicianClaimID: $physicianClaimID
        physicianSreet2: $physicianSreet2
        facilityClaimID: $facilityClaimID
        facilityStreet1: $facilityStreet1
        facilityStreet2: $facilityStreet2
        treatment2: $treatment2
        treatment3: $treatment3
        accidentType: $accidentType
        equipment: $equipment
        superIntendent: $superIntendent
        foreman: $foreman
        operations: $operations
        job: $job
        causes: $causes
        towed: $towed
        facilityCity: $facilityCity
        facilityStateOrProvince: $facilityStateOrProvince
        facilityCountryOrRegion: $facilityCountryOrRegion
        facilityZipOrPostalCode: $facilityZipOrPostalCode
        physicianCountry: $physicianCountry
        caseNumber: $caseNumber
        incidentDate: $incidentDate
        medicalCosts: $medicalCosts
        incidentTime: $incidentTime
        advisor: $advisor
        caseClosedOn: $caseClosedOn
        completedBy: $completedBy
        jobTitle: $jobTitle
        workPhone: $workPhone
        filedOn: $filedOn
        employeeTreatedInAnEmergencyRoom: $employeeTreatedInAnEmergencyRoom
        employeeHospitalizedOvernightAsAnInPatient: $employeeHospitalizedOvernightAsAnInPatient
        physician: $physician
        facility: $facility
        physicianStateOrProvince: $physicianStateOrProvince
        physicianStreet1: $physicianStreet1
        physicianCity: $physicianCity
        physicianZipOrPostalCode: $physicianZipOrPostalCode
        claimType: $claimType
        outcome: $outcome
        jobTransferOrRestriction: $jobTransferOrRestriction
        daysAwayFromWork: $daysAwayFromWork
        notReported: $notReported
      }
    )
  }
`;

export const SAFETY_LIST_QUERY = gql`
  query getEmployeeSafetyRecords($personId: String!) {
    getEmployeeSafetyRecords(personId: $personId) {
      safetyRecordId
      personId
      oshaLocation
      policeReport
      injuryType
      initialTreatment
      injury
      skinDisorder
      poisoning
      hearingLoss
      otherIllness
      death
      otherRecordableCases
      respiratoryCondition
      timeEmployeeBeganWork
      dateOfDeath
      policeReport2
      dayOfWeek
      weatherCondition
      incidentLocation
      incidentCity
      incidentCause
      hoursLost
      dateReceived
      reportedBy
      incidentDescription
      osha
      dot
      privacyCase
      strikethrough
      incidentState
      description
      physicianClaimID
      physicianSreet2
      facilityClaimID
      facilityStreet1
      facilityStreet2
      treatment2
      treatment3
      accidentType
      equipment
      superIntendent
      foreman
      operations
      job
      causes
      towed
      facilityCity
      facilityStateOrProvince
      facilityCountryOrRegion
      facilityZipOrPostalCode
      physicianCountry
      caseNumber
      incidentDate
      medicalCosts
      incidentTime
      advisor
      caseClosedOn
      completedBy
      jobTitle
      workPhone
      filedOn
      employeeTreatedInAnEmergencyRoom
      employeeHospitalizedOvernightAsAnInPatient
      physician
      facility
      physicianStateOrProvince
      physicianStreet1
      physicianCity
      physicianZipOrPostalCode
      claimType
      outcome
      jobTransferOrRestriction
      daysAwayFromWork
      notReported
    }
  }
`;

export const GET_SAFETY_QUERY = gql`
  query getEmployeeSafetyRecordById($recordId: String!) {
    getEmployeeSafetyRecordById(recordId: $recordId) {
      personId
      oshaLocation
      policeReport
      injuryType
      initialTreatment
      injury
      skinDisorder
      poisoning
      hearingLoss
      otherIllness
      death
      otherRecordableCases
      respiratoryCondition
      timeEmployeeBeganWork
      dateOfDeath
      policeReport2
      dayOfWeek
      weatherCondition
      incidentLocation
      incidentCity
      incidentCause
      hoursLost
      dateReceived
      reportedBy
      incidentDescription
      osha
      dot
      privacyCase
      strikethrough
      incidentState
      description
      physicianClaimID
      physicianSreet2
      facilityClaimID
      facilityStreet1
      facilityStreet2
      treatment2
      treatment3
      accidentType
      equipment
      superIntendent
      foreman
      operations
      job
      causes
      towed
      facilityCity
      facilityStateOrProvince
      facilityCountryOrRegion
      facilityZipOrPostalCode
      physicianCountry
      caseNumber
      incidentDate
      medicalCosts
      incidentTime
      advisor
      caseClosedOn
      completedBy
      jobTitle
      workPhone
      filedOn
      employeeTreatedInAnEmergencyRoom
      employeeHospitalizedOvernightAsAnInPatient
      physician
      facility
      physicianStateOrProvince
      physicianStreet1
      physicianCity
      physicianZipOrPostalCode
      claimType
      outcome
      jobTransferOrRestriction
      daysAwayFromWork
      notReported
    }
  }
`;

export const UPDATE_SAFETY = gql`
  mutation updateSafetyRecord(
    $personId: String!
    $oshaLocation: String
    $policeReport: String
    $injuryType: String
    $initialTreatment: String
    $injury: String
    $skinDisorder: String
    $poisoning: String
    $hearingLoss: String
    $otherIllness: String
    $death: String
    $otherRecordableCases: String
    $respiratoryCondition: String
    $timeEmployeeBeganWork: String
    $dateOfDeath: String
    $policeReport2: String
    $dayOfWeek: String
    $weatherCondition: String
    $incidentLocation: String
    $incidentCity: String
    $incidentCause: String
    $hoursLost: String
    $dateReceived: Date
    $reportedBy: String
    $incidentDescription: String
    $osha: String
    $dot: String
    $privacyCase: String
    $strikethrough: String
    $incidentState: String
    $description: String
    $physicianClaimID: String
    $physicianSreet2: String
    $facilityClaimID: String
    $facilityStreet1: String
    $facilityStreet2: String
    $treatment2: String
    $treatment3: String
    $accidentType: String
    $equipment: String
    $superIntendent: String
    $foreman: String
    $operations: String
    $job: String
    $causes: String
    $towed: String
    $facilityCity: String
    $facilityStateOrProvince: String
    $facilityCountryOrRegion: String
    $facilityZipOrPostalCode: String
    $physicianCountry: String
    $caseNumber: String!
    $incidentDate: Date
    $medicalCosts: String
    $incidentTime: String
    $advisor: String
    $caseClosedOn: String
    $completedBy: String
    $jobTitle: String
    $workPhone: String
    $filedOn: String
    $employeeTreatedInAnEmergencyRoom: String
    $employeeHospitalizedOvernightAsAnInPatient: String
    $physician: String
    $facility: String
    $physicianStateOrProvince: String
    $physicianStreet1: String
    $physicianCity: String
    $physicianZipOrPostalCode: String
    $claimType: String
    $outcome: String
    $jobTransferOrRestriction: String
    $daysAwayFromWork: String
    $notReported: String
  ) {
    updateSafetyRecord(
      personId: $personId
      data: {
        oshaLocation: $oshaLocation
        policeReport: $policeReport
        injuryType: $injuryType
        initialTreatment: $initialTreatment
        injury: $injury
        skinDisorder: $skinDisorder
        poisoning: $poisoning
        hearingLoss: $hearingLoss
        otherIllness: $otherIllness
        death: $death
        otherRecordableCases: $otherRecordableCases
        respiratoryCondition: $respiratoryCondition
        timeEmployeeBeganWork: $timeEmployeeBeganWork
        dateOfDeath: $dateOfDeath
        policeReport2: $policeReport2
        dayOfWeek: $dayOfWeek
        weatherCondition: $weatherCondition
        incidentLocation: $incidentLocation
        incidentCity: $incidentCity
        incidentCause: $incidentCause
        hoursLost: $hoursLost
        dateReceived: $dateReceived
        reportedBy: $reportedBy
        incidentDescription: $incidentDescription
        osha: $osha
        dot: $dot
        privacyCase: $privacyCase
        strikethrough: $strikethrough
        incidentState: $incidentState
        description: $description
        physicianClaimID: $physicianClaimID
        physicianSreet2: $physicianSreet2
        facilityClaimID: $facilityClaimID
        facilityStreet1: $facilityStreet1
        facilityStreet2: $facilityStreet2
        treatment2: $treatment2
        treatment3: $treatment3
        accidentType: $accidentType
        equipment: $equipment
        superIntendent: $superIntendent
        foreman: $foreman
        operations: $operations
        job: $job
        causes: $causes
        towed: $towed
        facilityCity: $facilityCity
        facilityStateOrProvince: $facilityStateOrProvince
        facilityCountryOrRegion: $facilityCountryOrRegion
        facilityZipOrPostalCode: $facilityZipOrPostalCode
        physicianCountry: $physicianCountry
        caseNumber: $caseNumber
        incidentDate: $incidentDate
        medicalCosts: $medicalCosts
        incidentTime: $incidentTime
        advisor: $advisor
        caseClosedOn: $caseClosedOn
        completedBy: $completedBy
        jobTitle: $jobTitle
        workPhone: $workPhone
        filedOn: $filedOn
        employeeTreatedInAnEmergencyRoom: $employeeTreatedInAnEmergencyRoom
        employeeHospitalizedOvernightAsAnInPatient: $employeeHospitalizedOvernightAsAnInPatient
        physician: $physician
        facility: $facility
        physicianStateOrProvince: $physicianStateOrProvince
        physicianStreet1: $physicianStreet1
        physicianCity: $physicianCity
        physicianZipOrPostalCode: $physicianZipOrPostalCode
        claimType: $claimType
        outcome: $outcome
        jobTransferOrRestriction: $jobTransferOrRestriction
        daysAwayFromWork: $daysAwayFromWork
        notReported: $notReported
      }
    )
  }
`;

export const GET_SAFETY_HISTORY = gql`
  query getEmployeeSafetyRecordHistoryById($recordId: String!) {
    getEmployeeSafetyRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        oshaLocation
        policeReport
        injuryType
        initialTreatment
        injury
        skinDisorder
        poisoning
        hearingLoss
        otherIllness
        death
        otherRecordableCases
        respiratoryCondition
        timeEmployeeBeganWork
        dateOfDeath
        policeReport2
        dayOfWeek
        weatherCondition
        incidentLocation
        incidentCity
        incidentCause
        hoursLost
        dateReceived
        reportedBy
        incidentDescription
        osha
        dot
        privacyCase
        strikethrough
        incidentState
        description
        physicianClaimID
        physicianSreet2
        facilityClaimID
        facilityStreet1
        facilityStreet2
        treatment2
        treatment3
        accidentType
        equipment
        superIntendent
        foreman
        operations
        job
        causes
        towed
        facilityCity
        facilityStateOrProvince
        facilityCountryOrRegion
        facilityZipOrPostalCode
        physicianCountry
        caseNumber
        incidentDate
        medicalCosts
        incidentTime
        advisor
        caseClosedOn
        completedBy
        jobTitle
        workPhone
        filedOn
        employeeTreatedInAnEmergencyRoom
        employeeHospitalizedOvernightAsAnInPatient
        physician
        facility
        physicianStateOrProvince
        physicianStreet1
        physicianCity
        physicianZipOrPostalCode
        claimType
        outcome
        jobTransferOrRestriction
        daysAwayFromWork
        notReported
        recordSource
      }
    }
  }
`;

export const DELETE_SAFETY = gql`
  mutation deleteSafetyRecord($recordId: String!) {
    deleteSafetyRecord(recordId: $recordId)
  }
`;
