import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Breadcrumb,
  Upload,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
  ImportErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { authUserData } from "../../utils/Helpers";
import "../dashboard/Dashboard.scss";

import { IMPORT_DATA } from "./ImportQuery";
import recordTypes from "./recordTypes.json";

const { Content } = Layout;
const { Option } = Select;
class csvImport extends Component {
  state = {
    btnLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    selectedRecordLabel: "Personal Information Record",
    selectedRecordValue: "PersonalInformation",
    selectedRecordFormatURL:
      "https://canopy-test-bucket.s3.us-east-2.amazonaws.com/sampleCSVHeaders/v2/personalInformation-header.csv",
  };

  uploadFile = ({ file, onSuccess, onError }) => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: IMPORT_DATA,
        variables: {
          file: file,
          recordType: this.state.selectedRecordValue,
        },
      })
      .then((response) => {
        if (response.data.importData.hasOwnProperty("errors")) {
          let errorsArr = response.data.importData.errors;
          errorsArr.forEach((error) => {
            ImportErrorNotificationMsg("Error", error);
            this.setState({ btnLoading: false });
          });
        } else {
          SuccessNotificationMsg("Success", "Data imported successfully.");
          this.setState({ btnLoading: false });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  onSelectChange = (e) => {
    let recordObj = recordTypes.find((o) => o.value === e);
    this.setState({
      selectedRecordLabel: recordObj.label,
      selectedRecordValue: recordObj.value,
      selectedRecordFormatURL: recordObj.formatURL,
    });
  };

  render() {
    const uploadProps = {
      multiple: false,
      listType: "text",
      showUploadList: false,
      progress: false,
    };
    const {
      btnLoading,
      selectedRecordLabel,
      selectedRecordFormatURL,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Import from CSV
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Import from CSV</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="quotewraper uploadwrap">
                  <Form onFinish={this.onFinish}>
                    <Row gutter={[15, 30]}>
                      <Col xs={24} sm={24} lg={24} className="uploadcol">
                        <Select
                          showSearch
                          style={{ width: 280 }}
                          defaultValue="PersonalInformation"
                          optionFilterProp="children"
                          onChange={(e) => this.onSelectChange(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {recordTypes.map((record) => (
                            <Option value={record.value} key={record.value}>
                              {record.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>

                    <Row gutter={[15]}>
                      <Col xs={24} sm={24} lg={24} className="uploadcol">
                        <label>
                          Download Sample CSV Header File
                          <br /> For {selectedRecordLabel} <br /> From{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkhere"
                            href={selectedRecordFormatURL}
                          >
                            Here.
                          </a>{" "}
                        </label>
                        <Upload
                          {...uploadProps}
                          customRequest={this.uploadFile}
                          accept=".csv"
                        >
                          <Button
                            icon={<UploadOutlined />}
                            size="small"
                            loading={btnLoading}
                          >
                            Click to Upload
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(csvImport);
