import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Table, Input } from "antd";
import { debounce } from "lodash";
import { withApollo } from "react-apollo";

import EmployeeListSetting from "./EmployeeListSetting";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

import { EMPLOYEE_LIST_QUERY } from "../employee/EmployeeQuery";

const { Search } = Input;
class EmployeeList extends Component {
  state = {
    datas: [],
    search: null,
    loading: false,
    perPageRecord: 10,
    orderBy: {
      sortingField: "createdAt",
      sortingOrder: "DESC",
    },
    column: [
      {
        title: "Employee No",
        key: "personId",
        render: (node) => (
          <Link to={"/employee-detail/" + btoa(node.personId)}>
            {node.personId}
          </Link>
        ),
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "name",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Email",
        dataIndex: "homeEmail",
        key: "homeEmail",
      },
      {
        title: "Mobile Number",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
    ],
  };

  componentDidMount() {
    this.fetchEmployeeList({
      orderBy: this.state.orderBy,
      first: this.state.perPageRecord,
    });
    this.changeGrid();

    this.searchCallback = debounce(function (e) {
      this.searchEmployee(e.target.value);
    }, 500);
  }

  fetchEmployeeList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: EMPLOYEE_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.getAllEmployees.rows,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  changeGrid = () => {
    let defaultGridFields = [
      "personId",
      "firstName",
      "lastName",
      "mobileNumber",
      "homeEmail",
      "zipOrPostalCode",
      "city",
      "stateOrProvince",
    ];
    let fieldsList;
    let grid_fields = localStorage.getItem("grid_fields");

    if (grid_fields === null || grid_fields === "" || grid_fields === "null") {
      localStorage.setItem("grid_fields", JSON.stringify(defaultGridFields));
      fieldsList = defaultGridFields;
    } else {
      fieldsList = JSON.parse(grid_fields);
    }

    let result = [];
    fieldsList.map((field) => {
      if (field === "personId") {
        result.push({
          title: "Employee No",
          key: "personId",
          render: (node) => (
            <Link to={"/employee-detail/" + btoa(node.personId)}>
              {node.personId}
            </Link>
          ),
        });
      } else {
        result.push({ title: CamelCaseToSpaceLetter(field), dataIndex: field });
      }
      return null;
    });

    this.setState({ column: result });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchEmployee = (searchText) => {
    if (searchText !== "") {
      this.fetchEmployeeList({
        search: searchText,
        orderBy: this.state.orderBy,
        first: 10,
        offset: 0,
      });

      this.setState({ searchText, offset: 0 });
    } else {
      this.fetchEmployeeList({
        orderBy: this.state.orderBy,
        first: 10,
        offset: 0,
      });
      this.setState({ searchText: "", offset: 0, first: 10 });
    }
  };

  render() {
    const { data, loading, column } = this.state;
    return (
      <>
        <div className="noProduct">
          <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
            <Col flex="auto">
              <h3>Employee List</h3>
            </Col>
            <Col flex="auto" align="end">
              <div className="statshead settingWrp">
                <div className="searchhwrap">
                  <Search
                    placeholder="search.."
                    onChange={this.searchCallbackDelayed}
                  />
                </div>
                <EmployeeListSetting onChangeGrid={this.changeGrid} />
              </div>
            </Col>
          </Row>
        </div>
        <Table
          className="table_grid"
          columns={column}
          dataSource={data}
          scroll={{ x: 970 }}
          pagination={false}
          loading={loading}
          rowKey={(record) => record.personId}
        />
      </>
    );
  }
}

export default withApollo(EmployeeList);
