/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:fab447c4-77f3-4090-b01c-9b000ec5d5bf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_LKMRqAag2",
    "aws_user_pools_web_client_id": "41pg3etd4a1aouh5h0g1ttfa1p",
    "oauth": {}
};


export default awsmobile;
