import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Drawer, Button, Menu, Dropdown } from "antd";

import { validateToken, logout } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import "antd/dist/antd.css";
import "./Common.scss";
import LogoSmall from "../../styles/LogoSmall";
import Profilepic from "../../images/profilephoto.png";
import {
  MenuOutlined,
  UserOutlined,
  BellOutlined,
  AppstoreOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const { Header, Footer, Content } = Layout;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/dashboard">
        <i className="menu-icon">
          <AppstoreOutlined />
        </i>{" "}
        Dashboard
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/myaccount">
        <i className="menu-icon">
          <UserOutlined />
        </i>{" "}
        My Account
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="#" onClick={() => logout()}>
        <i className="menu-icon">
          <LogoutOutlined />
        </i>{" "}
        Logout
      </Link>
    </Menu.Item>
  </Menu>
);
export class FrontLayout extends Component {
  state = {
    loginStatus: false,
    visible: false,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    const loginStatus = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY)
    );
    this.setState({ loginStatus });
  }

  render() {
    return (
      <Layout className="front">
        <Header>
          <div className="container">
            <Row align="middle">
              <Col flex="auto">
                <Link to="/" className="logo">
                  <LogoSmall />
                </Link>
                <div className="topmenu">
                  <Link to="/">Login</Link>
                </div>
              </Col>
              <Col align="end" className="rightside">
                {!this.state.loginStatus ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>
                    <Link to="/dashboard" className="headerbtn">
                      <BellOutlined />
                    </Link>
                    <div className="profilebtn">
                      <Dropdown overlay={menu} placement="bottomRight">
                        <div className="imgd profile-placeholder">
                          <img
                            className="cardicon"
                            alt="icon"
                            src={Profilepic}
                          ></img>
                        </div>
                      </Dropdown>
                    </div>
                  </React.Fragment>
                )}
                <div className="site-drawer-render-in-current-wrapper mobilemenu">
                  <Button
                    className="headerbtn"
                    type="link"
                    onClick={this.showDrawer}
                  >
                    <MenuOutlined />
                  </Button>

                  <Drawer
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    getContainer={false}
                  >
                    <Link to="/login">Login</Link>
                  </Drawer>
                </div>
              </Col>
            </Row>
          </div>
        </Header>

        <Content>{this.props.children}</Content>

        <Footer>
          <div className="container">
            <Row gutter={[15]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Link to="/" className="logo">
                  <LogoSmall />
                </Link>

                {/* <h3>Follow Us</h3> */}
                {/* <ul className="socialicons">
                  <li>
                    <Link to="#">
                      <FacebookOutlined />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <TwitterOutlined />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <InstagramOutlined />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <LinkedinOutlined />
                    </Link>
                  </li>
                </ul> */}
              </Col>
              <Col xs={24} sm={12} md={8} lg={9}>
                <h3>Quick Links</h3>
                <ul className="footerlinks">
                  {/* <li>
                    <Link to="#">Men</Link>
                  </li>
                  <li>
                    <Link to="#">About</Link>
                  </li>
                  <li>
                    <Link to="#">Women</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                  <li>
                    <Link to="#">Brands</Link>
                  </li> */}
                </ul>
              </Col>
              <Col xs={24} sm={24} md={8} lg={9}>
                <h3>Get in touch</h3>
                {/* <p>
                  We collect and calculate the impact of all sustainable
                  garments online presenting them on one site so you can easily
                  find what you are looking for.
                </p> */}
                <a href="mailto:info@canopy.com" className="link">
                  info@canopy.com
                </a>
              </Col>
            </Row>
          </div>
        </Footer>
      </Layout>
    );
  }
}

export default FrontLayout;
