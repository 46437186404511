import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

// fake data generator
const getItems = (fieldsArr) =>
  fieldsArr.map((k) => ({
    id: k,
    content: CamelCaseToSpaceLetter(k === "personId" ? "Employee No" : k),
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "#c5cada" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#d6dae6" : "#f5f5f5",
});
class EmployeeListSetting extends Component {
  state = {
    visible: false,
    fields: [
      "personId",
      "firstName",
      "middleName",
      "suffix",
      "alias",
      "salutation",
      "street1",
      "street2",
      "city",
      "stateOrProvince",
      "zipOrPostalCode",
      "homeEmail",
      "homePhone",
      "mobileNumber",
      "ssnOrsin",
      "birthDate",
      "gender",
      "driverLicenceNumber",
      "driverLicenceState",
      "driverLicenceExpires",
      "maritalStatus",
      "lastName",
      "countryOrRegion",
      "licenceCountry",
      "cellProviderType",
      "legalSuffix",
    ],
    items: [],
    selected: [],
  };

  loadComponentLogic = () => {
    let allFields = this.state.fields;
    let apifields = JSON.parse(localStorage.getItem("grid_fields"));
    var filtered = [];

    if (apifields.length > 0) {
      filtered = allFields.filter(function (value) {
        return !apifields.includes(value);
      });
    } else {
      filtered = allFields;
    }

    let remainFields = getItems(filtered);
    let selectedFields = getItems(apifields);
    this.setState({ items: remainFields, selected: selectedFields });
  };

  getItems = (fieldsArr) => {
    fieldsArr.map((k) => ({
      id: k,
      content: CamelCaseToSpaceLetter(k),
    }));
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: "items",
    droppable2: "selected",
  };

  getList = (id) => this.state[this.id2List[id]];

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });

    this.loadComponentLogic();
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = () => {
    let grid_fields = this.state.selected;
    let gridFieldsArr = [];

    grid_fields.map((item) => {
      gridFieldsArr.push(item.id);
      return null;
    });

    localStorage.setItem("grid_fields", JSON.stringify(gridFieldsArr));
    this.setState(
      {
        visible: false,
      },
      () => {
        this.props.onChangeGrid();
      }
    );
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <div className="settingdiv">
        <Link to="#" onClick={this.showModal}>
          <SettingOutlined />
        </Link>
        <Modal
          title="Set Table Fields"
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          header={false}
          wrapClassName="stngwrp"
        >
          <div className="draghead">
            <h3>All Fields</h3>
            <h3>Selected Fields</h3>
          </div>
          <div className="scrolldiv">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.content}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="droppable2">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.state.selected.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.content}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EmployeeListSetting;
