import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Collapse,
  Col,
  Row,
  Layout,
  Button,
  Space,
  Form,
  Breadcrumb,
  Input,
} from "antd";

import ReportFieldOrder from "./ReportFieldOrder";
import TransferTreeInput from "./TransferTreeInput";
import Report from "./Report";
import ReportFilters from "./ReportFilters";

import { CamelCaseToSpaceLetter } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import {
  CREATE_REPORT,
  GET_REPORT_BY_ID,
  UPDATE_REPORT,
  GET_REPORT_FIELDS_QUERY,
  GET_FIELD_RECOMMENDATIONS,
} from "./ReportingToolQuery";

const { Content } = Layout;
const { Panel } = Collapse;

function callback(key) {
  //console.log(key);
}
class CreateReport extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    currentRoute: this.props.location.pathname,
    showReport: false,
    refreshCount: 0,
    preview: false,
    searchOptions: [],
    allFields: [],
    targetKeys: [],
    reportId: null,
    reportName: null,
    reportCategory: null,
    treeData: [],
    selectedFields: [],
    appliedFilters: [],
    analysis: [],
    reportViewSortBy: [],
    displayOptions: {
      companyName: false,
      logo: false,
      reportGenerationDate: false,
      signatureBlock: false,
    },
    previewReportResponse: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.getTreeData();

    let reportId = this.props.match.params.reportId
      ? atob(this.props.match.params.reportId)
      : "";

    if (reportId !== "") {
      this.getReportData(reportId);
      this.setState({ reportId });
    }

    this.searchCallback = debounce(function (searchText, index) {
      this.getFieldList(searchText, index);
    }, 300);
  }

  onFinish = (values) => {
    if (
      this.state.reportId === "" ||
      this.state.reportId === null ||
      this.state.reportId === undefined
    ) {
      this.saveReport();
    } else {
      this.updateReport();
    }
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  getTreeData = () => {
    this.setState({ apiLoading: true });
    this.props.client
      .mutate({
        mutation: GET_REPORT_FIELDS_QUERY,
      })
      .then((result) => {
        let treeData = [];

        if (result.data.getReportWriterFormData) {
          result.data.getReportWriterFormData.map((recordType, x) => {
            let tableTree = {};
            let tableTreeChildern = [];

            tableTree["key"] = recordType.recordId;
            tableTree["title"] = recordType.recordName;
            tableTree["disabled"] = true;

            recordType.fields.map((recordField, k) => {
              let [tname, fname] = recordField.fieldId.split(".");

              tableTreeChildern.push({
                key: recordField.fieldId,
                title:
                  CamelCaseToSpaceLetter(tname.substr(0, tname.length - 7)) +
                  " > " +
                  CamelCaseToSpaceLetter(fname),
              });

              return null;
            });

            tableTree["children"] = tableTreeChildern;
            treeData.push(tableTree);
            return treeData;
          });
        }
        this.setState({ apiLoading: false, treeData });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  showErrorMessage = (e) => {
    let apiErros = e?.networkError?.result?.errors;
    if (apiErros) {
      apiErros.forEach((error) => {
        if (
          error.message.includes("got invalid value") &&
          error.message.includes("appliedFilters")
        ) {
          ErrorNotificationMsg(
            "Please fill filter fields correctly or remove them."
          );
        }

        if (
          error.message.includes("got invalid value") &&
          error.message.includes("analysis")
        ) {
          ErrorNotificationMsg(
            "Please fill analysis fields correctly or remove them."
          );
        }

        if (
          error.message.includes("got invalid value") &&
          error.message.includes("reportViewSortBy")
        ) {
          ErrorNotificationMsg(
            "Please fill sorting fields correctly or remove them."
          );
        }
      });
    } else {
      ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
    }
  };

  saveReport = () => {
    if (this.state.selectedFields.length === 0) {
      ErrorNotificationMsg("Please select fields for generate report.");
      return false;
    }

    let reportViewSortBy = this.state.reportViewSortBy;
    let appliedFilters = this.state.appliedFilters;

    if (
      this.state.appliedFilters.length > 0 &&
      this.state.appliedFilters[0].startsWith === "" &&
      this.state.appliedFilters[0].endsWith === ""
    ) {
      appliedFilters = [];
    }

    const { client } = this.props;
    this.setState({ btnLoading: true, showReport: false, preview: false });

    client
      .mutate({
        mutation: CREATE_REPORT,
        variables: { ...this.state, appliedFilters, reportViewSortBy },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Report saved successfully.");
        this.setState({
          btnLoading: false,
          showReport: true,
          reportId: result.data.createReport.reportId,
        });
      })
      .catch((e) => {
        this.showErrorMessage(e);
        this.setState({ btnLoading: false });
      });
  };

  previewReport = () => {
    if (this.state.selectedFields.length === 0) {
      ErrorNotificationMsg("Please select fields for generate report.");
      return false;
    }

    let reportViewSortBy = this.state.reportViewSortBy;
    let appliedFilters = this.state.appliedFilters;

    if (
      this.state.appliedFilters.length > 0 &&
      this.state.appliedFilters[0].startsWith === "" &&
      this.state.appliedFilters[0].endsWith === ""
    ) {
      appliedFilters = [];
    }

    const { client } = this.props;
    this.setState({ previewLoading: true, showReport: false });

    client
      .mutate({
        mutation: CREATE_REPORT,
        variables: {
          ...this.state,
          appliedFilters,
          reportViewSortBy,
          preview: true,
        },
      })
      .then((result) => {
        this.setState({
          previewReportResponse: result,
          showReport: true,
          preview: true,
          previewLoading: false,
        });
      })
      .catch((e) => {
        this.showErrorMessage(e);
        this.setState({ previewLoading: false });
      });
  };

  getReportData = (reportId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });

    client
      .mutate({
        mutation: GET_REPORT_BY_ID,
        variables: { reportId },
      })
      .then((response) => {
        let reportViewSortBy = this.state.reportViewSortBy;
        if (response.data.getReportById.reportViewSortBy !== 0) {
          reportViewSortBy = response.data.getReportById.reportViewSortBy;
        }

        let appliedFilters = this.state.appliedFilters;
        if (response.data.getReportById.appliedFilters.length !== 0) {
          appliedFilters = response.data.getReportById.appliedFilters;
        }

        let analysis = this.state.analysis;
        if (response.data.getReportById.analysis.length !== 0) {
          analysis = response.data.getReportById.analysis;
        }

        this.setState({
          ...response.data.getReportById,
          apiLoading: false,
          reportViewSortBy,
          appliedFilters,
          analysis,
        });
        this.showSelectedFields(response.data.getReportById.selectedFields);
        this.formRef.current.setFieldsValue(response.data.getReportById);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  showSelectedFields = (selectedFields) => {
    if (selectedFields && selectedFields.length > 0) {
      let newSelectedFields = [];
      selectedFields.forEach((element) => {
        newSelectedFields.push(...element.fields);
      });
      this.setState({
        targetKeys: newSelectedFields,
        allFields: newSelectedFields,
      });
    }
  };

  updateReport = () => {
    let appliedFilters = this.state.appliedFilters;
    if (
      this.state.appliedFilters.length > 0 &&
      this.state.appliedFilters[0].startsWith === "" &&
      this.state.appliedFilters[0].endsWith === ""
    ) {
      appliedFilters = [];
    }

    let reportViewSortBy = this.state.reportViewSortBy;
    if (
      this.state.reportViewSortBy.length > 0 &&
      this.state.reportViewSortBy[0] &&
      this.state.reportViewSortBy[0].sortingField === ""
    ) {
      reportViewSortBy = null;
    }

    const { client } = this.props;
    this.setState({ btnLoading: true, preview: false });

    client
      .mutate({
        mutation: UPDATE_REPORT,
        variables: { ...this.state, appliedFilters, reportViewSortBy },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Report updated successfully.");
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        this.showErrorMessage(e);
        this.setState({ btnLoading: false });
      });
  };

  handleViewReport = () => {
    this.setState({
      apiLoading: false,
      showReport: true,
      refreshCount: this.state.refreshCount + 1,
    });
  };

  mergeTreeStats = (selectedFields) => {
    let ss = selectedFields;
    let reponseFormat = this.preparePostData(selectedFields);
    this.setState({ selectedFields: reponseFormat, allFields: ss });
  };

  preparePostData = (fieldArr) => {
    let resultArr = [];

    fieldArr.forEach((field) => {
      let fieldObject = {};
      let splitArr = field.split(".");

      let tableName = splitArr[0];
      let fieldIndex = resultArr.findIndex((el) => el.recordId === tableName);

      if (fieldIndex === -1) {
        fieldObject["recordId"] = tableName;
        fieldObject["fields"] = [];
        fieldObject["fields"].push(field);
        resultArr.push(fieldObject);
      } else {
        resultArr[fieldIndex]["fields"].push(field);
      }
    });
    return resultArr;
  };

  addFilter = () => {
    this.setState(
      {
        appliedFilters: [
          ...this.state.appliedFilters,
          {
            startsWith: "BLANK",
            filterField: "",
            endsWith: "BLANK",
            operator: "",
            value: "",
          },
        ],
      },
      () => {
        this.setFieldValue(this.state.appliedFilters);
      }
    );
  };

  handleFilterChange = (e, index) => {
    const newApplyFilterList = [...this.state.appliedFilters];
    newApplyFilterList[index][e.target.name] = e.target.value;
    this.setState({ appliedFilters: newApplyFilterList });
  };

  handleFilterSelectChange = (name, input, index) => {
    let selected = this.state.appliedFilters;
    if (selected[index]) {
      selected[index][name] = input;
      this.setState({ appliedFilters: selected }, () => {});
    }
  };

  removeFilter = async (index) => {
    if (this.state.appliedFilters.length === 1) {
      this.setState({ appliedFilters: [] }, () => {
        this.setFieldValue(this.state.appliedFilters);
      });
    } else {
      this.state.appliedFilters.splice(index, 1);
      this.setState({ appliedFilters: this.state.appliedFilters }, () => {
        this.setFieldValue(this.state.appliedFilters);
      });
    }
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["startsWith" + objectKey] = objectValue.startsWith;
      mcFieldObject["filterField" + objectKey] = objectValue.filterField;
      mcFieldObject["operator" + objectKey] = objectValue.operator;
      mcFieldObject["value" + objectKey] = objectValue.value;
      mcFieldObject["endsWith" + objectKey] = objectValue.endsWith;
      return null;
    });
    //this.productFormRef.current.setFieldsValue(mcFieldObject);
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState((prevState) => ({
      displayOptions: {
        ...prevState.displayOptions,
        [input]: event.target.checked,
      },
    }));
  };

  // ================Analysis Start========================

  addAnalysis = () => {
    this.setState(
      {
        analysis: [
          ...this.state.analysis,
          {
            field: "",
            operator: "",
            displaySubTotal: false,
            summary: false,
          },
        ],
      },
      () => {
        this.setAnalysisFieldValue(this.state.analysis);
      }
    );
  };

  setAnalysisFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["field" + objectKey] = objectValue.field;
      mcFieldObject["operator" + objectKey] = objectValue.operator;
      return null;
    });
  };

  removeAnalysis = async (index) => {
    if (this.state.analysis.length === 1) {
      this.setState({ analysis: [] }, () => {
        this.setAnalysisFieldValue(this.state.analysis);
      });
    } else {
      this.state.analysis.splice(index, 1);
      this.setState({ analysis: this.state.analysis }, () => {
        this.setAnalysisFieldValue(this.state.analysis);
      });
    }
  };

  handleAnalysisSelectChange = (name, input, index) => {
    let selected = this.state.analysis;
    if (selected[index]) {
      selected[index][name] = input;
      this.setState({ analysis: selected }, () => {});
    }
  };

  // ================ Sorting Start ========================

  addSorting = () => {
    this.setState(
      {
        reportViewSortBy: [
          ...this.state.reportViewSortBy,
          {
            sortingField: "",
            sortingOrder: "",
          },
        ],
      },
      () => {
        this.setSortingFieldValue(this.state.reportViewSortBy);
      }
    );
  };

  setSortingFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["sortingField" + objectKey] = objectValue.sortingField;
      mcFieldObject["sortingOrder" + objectKey] = objectValue.sortingOrder;
      return null;
    });
  };

  removeSorting = async (index) => {
    if (this.state.reportViewSortBy.length === 1) {
      this.setState({ reportViewSortBy: [] }, () => {
        this.setSortingFieldValue(this.state.reportViewSortBy);
      });
    } else {
      this.state.reportViewSortBy.splice(index, 1);
      this.setState({ reportViewSortBy: this.state.reportViewSortBy }, () => {
        this.setSortingFieldValue(this.state.reportViewSortBy);
      });
    }
  };

  handleSortingSelectChange = (name, input, index) => {
    let selected = this.state.reportViewSortBy;
    if (selected[index]) {
      selected[index][name] = input;
      this.setState({ reportViewSortBy: selected }, () => {});
    }
  };

  onSearchFieldValue = (searchText, index) => {
    //e.persist();
    this.getFieldList(searchText, index);
  };

  getFieldList = (searchText, index) => {
    if (
      this.state.appliedFilters[index].filterField &&
      this.state.appliedFilters[index].filterField !== ""
    ) {
      const { client } = this.props;
      client
        .query({
          query: GET_FIELD_RECOMMENDATIONS,
          variables: {
            fieldId: this.state.appliedFilters[index].filterField,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          let mcStatArr = [];

          if (response.data.getReportWriterFieldRecommendations.length) {
            response.data.getReportWriterFieldRecommendations.map(
              (objectValue) => {
                let mcFieldObject = {};
                mcFieldObject["label"] = objectValue;
                mcFieldObject["value"] = objectValue;
                mcFieldObject["id"] = objectValue;

                mcStatArr.push(mcFieldObject);
                return null;
              }
            );
          }
          this.setState({ searchOptions: mcStatArr });
        })
        .catch((e) => {
          ErrorNotificationMsg(
            "Error",
            e.message.replace("GraphQL error:", "")
          );
          return false;
        });
    }
  };

  changeGrid = (data) => {
    let reponseFormat = this.preparePostData(data);
    this.setState({
      selectedFields: reponseFormat,
      allFields: data,
      targetKeys: data,
    });
  };

  render() {
    const { btnLoading, currentRoute, previewLoading } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Report
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Report</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper ">
                <div className="ReportPage">
                  <Form onFinish={this.onFinish} ref={this.formRef}>
                    <Collapse defaultActiveKey={["1"]} onChange={callback}>
                      <Panel
                        header={
                          currentRoute === "/create-report"
                            ? "Add Report"
                            : "Edit Report"
                        }
                        key="1"
                      >
                        <Row gutter={16}>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <label>Report Name :*</label>
                            <Space>
                              <Form.Item
                                name="reportName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Report Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  onChange={this.handleInputChange(
                                    "reportName"
                                  )}
                                  placeholder="Report Name"
                                />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <label>Category :*</label>
                            <Form.Item
                              name="reportCategory"
                              rules={[
                                {
                                  required: true,
                                  message: "Report Category is required",
                                },
                              ]}
                            >
                              <Input
                                onChange={this.handleInputChange(
                                  "reportCategory"
                                )}
                                placeholder="Category"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <label>Fields in Form:</label>{" "}
                            <TransferTreeInput
                              mergeStats={this.mergeTreeStats}
                              selectedFields={this.state.selectedFields}
                              stateValues={this.state}
                            />
                            <div className="fields-heading">
                              <ReportFieldOrder
                                onChangeGrid={this.changeGrid}
                                selectedFields={this.state.allFields}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Panel>

                      <Panel
                        header="Report Filters"
                        key="2"
                        className="filtercollapse"
                      >
                        <ReportFilters
                          appliedFilters={this.state.appliedFilters}
                          addFilter={this.addFilter}
                          removeFilter={this.removeFilter}
                          handleFilterChange={this.handleFilterChange}
                          stateValues={this.state}
                          handleFilterSelectChange={
                            this.handleFilterSelectChange
                          }
                          handleCheckboxChange={this.handleCheckboxChange}
                          addAnalysis={this.addAnalysis}
                          removeAnalysis={this.removeAnalysis}
                          handleAnalysisSelectChange={
                            this.handleAnalysisSelectChange
                          }
                          addSorting={this.addSorting}
                          removeSorting={this.removeSorting}
                          handleSortingSelectChange={
                            this.handleSortingSelectChange
                          }
                          onSearchFieldValue={this.onSearchFieldValue}
                        />
                      </Panel>
                    </Collapse>
                    <div className="btnrow">
                      <Space>
                        {this.state.selectedFields.length ? (
                          <Button
                            type="primary"
                            htmlType="button"
                            onClick={this.previewReport}
                            loading={previewLoading}
                          >
                            Preview Report
                          </Button>
                        ) : (
                          ""
                        )}

                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={btnLoading}
                        >
                          Save Report
                        </Button>
                      </Space>
                    </div>
                  </Form>

                  {this.state.showReport ? (
                    <Report
                      title={this.state.reportName}
                      stateValues={this.state}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(CreateReport);
