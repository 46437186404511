import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Drawer, Table, Row, Col, Form } from "antd";
import { CamelCaseToSpaceLetter, ToDateTime } from "../../utils/Helpers";
import { ClockCircleOutlined } from "@ant-design/icons";

const HistoryDetail = ({ title, historyData }) => {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [hashRecord, setHashRecord] = useState({});

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onClose2 = () => {
    setVisible2(false);
  };

  const showDrawer2 = (historyRecord) => {
    delete historyRecord.data.__typename;
    setHashRecord(historyRecord);
    setVisible2(true);
  };

  const prepareHistory = (ss) => {
    let previousValue = "";
    let historyResult = [];

    let newHistoryArr = [...historyData].reverse();
    newHistoryArr.map((history) => {
      if (previousValue !== history.data[title]) {
        historyResult.push(history);
        previousValue = history.data[title];
      }
      return null;
    });
    return historyResult.reverse();
  };

  const columns = [
    {
      title: "Version",
      dataIndex: "metadata",
      render: (metadata) => `${metadata.version}`,
    },
    {
      title: "Property Value",
      dataIndex: "data",
      render: (data) => `${data[title] === undefined ? "-" : data[title]}`,
    },
    {
      title: "Record Source",
      dataIndex: "data",
      render: (data) => `${data.recordSource}`,
    },
    {
      title: "Transaction Time",
      dataIndex: "metadata",
      render: (metadata) => `${ToDateTime(metadata.txTime)}`,
    },
    {
      title: "Hash",
      width:"200px",
      render: (record) => (
        <Link to="#" onClick={() => showDrawer2(record)}>
          {record.hash}
        </Link>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Button type="secondary" htmlType="button" onClick={showDrawer}>
        <ClockCircleOutlined />
      </Button>
      {visible ? (
        <React.Fragment>
          <Drawer
            className="dtldrawer"
            title={CamelCaseToSpaceLetter(title)}
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
          >
            <div className="tbl_wrap">
              <Table
                columns={columns}
                rowKey={(record) => record.hash}
                dataSource={prepareHistory(historyData)}
                pagination={false}
              ></Table>
            </div>
          </Drawer>

          <Drawer
            className="dtldrawer"
            title={`Hash Record : ${hashRecord.hash}`}
            placement="right"
            closable={true}
            onClose={onClose2}
            visible={visible2}
          >
            <div className="tbl_wrap">
              <Row gutter={[15]}>
                {hashRecord.data !== undefined
                  ? Object.entries(hashRecord.data).map(([key, value], i) => (
                      <Col xs={24} sm={12} md={12} lg={12} key={i}>
                        <label>{CamelCaseToSpaceLetter(key)}</label>
                        <Form.Item>{value === "" ? "-" : value}</Form.Item>
                      </Col>
                    ))
                  : ""}
              </Row>
            </div>
          </Drawer>
        </React.Fragment>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default HistoryDetail;
