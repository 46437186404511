import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import {
  GET_CERTIFICATION_LICENCE_QUERY,
  UPDATE_CERTIFICATION_LICENCE,
} from "./CertificationLicenceQuery";

const { Content } = Layout;

class AddCertificationLicence extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    issueDate: null,
    fieldArr: [
      "issueDate",
      "renewalDate",
      "type",
      "name",
      "expirationDate",
      "school",
      "certification",
      "licenceNumber",
      "licenceCountry",
      "licenceState",
      "certificationNumber",
      "areaNumber",
    ],
    datepickerFields: ["issueDate", "renewalDate"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getCertificationLicenceDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    if (!this.state.issueDate || !this.state.name) {
      ErrorNotificationMsg("Error", "Issue Date & Name is required field.");
      return false;
    }
    this.updateCertificationLicence();
  };

  updateCertificationLicence = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_CERTIFICATION_LICENCE,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success",
          "Certification and Licence updated successfully."
        );
        this.formRef.current.resetFields();
        this.props.history.push("/manage-certification-licence", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getCertificationLicenceDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_CERTIFICATION_LICENCE_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(
          response.data.getEmployeeCertificationAndLicenceRecordById
        );
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeCertificationAndLicenceRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleDatepickerChange = (date, dateString, name) => {
    this.setState({ [name]: dateString });
  };

  render() {
    const { fieldArr, currentRoute, apiLoading, btnLoading, personId, datepickerFields } =
      this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-certification-licence" ? "Add" : "Edit"}
                Certification and Licence : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Certification and Licence
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-certification-licence"
                      ? "Add"
                      : "Edit"}
                    Certification and Licence
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              datepickerFields={datepickerFields}
                              handleDatepickerChange={
                                this.handleDatepickerChange
                              }
                              stateValues={this.state}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddCertificationLicence);
