import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

export default class Logout extends Component {
  componentDidMount() {
    try {
      Auth.signOut().then(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    } catch (err) {
      ErrorNotificationMsg("Error", err.message);
    }
  }

  render() {
    return <div></div>;
  }
}
