import gql from "graphql-tag";

export const ADD_JOB_DUTY = gql`
  mutation createJobDutiesRecord(
    $personId: String!
    $description: String
    $category: String
    $percentOfTime: String
    $priority: String
    $frequency: String
    $essential: String
    $other: String
  ) {
    createJobDutiesRecord(
      personId: $personId
      data: {
        description: $description
        category: $category
        percentOfTime: $percentOfTime
        priority: $priority
        frequency: $frequency
        essential: $essential
        other: $other
      }
    )
  }
`;

export const JOB_DUTY_LIST_QUERY = gql`
  query getEmployeeJobDutiesRecords($personId: String!) {
    getEmployeeJobDutiesRecords(personId: $personId) {
      jobDutiesId
      personId
      description
      category
      percentOfTime
      priority
      frequency
      essential
      other
    }
  }
`;

export const GET_JOB_DUTY_QUERY = gql`
  query getEmployeeJobDutiesRecordById($recordId: String!) {
    getEmployeeJobDutiesRecordById(recordId: $recordId) {
      personId
      description
      category
      percentOfTime
      priority
      frequency
      essential
      other
    }
  }
`;

export const UPDATE_JOB_DUTY = gql`
  mutation updateJobDutiesRecord(
    $personId: String!
    $description: String!
    $category: String
    $percentOfTime: String
    $priority: String
    $frequency: String
    $essential: String
    $other: String
  ) {
    updateJobDutiesRecord(
      personId: $personId
      data: {
        description: $description
        category: $category
        percentOfTime: $percentOfTime
        priority: $priority
        frequency: $frequency
        essential: $essential
        other: $other
      }
    )
  }
`;

export const GET_JOB_DUTY_HISTORY = gql`
  query getEmployeeJobDutiesRecordHistoryById($recordId: String!) {
    getEmployeeJobDutiesRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        description
        category
        percentOfTime
        priority
        frequency
        essential
        other
        recordSource
      }
    }
  }
`;

export const DELETE_JOB_DUTY = gql`
  mutation deleteJobDutiesRecord($recordId: String!) {
    deleteJobDutiesRecord(recordId: $recordId)
  }
`;
