import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { debounce } from "lodash";
import {
  Col,
  Row,
  Layout,
  Table,
  Space,
  Breadcrumb,
  Popconfirm,
  Input,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import RecordMenu from "../common/RecordMenu";

import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

import { EMPLOYEE_LIST_QUERY, DELETE_EMPLOYEE } from "./EmployeeQuery";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;
const { Search } = Input;
class ManageEmployee extends Component {
  state = {
    data: [],
    loading: false,
    totalRecords: 0,
    first: 10,
    offset: 0,
    search: null,
    orderBy: {
      sortingField: "createdAt",
      sortingOrder: "DESC",
    },
  };

  componentDidMount() {
    this.fetchEmployeeList({
      orderBy: this.state.orderBy,
      first: this.state.first,
      offset: this.state.offset,
    });

    this.searchCallback = debounce(function (e) {
      this.searchEmployee(e.target.value);
    }, 500);
  }

  fetchEmployeeList = (params) => {
    this.setState({ loading: true });
    const { client } = this.props;

    client
      .query({
        query: EMPLOYEE_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.getAllEmployees.rows,
          totalRecords: result.data.getAllEmployees.count,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  confirmDelete = (personId, props) => {
    
    this.setState({ loading: true });
    const { client } = props;

    client
      .mutate({
        mutation: DELETE_EMPLOYEE,
        variables: { personId: personId },
      })
      .then(() => {
        setTimeout(() => {
          this.fetchEmployeeList({
            orderBy: this.state.orderBy,
          });
          SuccessNotificationMsg("Success", "Employee deleted successfully.");
        }, 2000);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  searchCallbackDelayed = (e) => {
    e.persist();
    this.searchCallback(e);
  };

  searchEmployee = (searchText) => {
    if (searchText !== "") {
      this.fetchEmployeeList({
        search: searchText,
        orderBy: this.state.orderBy,
        first: 10,
        offset: 0,
      });

      this.setState({ searchText, offset: 0 });
    } else {
      this.fetchEmployeeList({
        orderBy: this.state.orderBy,
        first: 10,
        offset: 0,
      });
      this.setState({ searchText: "", offset: 0, first: 10 });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const orderBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({
      first: pagination.defaultPageSize,
      offset: (pagination.current - 1) * pagination.defaultPageSize,
      orderBy,
    });

    this.fetchEmployeeList({
      orderBy,
      first: pagination.defaultPageSize,
      offset: (pagination.current - 1) * pagination.defaultPageSize,
    });
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = this.state.orderBy;

    if (sortField === "firstName" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "firstName", sortingOrder: "ASC" };
    }
    if (sortField === "firstName" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "firstName", sortingOrder: "DESC" };
    }

    if (sortField === "gender" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "gender", sortingOrder: "ASC" };
    }
    if (sortField === "gender" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "gender", sortingOrder: "DESC" };
    }

    if (sortField === "homeEmail" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "homeEmail", sortingOrder: "ASC" };
    }
    if (sortField === "homeEmail" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "homeEmail", sortingOrder: "DESC" };
    }

    if (sortField === "mobileNumber" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "mobileNumber", sortingOrder: "ASC" };
    }
    if (sortField === "mobileNumber" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "mobileNumber", sortingOrder: "DESC" };
    }

    if (sortField === "city" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "city", sortingOrder: "ASC" };
    }
    if (sortField === "city" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "city", sortingOrder: "DESC" };
    }
    return sortingEnumKey;
  }

  render() {
    const { data, loading, totalRecords, first, offset } = this.state;
    const columns = [
      {
        title: "Employee No",
        render: (node) => (
          <Link to={"/employee-detail/" + btoa(node.personId)}>
            {node.personId}
          </Link>
        ),
        key: "personId",
        width: "15%",
      },
      {
        title: "Employee Name",
        render: (node) => `${node.firstName + " " + node.lastName}`,
        key: "firstName",
        width: "15%",
        sorter: true,
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        width: "12%",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "homeEmail",
        key: "homeEmail",
        width: "20%",
        sorter: true,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        width: "15%",
        sorter: true,
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        width: "13%",
        sorter: true,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/employee-detail/" + btoa(node.personId)}
              className="action"
              title="View"
            >
              <EyeOutlined />
            </Link>
            <Link
              to={"/edit-employee/" + btoa(node.personId)}
              className="action"
              title="Edit"
            >
              <EditOutlined />
            </Link>
            <Link
              to={"/employee-history/" + btoa(node.personId)}
              className="action"
              title="History"
            >
              <HistoryOutlined />
            </Link>
            <RecordMenu history={this.props.history} personId={node.personId} />
            <Popconfirm
              className="action"
              title="Are you sure to delete ?"
              onConfirm={(id) => this.confirmDelete(node.personId, this.props)}
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Employee
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Employee</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto"></Col>
                      <Col flex="auto" align="end">
                        <div className="searchhwrap">
                          <Search
                            placeholder="search.."
                            onChange={this.searchCallbackDelayed}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 970 }}
                    loading={loading}
                    rowKey={(record) => record.personId}
                    onChange={this.handleTableChange}
                    pagination={{
                      defaultCurrent: offset + 1,
                      defaultPageSize: first,
                      total:
                        totalRecords < 10 ? totalRecords + 1 : totalRecords,
                      showSizeChanger: false,
                    }}
                  />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageEmployee);
