import gql from "graphql-tag";

export const ADD_WORK_AUTHORIZATION = gql`
  mutation createWorkAuthorizationRecord(
    $personId: String!
    $citizenship: String
    $alienNumber: String
    $alienExpirationDate: String
    $alienRegistrationNumberOrUSCIS: String
    $formI94AdmissionNumber: String
    $foreignPassportNumber: String
    $countryOfIssuance: String
    $certificationDate: String
    $listADocumentTitle: String
    $listAIssuingAuthority: String
    $listADocumentNumber: String
    $listAExpirationDate: String
    $listBDocumentTitle: String
    $listBIssuingAuthority: String
    $listBDocumentNumber: String
    $listBExpirationDate: String
    $listCDocumentTitle: String
    $listCIssuingAuthority: String
    $listCDocumentNumber: String
    $listCExpirationDate: String
  ) {
    createWorkAuthorizationRecord(
      personId: $personId
      data: {
        citizenship: $citizenship
        alienNumber: $alienNumber
        alienExpirationDate: $alienExpirationDate
        alienRegistrationNumberOrUSCIS: $alienRegistrationNumberOrUSCIS
        formI94AdmissionNumber: $formI94AdmissionNumber
        foreignPassportNumber: $foreignPassportNumber
        countryOfIssuance: $countryOfIssuance
        certificationDate: $certificationDate
        listADocumentTitle: $listADocumentTitle
        listAIssuingAuthority: $listAIssuingAuthority
        listADocumentNumber: $listADocumentNumber
        listAExpirationDate: $listAExpirationDate
        listBDocumentTitle: $listBDocumentTitle
        listBIssuingAuthority: $listBIssuingAuthority
        listBDocumentNumber: $listBDocumentNumber
        listBExpirationDate: $listBExpirationDate
        listCDocumentTitle: $listCDocumentTitle
        listCIssuingAuthority: $listCIssuingAuthority
        listCDocumentNumber: $listCDocumentNumber
        listCExpirationDate: $listCExpirationDate
      }
    )
  }
`;

export const WORK_AUTHORIZATION_LIST_QUERY = gql`
  query getEmployeeWorkAuthorizationRecords($personId: String!) {
    getEmployeeWorkAuthorizationRecords(personId: $personId) {
      workAuthorizationId
      personId
      citizenship
      alienNumber
      alienExpirationDate
      alienRegistrationNumberOrUSCIS
      formI94AdmissionNumber
      foreignPassportNumber
      countryOfIssuance
      certificationDate
      listADocumentTitle
      listAIssuingAuthority
      listADocumentNumber
      listAExpirationDate
      listBDocumentTitle
      listBIssuingAuthority
      listBDocumentNumber
      listBExpirationDate
      listCDocumentTitle
      listCIssuingAuthority
      listCDocumentNumber
      listCExpirationDate
    }
  }
`;

export const GET_WORK_AUTHORIZATION_QUERY = gql`
  query getEmployeeWorkAuthorizationRecordById($recordId: String!) {
    getEmployeeWorkAuthorizationRecordById(recordId: $recordId) {
      personId
      citizenship
      alienNumber
      alienExpirationDate
      alienRegistrationNumberOrUSCIS
      formI94AdmissionNumber
      foreignPassportNumber
      countryOfIssuance
      certificationDate
      listADocumentTitle
      listAIssuingAuthority
      listADocumentNumber
      listAExpirationDate
      listBDocumentTitle
      listBIssuingAuthority
      listBDocumentNumber
      listBExpirationDate
      listCDocumentTitle
      listCIssuingAuthority
      listCDocumentNumber
      listCExpirationDate
    }
  }
`;

export const UPDATE_WORK_AUTHORIZATION = gql`
  mutation updateWorkAuthorizationRecord(
    $personId: String!
    $citizenship: String!
    $alienNumber: String
    $alienExpirationDate: String
    $alienRegistrationNumberOrUSCIS: String
    $formI94AdmissionNumber: String
    $foreignPassportNumber: String
    $countryOfIssuance: String
    $certificationDate: String
    $listADocumentTitle: String
    $listAIssuingAuthority: String
    $listADocumentNumber: String
    $listAExpirationDate: String
    $listBDocumentTitle: String
    $listBIssuingAuthority: String
    $listBDocumentNumber: String
    $listBExpirationDate: String
    $listCDocumentTitle: String
    $listCIssuingAuthority: String
    $listCDocumentNumber: String
    $listCExpirationDate: String
  ) {
    updateWorkAuthorizationRecord(
      personId: $personId
      data: {
        citizenship: $citizenship
        alienNumber: $alienNumber
        alienExpirationDate: $alienExpirationDate
        alienRegistrationNumberOrUSCIS: $alienRegistrationNumberOrUSCIS
        formI94AdmissionNumber: $formI94AdmissionNumber
        foreignPassportNumber: $foreignPassportNumber
        countryOfIssuance: $countryOfIssuance
        certificationDate: $certificationDate
        listADocumentTitle: $listADocumentTitle
        listAIssuingAuthority: $listAIssuingAuthority
        listADocumentNumber: $listADocumentNumber
        listAExpirationDate: $listAExpirationDate
        listBDocumentTitle: $listBDocumentTitle
        listBIssuingAuthority: $listBIssuingAuthority
        listBDocumentNumber: $listBDocumentNumber
        listBExpirationDate: $listBExpirationDate
        listCDocumentTitle: $listCDocumentTitle
        listCIssuingAuthority: $listCIssuingAuthority
        listCDocumentNumber: $listCDocumentNumber
        listCExpirationDate: $listCExpirationDate
      }
    )
  }
`;

export const GET_WORK_AUTHORIZATION_HISTORY = gql`
  query getEmployeeWorkAuthorizationRecordHistoryById($recordId: String!) {
    getEmployeeWorkAuthorizationRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        citizenship
        alienNumber
        alienExpirationDate
        alienRegistrationNumberOrUSCIS
        formI94AdmissionNumber
        foreignPassportNumber
        countryOfIssuance
        certificationDate
        listADocumentTitle
        listAIssuingAuthority
        listADocumentNumber
        listAExpirationDate
        listBDocumentTitle
        listBIssuingAuthority
        listBDocumentNumber
        listBExpirationDate
        listCDocumentTitle
        listCIssuingAuthority
        listCDocumentNumber
        listCExpirationDate
        recordSource
      }
    }
  }
`;

export const DELETE_WORK_AUTHORIZATION = gql`
  mutation deleteWorkAuthorizationRecord($recordId: String!) {
    deleteWorkAuthorizationRecord(recordId: $recordId)
  }
`;
