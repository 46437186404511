import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Card, Col, Row, Layout } from "antd";
import _ from "lodash";

import EmployeeList from "./EmployeeList";
import EmployeeAnniversaryList from "./EmployeeAnniversaryList";
// import DocumentExpiringList from "./DocumentExpiringList";
import EmployeeDepartmentChart from "./EmployeeDepartmentChart";
import EmployeeTypeChart from "./EmployeeTypeChart";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

import "../dashboard/Dashboard.scss";
import emprecord from "../../images/emprecord.png";
import empleave from "../../images/empleave.png";
import totalrecord from "../../images/totalrecord.png";
import EmployeeStatusChart from "./EmployeeStatusChart";

import { DASHBOARD_METRICS_QUERY } from "./DashboardQuery";

const { Content } = Layout;
class Dashboard extends Component {
  state = {
    pieChartColors: ["#EEC683", "#81DDC6", "#F45E58", "#50514F"],
    columnChartColors: [
      "#2D99FF",
      "#81DDC6",
      "#826AF9",
      "#FF4D8D",
      "#FF6C40",
      "#FFE700",
      "#F45E58",
    ],
    dashboardData: {},
    statusChartData: [],
    typeChartData: [],
    departmentChartData: [],
  };

  componentDidMount() {
    this.getDashboardData();
  }

  getDashboardData = () => {
    this.props.client
      .query({
        query: DASHBOARD_METRICS_QUERY,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        let statusChartData = this.prepareStatusChartData(
          result.data.getDashboardMetrics.employmentData
        );

        let typeChartData = this.prepareTypeChartData(
          result.data.getDashboardMetrics.employmentData
        );

        let departmentChartData = this.prepareDepartmentChartData(
          result.data.getDashboardMetrics.employmentData
        );

        this.setState({
          statusChartData,
          typeChartData,
          departmentChartData,
          dashboardData: result.data.getDashboardMetrics,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
      });
  };

  prepareStatusChartData = (apiData) => {
    let statusChartData = [];

    if (apiData.length > 0) {
      let uniqueStatus = [...new Set(apiData.map((item) => item.status))];
      uniqueStatus.map((status, i) => {
        if (
          status === "null" ||
          status === "" ||
          status === null ||
          status === undefined
        ) {
          return true;
        }

        let statusValueCount = _.filter(
          apiData,
          (item) => item.status === status
        ).length;
        statusChartData.push({
          y: statusValueCount,
          label: status,
          color: this.state.pieChartColors[i],
        });
        return null;
      });
    }

    return statusChartData;
  };

  prepareTypeChartData = (apiData) => {
    let typeChartData = [];

    if (apiData.length > 0) {
      let uniqueTypes = [...new Set(apiData.map((item) => item.type))];
      uniqueTypes.map((type, i) => {
        if (
          type === "null" ||
          type === "" ||
          type === null ||
          type === undefined
        ) {
          return true;
        }

        let typeValueCount = _.filter(
          apiData,
          (item) => item.type === type
        ).length;
        typeChartData.push({
          y: typeValueCount,
          label: type,
          color: this.state.pieChartColors[i],
        });
        return null;
      });
    }

    return typeChartData;
  };

  prepareDepartmentChartData = (apiData) => {
    let departmentChartData = [];

    if (apiData.length > 0) {
      let uniqueDepartments = [
        ...new Set(apiData.map((item) => item.cc2OrDepartment)),
      ];
      uniqueDepartments.map((cc2OrDepartment, i) => {
        if (
          cc2OrDepartment === "null" ||
          cc2OrDepartment === "" ||
          cc2OrDepartment === null ||
          cc2OrDepartment === undefined
        ) {
          return true;
        }

        let cc2OrDepartmentValueCount = _.filter(
          apiData,
          (item) => item.cc2OrDepartment === cc2OrDepartment
        ).length;
        departmentChartData.push({
          y: cc2OrDepartmentValueCount,
          label: cc2OrDepartment,
          color: this.state.columnChartColors[i],
        });
        return null;
      });
    }

    return departmentChartData;
  };

  render() {
    const {
      dashboardData,
      statusChartData,
      typeChartData,
      departmentChartData,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">Dashboard</div>
              <div className="content_wraper dashboardPage">
                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, xl: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={emprecord}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>
                            {dashboardData.activeEmployees > 0
                              ? dashboardData.activeEmployees
                              : 0}
                          </h3>
                          <h5>Active Employee Records</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={empleave}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>
                            {dashboardData.employeesOnLeave > 0
                              ? dashboardData.employeesOnLeave
                              : 0}
                          </h3>
                          <h5>Employees on Leave</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} md={8}>
                    <Card bordered={false}>
                      <Row>
                        <Col>
                          <img
                            className="cardicon"
                            alt="icon"
                            src={totalrecord}
                          ></img>
                        </Col>
                        <Col flex="auto">
                          <h3>
                            {dashboardData.totalEmployees > 0
                              ? dashboardData.totalEmployees
                              : 0}{" "}
                          </h3>
                          <h5>Total No Of Records</h5>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row
                  gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                  className="card_wrap"
                >
                  <Col xs={24} md={24} xl={16}>
                    <EmployeeDepartmentChart
                      departmentChartData={departmentChartData}
                    />
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <Row gutter={{ xs: 0, md: 16, xl: 0 }}>
                      <Col xs={24} md={12} xl={24}>
                        <EmployeeAnniversaryList
                          data={dashboardData.anniversariesThisMonth}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={{ xs: 16, sm: 16, md: 16, xl: 32 }}>
                  <Col xs={24} xl={10}>
                    <EmployeeTypeChart typeChartData={typeChartData} />
                  </Col>
                  <Col xs={24} xl={14}>
                    <EmployeeStatusChart
                      statusChartData={statusChartData}
                      ytdTerminations={dashboardData.ytdTerminations}
                      ytdNewHires={dashboardData.ytdNewHires}
                    />
                  </Col>
                </Row>
                <div className="productList mystatsgrid">
                  <EmployeeList />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(Dashboard);
