import gql from "graphql-tag";

export const COMPENSATION_LIST_QUERY = gql`
  query getEmployeeCompensationRecords($personId: String!) {
    getEmployeeCompensationRecords(personId: $personId) {
      compensationRecordId
      personId
      highlyCompensatedEmployee
      compensation
      hourlyRate2
      grantDate
      boardApprovalDate
      numberOfSharesGranted
      vestingStatus
      exercisePricePerShares
      termExpirationDate
      vesting
      hourlyRate3
      earningType
      earningCode
      description
      otherPayAmount
      payDate
      frequency
    }
  }
`;

export const GET_COMPENSATION_QUERY = gql`
  query getEmployeeCompensationRecordById($recordId: String!) {
    getEmployeeCompensationRecordById(recordId: $recordId) {
      personId
      highlyCompensatedEmployee
      compensation
      hourlyRate2
      grantDate
      boardApprovalDate
      numberOfSharesGranted
      vestingStatus
      exercisePricePerShares
      termExpirationDate
      vesting
      hourlyRate3
      earningType
      earningCode
      description
      otherPayAmount
      payDate
      frequency
    }
  }
`;

export const UPDATE_COMPENSATION = gql`
  mutation updateCompensationRecord(
    $personId: String!
    $highlyCompensatedEmployee: String
    $compensation: String
    $hourlyRate2: String
    $grantDate: Date
    $boardApprovalDate: String
    $numberOfSharesGranted: String
    $vestingStatus: String
    $exercisePricePerShares: String
    $termExpirationDate: String
    $vesting: String
    $hourlyRate3: String
    $earningType: String
    $earningCode: String!
    $description: String
    $otherPayAmount: String
    $payDate: Date
    $frequency: String
  ) {
    updateCompensationRecord(
      personId: $personId
      data: {
        highlyCompensatedEmployee: $highlyCompensatedEmployee
        compensation: $compensation
        hourlyRate2: $hourlyRate2
        grantDate: $grantDate
        boardApprovalDate: $boardApprovalDate
        numberOfSharesGranted: $numberOfSharesGranted
        vestingStatus: $vestingStatus
        exercisePricePerShares: $exercisePricePerShares
        termExpirationDate: $termExpirationDate
        vesting: $vesting
        hourlyRate3: $hourlyRate3
        earningType: $earningType
        earningCode: $earningCode
        description: $description
        otherPayAmount: $otherPayAmount
        payDate: $payDate
        frequency: $frequency
      }
    )
  }
`;

export const GET_COMPENSATION_HISTORY = gql`
  query getEmployeeCompensationRecordHistoryById($recordId: String!) {
    getEmployeeCompensationRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        highlyCompensatedEmployee
        compensation
        hourlyRate2
        grantDate
        boardApprovalDate
        numberOfSharesGranted
        vestingStatus
        exercisePricePerShares
        termExpirationDate
        vesting
        hourlyRate3
        earningType
        earningCode
        description
        otherPayAmount
        payDate
        frequency
        recordSource
      }
    }
  }
`;

export const DELETE_COMPENSATION = gql`
  mutation deleteCompensationRecord($recordId: String!) {
    deleteCompensationRecord(recordId: $recordId)
  }
`;
