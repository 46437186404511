import React, { useState } from "react";
import { Button, Drawer, Row, Col, Form } from "antd";
import { CamelCaseToSpaceLetter, ToDateTime } from "../../utils/Helpers";
import { RightOutlined } from "@ant-design/icons";
import { HistoryDetailPrint } from "./HistoryDetailPrint";

const HistoryDetailDrawer = ({ historyData }) => {
  const [visible, setVisible] = useState(false);
  delete historyData.data.__typename;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <Button type="link" onClick={showDrawer}>
        More Info <RightOutlined />
      </Button>
      {visible ? (
        <Drawer
          title={`Version: ${historyData.metadata.version}`}
          width={720}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
          className="historyDrawer"
        >
          <HistoryDetailPrint historyData={historyData} />

          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={12} lg={12}>
                <label>Transaction Id</label>
                <Form.Item>{historyData.metadata.txId}</Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12}>
                <label>Transaction Time</label>
                <Form.Item>{ToDateTime(historyData.metadata.txTime)}</Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12}>
                <label>Hash</label>
                <Form.Item>{historyData.hash}</Form.Item>
              </Col>
              {Object.entries(historyData.data).map(([key, value], i) => (
                <Col xs={24} sm={12} md={12} lg={12} key={key}>
                  <label>{CamelCaseToSpaceLetter(key)}</label>
                  <Form.Item>
                    {value === "" || value === "null" ? "-" : value}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        </Drawer>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default HistoryDetailDrawer;
