import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Table,
  Space,
  Breadcrumb,
  Input,
  Button,
  Popconfirm,
} from "antd";
import {
  HeartOutlined,
  HeartFilled,
  DeleteOutlined,
  CopyOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import update from "immutability-helper";
import AddScheduler from "./AddScheduler";
import ReportPDF from "./ReportPDF";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { ToDateTime } from "../../utils/Helpers";
import {
  GET_ALL_REPORT_QUERY,
  COPY_REPORT,
  FAVOURITE_REPORT,
  DELETE_REPORT,
} from "./ReportingToolQuery";

import "../dashboard/Dashboard.scss";

const { Content } = Layout;
class ReportList extends Component {
  state = {
    data: [],
    loading: false,
    favouritesOnly: false,
    searchQuery: "",
    first: 10,
    offset: 0,
    sortBy: {
      sortingField: "updatedAt",
      sortingOrder: "DESC",
    },
    showLoadMore: false,
    modelReportId: null,
    modelReportName: null,
  };

  componentDidMount() {
    this.fetchReportList({
      first: this.state.first,
      offset: this.state.offset,
      sortBy: this.state.sortBy,
    });
  }

  fetchReportList = (params) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        query: GET_ALL_REPORT_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          showLoadMore: result.data.getAllReports.length >= 10 ? true : false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.getAllReports)
              : result.data.getAllReports,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  loadMore = () => {
    this.fetchReportList({
      searchQuery: this.state.searchQuery,
      favouritesOnly: this.state.favouritesOnly,
      sortBy: this.state.sortBy,
      first: this.state.first,
      offset: this.state.offset + this.state.first,
    });
    this.setState({ offset: this.state.offset + this.state.first });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ data: [], sortBy: sortBy, first: 10, offset: 0 }, () => {
      this.fetchReportList({
        searchQuery: this.state.searchQuery,
        favouritesOnly: this.state.favouritesOnly,
        sortBy: sortBy,
        first: 10,
        offset: 0,
      });
    });
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "DESC" };

    if (sortField === "reportName" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "reportName", sortingOrder: "ASC" };
    }
    if (sortField === "reportName" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "reportName", sortingOrder: "DESC" };
    }

    if (sortField === "reportCategory" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "reportCategory", sortingOrder: "ASC" };
    }
    if (sortField === "reportCategory" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "reportCategory", sortingOrder: "DESC" };
    }

    if (sortField === "updatedAt" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "ASC" };
    }
    if (sortField === "updatedAt" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "updatedAt", sortingOrder: "DESC" };
    }

    return sortingEnumKey;
  }

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSearchData();
    }
  };

  handleSearchData = () => {
    this.setState({ data: [], offset: 0, first: 10 }, () => {
      this.fetchReportList({
        searchQuery: this.state.searchQuery,
        favouritesOnly: this.state.favouritesOnly,
        sortBy: this.state.sortBy,
        first: 10,
        offset: 0,
      });
    });
  };

  confirmDelete = (reportId, props) => {
    const { client } = props;
    client
      .mutate({
        mutation: DELETE_REPORT,
        variables: { reportId: reportId },
      })
      .then(() => {
        this.setState({ data: [], offset: 0, first: 10 }, () => {
          this.fetchReportList({
            sortBy: this.state.sortBy,
            first: 10,
            offset: 0,
          });
        });
        SuccessNotificationMsg("Success", "Report deleted successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  copyReport = (reportId, props) => {
    const { client } = props;
    client
      .mutate({
        mutation: COPY_REPORT,
        variables: { reportId: reportId },
      })
      .then(() => {
        this.setState({ data: [], offset: 0, first: 10 }, () => {
          this.fetchReportList({
            sortBy: this.state.sortBy,
            first: 10,
            offset: 0,
          });
        });
        SuccessNotificationMsg("Success", "Report copied successfully.");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  makeFavouriteReport = (reportObj, props) => {
    let msg = reportObj.favourite
      ? "Report removed from favourites"
      : "Report added to favourites";
    const { client } = props;

    client
      .mutate({
        mutation: FAVOURITE_REPORT,
        variables: {
          reportId: reportObj.reportId,
          favourite: !reportObj.favourite,
        },
      })
      .then(() => {
        const data = this.state.data;
        const commentIndex = data.findIndex(function (c) {
          return c.reportId === reportObj.reportId;
        });

        const updatedComment = update(data[commentIndex], {
          favourite: { $set: !reportObj.favourite },
        });
        const newData = update(data, {
          $splice: [[commentIndex, 1, updatedComment]],
        });

        this.setState({ data: newData });
        SuccessNotificationMsg("Success", msg);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  showModal = (e, node) => {
    this.setState({
      visible: true,
      modelReportId: node.reportId,
      modelReportName: node.reportName,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  onFavouriteToggleChange = () => {
    this.setState(
      {
        data: [],
        offset: 0,
        first: 10,
        favouritesOnly: !this.state.favouritesOnly,
      },
      () => {
        this.fetchReportList({
          searchQuery: this.state.searchQuery,
          favouritesOnly: this.state.favouritesOnly,
          sortBy: this.state.sortBy,
          first: 10,
          offset: 0,
        });
      }
    );
  };

  render() {
    const { loading, data, showLoadMore } = this.state;
    const columns = [
      {
        title: "Report Name",
        key: "reportName",

        width: "38%",
        sorter: true,
        render: (node) => (
          <Link to={"/edit-report/" + btoa(node.reportId)}>
            {node.reportName}
          </Link>
        ),
      },
      {
        title: "Category",
        key: "reportCategory",
        dataIndex: "reportCategory",
        width: "25%",
        sorter: true,
      },
      {
        title: "Last Modified",
        key: "updatedAt",
        render: (record) => ToDateTime(record.updatedAt),
        width: "25%",
        sorter: true,
      },
      {
        title: "Action",
        key: "action",
        width: "12%",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to="#"
              onClick={(id) => this.makeFavouriteReport(node, this.props)}
              className="action"
              title="Favourite"
            >
              {node.favourite ? <HeartFilled /> : <HeartOutlined />}
            </Link>

            <Popconfirm
              className="action"
              title="Are you sure to copy report ?"
              onConfirm={(id) => this.copyReport(node.reportId, this.props)}
              okText="Yes"
              placement="left"
            >
              <CopyOutlined />
            </Popconfirm>

            <Popconfirm
              className="action"
              title="Are you sure to delete ?"
              onConfirm={(id) => this.confirmDelete(node.reportId, this.props)}
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>

            <Link
              to="#"
              onClick={(e) => this.showModal(e, node)}
              title="Report Scheduler"
              className="action"
            >
              <ScheduleOutlined />
            </Link>

            <ReportPDF reportId={node.reportId} title={node.reportName} />
          </Space>
        ),
      },
    ];

    return (
      <div className="contentpart">
        {this.state.visible ? (
          <AddScheduler
            hideModal={this.hideModal}
            reportId={this.state.modelReportId}
            reportName={this.state.modelReportName}
          />
        ) : (
          ""
        )}
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Report List
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Report List</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto"></Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <div
                            className={
                              this.state.favouritesOnly
                                ? "fav-button checked"
                                : "fav-button"
                            }
                            onClick={() => this.onFavouriteToggleChange()}
                          >
                            <label>Favourites</label>
                            {this.state.favouritesOnly ? (
                              <HeartFilled />
                            ) : (
                              <HeartOutlined />
                            )}
                          </div>

                          <Input
                            placeholder="search"
                            name="searchQuery"
                            onChange={this.handleInputChange("searchQuery")}
                            onKeyDown={this._handleKeyDown}
                            style={{ width: "250px" }}
                          />
                          <Button
                            type="primary"
                            htmlType="button"
                            icon={<PlusCircleOutlined />}
                            onClick={() =>
                              this.props.history.push("/create-report")
                            }
                          >
                            Add Report
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 970 }}
                    pagination={false}
                    loading={loading}
                    rowKey={(record) => record.reportId}
                    onChange={this.handleTableChange}
                  />

                  {showLoadMore && loading === false ? (
                    <Row
                      justify="center"
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col>
                        <Button
                          className="loadmore-btn"
                          size="small"
                          onClick={this.loadMore}
                        >
                          Load More
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ReportList);
