import gql from "graphql-tag";

export const ADD_EMERGENCY_CONTACT = gql`
  mutation createEmergencyContactRecord(
    $name: String
    $homePhone: String
    $workPhone: String
    $cellPhone: String
    $personalEmail: String
    $workEmail: String
    $relationship: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $countryOrRegion: String
    $personId: String!
  ) {
    createEmergencyContactRecord(
      personId: $personId
      data: {
        name: $name
        homePhone: $homePhone
        workPhone: $workPhone
        cellPhone: $cellPhone
        personalEmail: $personalEmail
        workEmail: $workEmail
        relationship: $relationship
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        countryOrRegion: $countryOrRegion
      }
    )
  }
`;

export const EMERGENCY_CONTACT_LIST_QUERY = gql`
  query getEmployeeEmergencyContactRecords($personId: String!) {
    getEmployeeEmergencyContactRecords(personId: $personId) {
      emergencyContactId
      personId
      name
      relationship
      homePhone
      workPhone
      cellPhone
      personalEmail
      city
    }
  }
`;

export const GET_EMERGENCY_CONTACT_QUERY = gql`
  query getEmployeeEmergencyContactRecordById($recordId: String!) {
    getEmployeeEmergencyContactRecordById(recordId: $recordId) {
      personId
      name
      homePhone
      workPhone
      cellPhone
      personalEmail
      workEmail
      relationship
      street1
      street2
      city
      stateOrProvince
      zipOrPostalCode
      countryOrRegion
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation updateEmergencyContactRecord(
    $personId: String!
    $name: String!
    $homePhone: String
    $workPhone: String
    $cellPhone: String
    $personalEmail: String
    $workEmail: String
    $relationship: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $countryOrRegion: String
  ) {
    updateEmergencyContactRecord(
      personId: $personId
      data: {
        name: $name
        homePhone: $homePhone
        workPhone: $workPhone
        cellPhone: $cellPhone
        personalEmail: $personalEmail
        workEmail: $workEmail
        relationship: $relationship
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        countryOrRegion: $countryOrRegion
      }
    )
  }
`;

export const IMPORT_EMPLOYEE = gql`
  mutation importData($file: Upload!) {
    importData(file: $file, recordType: EmergencyContact)
  }
`;

export const GET_EMERGENCY_CONTACT_HISTORY = gql`
  query getEmployeeEmergencyContactRecordHistoryById($recordId: String!) {
    getEmployeeEmergencyContactRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        name
        homePhone
        workPhone
        cellPhone
        personalEmail
        workEmail
        relationship
        street1
        street2
        city
        stateOrProvince
        zipOrPostalCode
        countryOrRegion
        recordSource
      }
    }
  }
`;

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation deleteEmergencyContactRecord($recordId: String!) {
    deleteEmergencyContactRecord(recordId: $recordId)
  }
`;
