import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { GET_TIMESHEET_QUERY, UPDATE_TIMESHEET } from "./TimesheetQuery";

const { Content } = Layout;
class AddTimesheet extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "badgeID",
      "otherID",
      "startTime",
      "endTime",
      "lunchInMinutes",
      "punchType",
      "homeDivision",
      "homeDivisionDescription",
      "homeDepartment",
      "homeDepartmentDescription",
      "workDepartment",
      "workDepartmentDescription",
      "workJob",
      "workJobDescription",
      "workLevel4",
      "workLevel4Description",
      "workLevel5",
      "workLevel5Description",
      "startRound",
      "endRound",
      "regHours",
      "otHours",
      "dtHours",
      "totalTime",
      "dollars",
      "schedule",
      "shift",
      "shiftDescription",
      "shiftGroup",
      "payRate",
      "totalPayDollars",
      "billRate",
      "totalBillDollars",
      "totalDollars",
      "payType",
      "payTypeDescription",
      "payPeriodCode",
      "payPeriodDescription",
      "lastModified",
      "accrueOT",
      "regTCompensation",
      "otCompensation",
      "dtCompensation",
      "startLocationNo",
      "startLocationType",
      "startLocationName",
      "endLocationNo",
      "endLocationType",
      "endLocationName",
    ],
    requiredFieldArr: ["startTime", "endTime"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getTimesheetDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    this.updateTimesheet();
  };

  updateTimesheet = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_TIMESHEET,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Timesheet updated successfully.");
        this.formRef.current.resetFields();
        this.props.history.push("/manage-timesheet", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getTimesheetDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_TIMESHEET_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeeTimesheetRecordById);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeTimesheetRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const { fieldArr, currentRoute, apiLoading, btnLoading, personId, requiredFieldArr } =
      this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-timesheet" ? "Add" : "Edit"} Timesheet :{" "}
                {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Timesheet
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-timesheet" ? "Add" : "Edit"}
                    Timesheet
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              requiredFieldArr={requiredFieldArr}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddTimesheet);
