import gql from "graphql-tag";

export const DIRECT_DEPOSIT_ACCOUNTS_LIST_QUERY = gql`
  query getEmployeeDirectDepositAccountsRecords($personId: String!) {
    getEmployeeDirectDepositAccountsRecords(personId: $personId) {
      directDepositAccountsId
      personId
      waiveDirectDepositAuthorization
      transitOrABANumber
      account
      accountNumber
      amount
    }
  }
`;

export const GET_DIRECT_DEPOSIT_ACCOUNTS_QUERY = gql`
  query getEmployeeDirectDepositAccountsRecordById($recordId: String!) {
    getEmployeeDirectDepositAccountsRecordById(recordId: $recordId) {
      personId
      waiveDirectDepositAuthorization
      transitOrABANumber
      account
      accountNumber
      amount
    }
  }
`;

export const UPDATE_DIRECT_DEPOSIT_ACCOUNTS = gql`
  mutation updateDirectDepositAccountsRecord(
    $personId: String!
    $waiveDirectDepositAuthorization: String
    $transitOrABANumber: String!
    $account: String
    $accountNumber: String!
    $amount: String
  ) {
    updateDirectDepositAccountsRecord(
      personId: $personId
      data: {
        waiveDirectDepositAuthorization: $waiveDirectDepositAuthorization
        transitOrABANumber: $transitOrABANumber
        account: $account
        accountNumber: $accountNumber
        amount: $amount
      }
    )
  }
`;

export const GET_DIRECT_DEPOSIT_ACCOUNTS_HISTORY = gql`
  query getEmployeeDirectDepositAccountsRecordHistoryById($recordId: String!) {
    getEmployeeDirectDepositAccountsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        directDepositAccountsId
        personId
        waiveDirectDepositAuthorization
        transitOrABANumber
        account
        accountNumber
        amount
        recordSource
      }
    }
  }
`;

export const DELETE_DIRECT_DEPOSIT_ACCOUNTS = gql`
  mutation deleteDirectDepositAccountsRecord($recordId: String!) {
    deleteDirectDepositAccountsRecord(recordId: $recordId)
  }
`;
