import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Breadcrumb,
  Input,
  DatePicker,
} from "antd";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import { ToDateTime } from "../../utils/Helpers";
import { GET_HISTORY_QUERY } from "./ChangeHistoryQuery";
import recordTypes from "../csvImport/recordTypes.json";

import "../dashboard/Dashboard.scss";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = "MM/DD/YYYY";

class ChronologicalHistory extends Component {
  state = {
    data: [],
    loading: false,
    startDate: null,
    endDate: null,
    searchQuery: "",
    first: 10,
    offset: 0,
    sortBy: {
      sortingField: "txTime",
      sortingOrder: "DESC",
    },
    showLoadMore: false,
  };

  componentDidMount() {
    this.fetchHistory({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      first: this.state.first,
      offset: this.state.offset,
      sortBy: this.state.sortBy,
    });
  }

  fetchHistory = (params) => {
    this.setState({ loading: true });
    this.props.client
      .query({
        query: GET_HISTORY_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          showLoadMore:
            result.data.getChangeHistory.length >= 10 ? true : false,
          data:
            this.state.data.length > 0
              ? this.state.data.concat(result.data.getChangeHistory)
              : result.data.getChangeHistory,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  handleDatepickerChange = (dates, dateStrings) => {
    this.setState({ startDate: dateStrings[0], endDate: dateStrings[1] });
  };

  handleSearchData = () => {
    this.setState({ data: [], offset: 0, first: 10 }, () => {
      this.fetchHistory({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        searchQuery: this.state.searchQuery,
        sortBy: this.state.sortBy,
        first: 10,
        offset: 0,
      });
    });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleReset = () => {
    window.location.reload();
  };

  getHistoryDetailURL = (record) => {
    let recordObj = recordTypes.find((o) => o.value === record.record);
    let pageURL = recordObj ? recordObj.historyPageURL : "";
    return (
      <Link
        to={{
          pathname: pageURL + "/" + btoa(record.recordid),
          state: { personId: record.personid },
        }}
      >
        {record.txhash}
      </Link>
    );
  };

  getRecordTypeLabel = (record) => {
    let recordObj = recordTypes.find((o) => o.value === record.record);
    return recordObj ? recordObj.label : "";
  };

  loadMore = () => {
    this.fetchHistory({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      searchQuery: this.state.searchQuery,
      sortBy: this.state.sortBy,
      first: this.state.first,
      offset: this.state.offset + this.state.first,
    });
    this.setState({ offset: this.state.offset + this.state.first });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const sortBy = this.GetSortingEnumValue(sorter.columnKey, sorter.order);
    this.setState({ data: [], sortBy: sortBy, first: 10, offset: 0 }, () => {
      this.fetchHistory({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        searchQuery: this.state.searchQuery,
        sortBy: sortBy,
        first: 10,
        offset: 0,
      });
    });
  };

  GetSortingEnumValue(sortField, sortOrder) {
    let sortingEnumKey = { sortingField: "txTime", sortingOrder: "DESC" };

    if (sortField === "txtime" && sortOrder === "ascend") {
      return (sortingEnumKey = { sortingField: "txTime", sortingOrder: "ASC" });
    }

    if (sortField === "txtime" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "txTime", sortingOrder: "DESC" };
    }

    if (sortField === "txhash" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "txHash", sortingOrder: "ASC" };
    }

    if (sortField === "txhash" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "txHash", sortingOrder: "DESC" };
    }

    if (sortField === "record" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "record", sortingOrder: "ASC" };
    }

    if (sortField === "record" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "record", sortingOrder: "DESC" };
    }

    if (sortField === "recordsource" && sortOrder === "ascend") {
      sortingEnumKey = { sortingField: "recordSource", sortingOrder: "ASC" };
    }

    if (sortField === "recordsource" && sortOrder === "descend") {
      sortingEnumKey = { sortingField: "recordSource", sortingOrder: "DESC" };
    }
    return sortingEnumKey;
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSearchData();
    }
  };

  render() {
    const { loading, data, showLoadMore } = this.state;
    const columns = [
      {
        title: "Employee ID",
        render: (record) => (
          <Link to={"/employee-detail/" + btoa(record.personid)}>
            {record.personid}
          </Link>
        ),
        key: "personid",
        width: "14%",
        sorter: false,
      },
      {
        title: "Employee Name",
        render: (record) => record.firstname + " " + record.lastname,
        key: "firstname",
        width: "13%",
        sorter: false,
      },
      {
        title: "Transaction Date",
        render: (record) => ToDateTime(record.txtime),
        key: "txtime",
        width: "15%",
        sorter: true,
      },
      {
        title: "Hash",
        render: (record) => this.getHistoryDetailURL(record),
        key: "txhash",
        width: "28%",
        sorter: true,
      },
      {
        title: "Record Type",
        render: (record) => this.getRecordTypeLabel(record),
        key: "record",
        width: "18%",
        sorter: true,
      },
      {
        title: "Source",
        render: (record) => (!record.recordsource ? "-" : record.recordsource),
        key: "recordsource",
        width: "12%",
        sorter: true,
      },
    ];
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Chronological History List
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Chronological History List</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto">
                        <Space>
                          <Input
                            placeholder="Quick Employee Lookup:"
                            name="searchQuery"
                            onChange={this.handleInputChange("searchQuery")}
                            onKeyDown={this._handleKeyDown}
                            style={{ width: "250px" }}
                          />

                          <RangePicker
                            onChange={this.handleDatepickerChange}
                            format={dateFormat}
                          />

                          <Button
                            type="secondary"
                            htmlType="button"
                            onClick={this.handleSearchData}
                          >
                            Search
                          </Button>
                          <Button
                            type="secondary"
                            htmlType="button"
                            onClick={this.handleReset}
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                      <Col flex="auto" align="end">
                        <div className="statshead settingWrp">
                          Displaying Change History of Last 30 Days
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 970 }}
                    pagination={false}
                    loading={loading}
                    rowKey={(record) => record.txtime}
                    onChange={this.handleTableChange}
                  />

                  {showLoadMore && loading === false ? (
                    <Row
                      justify="center"
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col>
                        <Button
                          className="loadmore-btn"
                          size="small"
                          onClick={this.loadMore}
                        >
                          Load More
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ChronologicalHistory);
