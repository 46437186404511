import React from "react";
import { Col, Row } from "antd";
import CanvasJSReact from "../../charts/canvasjs.react";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const EmployeeStatusChart = (props) => {
  const pie2 = {
    animationEnabled: true,
    title: {
      text: "",
      horizontalAlign: "",
    },
    data: [
      {
        type: "doughnut",
        startAngle: 60,
        innerRadius: 40,
        indexLabelFontSize: 14,
        indexLabel: "{label} - #percent%",
        toolTipContent: "<b>{label}:</b> {y} (#percent%)",
        dataPoints: props.statusChartData,
      },
    ],
  };

  return (
    <div className="chart-wrapper chart_mainwrap">
      <div className="headingrow">Employment Status</div>
      <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
        <Col xs={24} md={16}>
          <CanvasJSChart options={pie2} />
        </Col>
        <Col xs={24} md={8}>
          <div className="subcardwrap">
            <div className="subcard darkblue">
              <div className="count">
                {props.ytdNewHires > 0 ? props.ytdNewHires : 0}
              </div>
              <h3>YTD New Hires</h3>
            </div>
            <div className="subcard orange">
              <div className="count">
                {props.ytdTerminations > 0 ? props.ytdTerminations : 0}
              </div>
              <h3>YTD Terminations</h3>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeStatusChart;
