import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import {
  GET_WORK_AUTHORIZATION_QUERY,
  UPDATE_WORK_AUTHORIZATION,
} from "./WorkAuthorizationQuery";

const { Content } = Layout;
class AddWorkAuthorization extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "citizenship",
      "alienNumber",
      "alienExpirationDate",
      "alienRegistrationNumberOrUSCIS",
      "formI94AdmissionNumber",
      "foreignPassportNumber",
      "countryOfIssuance",
      "certificationDate",
      "listADocumentTitle",
      "listAIssuingAuthority",
      "listADocumentNumber",
      "listAExpirationDate",
      "listBDocumentTitle",
      "listBIssuingAuthority",
      "listBDocumentNumber",
      "listBExpirationDate",
      "listCDocumentTitle",
      "listCIssuingAuthority",
      "listCDocumentNumber",
      "listCExpirationDate",
    ],
    requiredFieldArr: ["citizenship"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getWorkAuthorizationDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    this.updateWorkAuthorization();
  };

  updateWorkAuthorization = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_WORK_AUTHORIZATION,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success",
          "Work Authorization updated successfully."
        );
        this.formRef.current.resetFields();
        this.props.history.push("/manage-work-authorization", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getWorkAuthorizationDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_WORK_AUTHORIZATION_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeeWorkAuthorizationRecordById);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeWorkAuthorizationRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const {
      fieldArr,
      currentRoute,
      apiLoading,
      btnLoading,
      personId,
      requiredFieldArr,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-work-authorization" ? "Add" : "Edit"}{" "}
                Work Authorization : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Work Authorization
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-work-authorization"
                      ? "Add"
                      : "Edit"}{" "}
                    WorkAuthorization
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              requiredFieldArr={requiredFieldArr}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddWorkAuthorization);
