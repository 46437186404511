import gql from "graphql-tag";

export const ADD_APPLICANT = gql`
  mutation createApplicantRecord(
    $personId: String!
    $applicantFirstName: String
    $applicantLastName: String
    $applicantMiddleName: String
    $applicantZip: String
    $applicantCountry: String
    $applicantState: String
    $applicantCity: String
    $applicantPhone: String
    $applicantEmail: String
    $whereDidYouHearAboutThisCompany: String
    $applicantGender: String
    $applicantEthnicOrigin: String
    $applicantCitizenship: String
    $jobScore: String
    $applicantStatus: String
    $rejectedReason: String
    $changeStatus: String
  ) {
    createApplicantRecord(
      personId: $personId
      data: {
        applicantFirstName: $applicantFirstName
        applicantLastName: $applicantLastName
        applicantMiddleName: $applicantMiddleName
        applicantZip: $applicantZip
        applicantCountry: $applicantCountry
        applicantState: $applicantState
        applicantCity: $applicantCity
        applicantPhone: $applicantPhone
        applicantEmail: $applicantEmail
        whereDidYouHearAboutThisCompany: $whereDidYouHearAboutThisCompany
        applicantGender: $applicantGender
        applicantEthnicOrigin: $applicantEthnicOrigin
        applicantCitizenship: $applicantCitizenship
        jobScore: $jobScore
        applicantStatus: $applicantStatus
        rejectedReason: $rejectedReason
        changeStatus: $changeStatus
      }
    )
  }
`;

export const APPLICANT_LIST_QUERY = gql`
  query getEmployeeApplicantRecords($personId: String!) {
    getEmployeeApplicantRecords(personId: $personId) {
      applicantRecordId
      personId
      applicantFirstName
      applicantLastName
      applicantMiddleName
      applicantZip
      applicantCountry
      applicantState
      applicantCity
      applicantPhone
      applicantEmail
      whereDidYouHearAboutThisCompany
      applicantGender
      applicantEthnicOrigin
      applicantCitizenship
      jobScore
      applicantStatus
      rejectedReason
      changeStatus
    }
  }
`;

export const GET_APPLICANT_QUERY = gql`
  query getEmployeeApplicantRecordById($recordId: String!) {
    getEmployeeApplicantRecordById(recordId: $recordId) {      
      personId
      applicantFirstName
      applicantLastName
      applicantMiddleName
      applicantZip
      applicantCountry
      applicantState
      applicantCity
      applicantPhone
      applicantEmail
      whereDidYouHearAboutThisCompany
      applicantGender
      applicantEthnicOrigin
      applicantCitizenship
      jobScore
      applicantStatus
      rejectedReason
      changeStatus
    }
  }
`;

export const UPDATE_APPLICANT = gql`
  mutation updateApplicantRecord(
    $personId: String!
    $applicantFirstName: String
    $applicantLastName: String
    $applicantMiddleName: String
    $applicantZip: String
    $applicantCountry: String
    $applicantState: String
    $applicantCity: String
    $applicantPhone: String
    $applicantEmail: String
    $whereDidYouHearAboutThisCompany: String
    $applicantGender: String
    $applicantEthnicOrigin: String
    $applicantCitizenship: String
    $jobScore: String
    $applicantStatus: String
    $rejectedReason: String
    $changeStatus: String
  ) {
    updateApplicantRecord(
      personId: $personId
      data: {
        applicantFirstName: $applicantFirstName
        applicantLastName: $applicantLastName
        applicantMiddleName: $applicantMiddleName
        applicantZip: $applicantZip
        applicantCountry: $applicantCountry
        applicantState: $applicantState
        applicantCity: $applicantCity
        applicantPhone: $applicantPhone
        applicantEmail: $applicantEmail
        whereDidYouHearAboutThisCompany: $whereDidYouHearAboutThisCompany
        applicantGender: $applicantGender
        applicantEthnicOrigin: $applicantEthnicOrigin
        applicantCitizenship: $applicantCitizenship
        jobScore: $jobScore
        applicantStatus: $applicantStatus
        rejectedReason: $rejectedReason
        changeStatus: $changeStatus
      }
    )
  }
`;

export const GET_APPLICANT_HISTORY = gql`
  query getEmployeeApplicantRecordHistoryById($recordId: String!) {
    getEmployeeApplicantRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        applicantFirstName
        applicantLastName
        applicantMiddleName
        applicantZip
        applicantCountry
        applicantState
        applicantCity
        applicantPhone
        applicantEmail
        whereDidYouHearAboutThisCompany
        applicantGender
        applicantEthnicOrigin
        applicantCitizenship
        jobScore
        applicantStatus
        rejectedReason
        changeStatus
        recordSource
      }
    }
  }
`;

export const DELETE_APPLICANT = gql`
  mutation deleteApplicantRecord($recordId: String!) {
    deleteApplicantRecord(recordId: $recordId)
  }
`;
