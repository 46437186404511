import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { GET_EMPLOYMENT_QUERY, UPDATE_EMPLOYMENT } from "./EmploymentQuery";

const { Content } = Layout;
class AddEmployment extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "newHireReportDate",
      "lastReviewDate",
      "newHireReported",
      "terminationType",
      "lastPayRaise",
      "lastPromotion",
      "nextReviewDate",
      "trainingTrack",
      "disabledVeteran",
      "armedForcesServiceVeteran",
      "vietnamEraVeteran",
      "otherProtectedVeteran",
      "costCenter4",
      "costCenter5",
      "LastPaidDate",
      "status",
      "changeReason",
      "jobClassification",
      "eeoCode",
      "cc1OrLocation",
      "cc3OrDivision",
      "cc2OrDepartment",
      "isUnion",
      "positionTitle",
      "otherId",
      "wcJobClassCode",
      "wcType",
      "employeeIsAVeteran",
      "activeDutyReleaseDate",
      "type",
      "employeeID",
      "benefitClass",
      "workPhone",
      "workEmail",
      "badgeNumber",
      "okToRehire",
      "originalHireDate",
      "seniorityDate",
      "terminationDate",
      "hireDate",
      "reportsTo",
      "payGroupName",
      "terminationReason",
    ],
    datepickerFields: ["seniorityDate", "terminationDate"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getEmploymentDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    this.updateEmployment();
  };

  updateEmployment = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_EMPLOYMENT,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Employment updated successfully.");
        this.formRef.current.resetFields();
        this.props.history.push("/manage-employment", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getEmploymentDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_EMPLOYMENT_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeeEmploymentRecordById);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeEmploymentRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleDatepickerChange = (date, dateString, name) => {
    this.setState({ [name]: dateString });
  };

  render() {
    const {
      fieldArr,
      currentRoute,
      apiLoading,
      btnLoading,
      personId,
      datepickerFields,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-employment" ? "Add" : "Edit"} Employment
                : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Employment
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-employment" ? "Add" : "Edit"}
                    Employment
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              datepickerFields={datepickerFields}
                              handleDatepickerChange={
                                this.handleDatepickerChange
                              }
                              stateValues={this.state}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddEmployment);
