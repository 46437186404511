import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import { GET_SAFETY_QUERY, UPDATE_SAFETY } from "./SafetyQuery";

const { Content } = Layout;
class AddSafety extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "oshaLocation",
      "policeReport",
      "injuryType",
      "initialTreatment",
      "injury",
      "skinDisorder",
      "poisoning",
      "hearingLoss",
      "otherIllness",
      "death",
      "otherRecordableCases",
      "respiratoryCondition",
      "timeEmployeeBeganWork",
      "dateOfDeath",
      "policeReport2",
      "dayOfWeek",
      "weatherCondition",
      "incidentLocation",
      "incidentCity",
      "incidentCause",
      "hoursLost",
      "dateReceiv",
      "reportedBy",
      "incidentDescription",
      "osha",
      "dot",
      "privacyCase",
      "strikethrough",
      "incidentState",
      "description",
      "physicianClaimID",
      "physicianSreet2",
      "facilityClaimID",
      "facilityStreet1",
      "facilityStreet2",
      "treatment2",
      "treatment3",
      "accidentType",
      "equipment",
      "superIntendent",
      "foreman",
      "operations",
      "job",
      "causes",
      "towed",
      "facilityCity",
      "facilityStateOrProvince",
      "facilityCountryOrRegion",
      "facilityZipOrPostalCode",
      "physicianCountry",
      "caseNumber",
      "incidentDa",
      "medicalCosts",
      "incidentTime",
      "advisor",
      "caseClosedOn",
      "completedBy",
      "jobTitle",
      "workPhone",
      "filedOn",
      "employeeTreatedInAnEmergencyRoom",
      "employeeHospitalizedOvernightAsAnInPatient",
      "physician",
      "facility",
      "physicianStateOrProvince",
      "physicianStreet1",
      "physicianCity",
      "physicianZipOrPostalCode",
      "claimType",
      "outcome",
      "jobTransferOrRestriction",
      "daysAwayFromWork",
      "notReported",
    ],
    requiredFieldArr: ["caseNumber"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getSafetyDetail(recordId);
      this.setState({ recordId });
    } else {
      this.setState({ personId: this.props.history.location.state.personId });
    }
  }

  onFinish = (values) => {
    this.updateSafety();
  };

  updateSafety = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_SAFETY,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Safety updated successfully.");
        this.formRef.current.resetFields();
        this.props.history.push("/manage-safety", {
          personId: this.state.personId,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getSafetyDetail = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_SAFETY_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeeSafetyRecordById);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeeSafetyRecordById
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const {
      fieldArr,
      currentRoute,
      apiLoading,
      btnLoading,
      personId,
      requiredFieldArr,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-safety" ? "Add" : "Edit"} Safety :{" "}
                {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Safety
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-safety" ? "Add" : "Edit"}
                    Safety
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              requiredFieldArr={requiredFieldArr}
                            />
                          );
                        })}
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddSafety);
