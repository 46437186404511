import gql from "graphql-tag";

export const CREATE_TEMPLATE = gql`
  mutation createCSVMappingTemplate(
    $templateName: String!
    $recordName: String!
    $templateFields: [String]!
  ) {
    createCSVMappingTemplate(
      data: {
        templateName: $templateName
        recordName: $recordName
        templateFields: $templateFields
      }
    )
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteCSVMappingTemplate($templateId: String!) {
    deleteCSVMappingTemplate(templateId: $templateId)
  }
`;

export const GET_ALL_TEMPLATES = gql`
  query getCSVMappingTemplates(
    $first: Int
    $offset: Int
    $sortBy: sortInput
    $searchQuery: String
  ) {
    getCSVMappingTemplates(
      first: $first
      offset: $offset
      sortBy: $sortBy
      searchQuery: $searchQuery
    ) {
      templateId
      templateName
      recordName
      templateFields
    }
  }
`;

export const GET_TEMPLATE_BY_ID = gql`
  query getCSVMappingTemplateById($templateId: String!) {
    getCSVMappingTemplateById(templateId: $templateId) {
      templateId
      templateName
      recordName
      templateFields
      templateCreatedBy
      createdAt
      updatedAt
    }
  }
`;
