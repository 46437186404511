import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import HistoryFields from "../common/HistoryFields";
import RecordMenu from "../common/RecordMenu";

import { authUserData } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

import { GET_EMPLOYEE_QUERY, GET_EMPLOYEE_HISTORY } from "./EmployeeQuery";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;
class EmployeeDetail extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    historyData: [],
  };

  componentDidMount() {
    let employeeId = this.props.match.params.employeeId
      ? atob(this.props.match.params.employeeId)
      : "";

    if (employeeId !== "") {
      this.getEmployeeDetail(employeeId);
      this.setState({ employeeId });
    }
  }

  getEmployeeDetail = (employeeId) => {
    let detailResponse = "";
    this.setState({ apiLoading: true });

    this.props.client
      .mutate({
        mutation: GET_EMPLOYEE_QUERY,
        variables: { personId: employeeId },
      })
      .then((response) => {
        detailResponse = response;
        return this.getHistory(employeeId);
      })
      .then((historydata) => {
        let historyObj = {
          apiLoading: false,
          historyData:
            historydata.data.getEmployeePersonalInformationRecordHistory,
        };
        let stateObject = {
          ...historyObj,
          ...detailResponse.data.getEmployeePersonalInformationRecord,
        };
        this.setState(stateObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  getHistory = (employeeId) => {
    return this.props.client.mutate({
      mutation: GET_EMPLOYEE_HISTORY,
      variables: { personId: employeeId },
    });
  };

  render() {
    const {
      apiLoading,
      btnLoading,
      authUserId,
      recordId,
      employeeId,
      historyData,
      personId,
      ...fieldsObject
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Employee Detail : {employeeId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/manage-employee">Manage Employee</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Employee Detail</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper employeeDetail">
                <div className="employeeDetail_wraper">
                  <div className="heading">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col xs={24} align="end">
                        <Space>
                          <Link to={"/edit-employee/" + btoa(employeeId)}>
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              icon={<EditOutlined />}
                            >
                              Edit
                            </Button>
                          </Link>

                          <RecordMenu
                            history={this.props.history}
                            personId={employeeId}
                            buttonStyle={true}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </div>

                  <Spin spinning={apiLoading}>
                    <Form ref={this.formRef}>
                      <Row gutter={[15]}>
                        <HistoryFields
                          fieldsObject={fieldsObject}
                          historyData={historyData}
                          showHistoryButton={true}
                        />
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        align="end"
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="submit"
                              size="large"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(EmployeeDetail);
