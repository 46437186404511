import gql from "graphql-tag";

export const ADD_ATTENDANE_POINTS = gql`
  mutation createAttendancePointsRecord(
    $personId: String!
    $dateOn: Date
    $reason: String
    $comments: String
  ) {
    createAttendancePointsRecord(
      personId: $personId
      data: { dateOn: $dateOn, reason: $reason, comments: $comments }
    )
  }
`;

export const ATTENDANE_POINTS_LIST_QUERY = gql`
  query getEmployeeAttendancePointsRecords($personId: String!) {
    getEmployeeAttendancePointsRecords(personId: $personId) {
      attendanePointsId
      personId
      dateOn
      reason
      comments
    }
  }
`;

export const GET_ATTENDANE_POINTS_QUERY = gql`
  query getEmployeeAttendancePointsRecordById($recordId: String!) {
    getEmployeeAttendancePointsRecordById(recordId: $recordId) {
      personId
      dateOn
      reason
      comments
    }
  }
`;

export const UPDATE_ATTENDANE_POINTS = gql`
  mutation updateAttendancePointsRecord(
    $personId: String!
    $dateOn: Date!
    $reason: String
    $comments: String
  ) {
    updateAttendancePointsRecord(
      personId: $personId
      data: { dateOn: $dateOn, reason: $reason, comments: $comments }
    )
  }
`;

export const GET_ATTENDANE_POINTS_HISTORY = gql`
  query getEmployeeAttendancePointsRecordHistoryById($recordId: String!) {
    getEmployeeAttendancePointsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        attendanePointsId
        personId
        dateOn
        reason
        comments
        recordSource
      }
    }
  }
`;

export const DELETE_ATTENDANE_POINTS = gql`
  mutation deleteAttendancePointsRecord($recordId: String!) {
    deleteAttendancePointsRecord(recordId: $recordId)
  }
`;
