import React from "react";
import CanvasJSReact from "../../charts/canvasjs.react";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const EmployeeDepartmentChart = (props) => {
  const ChartDataObject = {
    animationEnabled: true,
    exportEnabled: false,
    theme: "light2",
    height: 435,
    dataPointMaxWidth: 70,
    title: {
      text: "",
      fontFamily: "Euclid Circular A",
      fontSize: 14,
      horizontalAlign: "left",
      showLegend:true,
      padding: {
        top: 1,
        right: 1,
        bottom: 30,
        left: 15,
      },
    },
    axisY: {
      includeZero: true,
    },
    data: [
      {
        type: "column",
        indexLabelFontColor: "#5A5757",
        indexLabel: false,
        bevelEnabled: true,
        indexLabelPlacement: "outside",
        dataPoints: props.departmentChartData,
      },
    ],
  };

  return (
    <div className="chart-wrapper">
      <div className="mainchart_title">
        <h3>Employee count by department</h3>        
      </div>
      <div className="w1">
        <CanvasJSChart options={ChartDataObject} />
      </div>
    </div>
  );
};

export default EmployeeDepartmentChart;
