import gql from "graphql-tag";

export const ADD_BENEFITS_RECORD = gql`
  mutation createBenefitsRecord(
    $personId: String!
    $planName: String
    $planEndDate: Date
    $effectiveDate: Date
    $coverageLevel: String
    $totalPremium: String
    $employerPortion: String
    $employeePortion: String
    $deductionAmount: String
    $terminationDate: String
    $terminationReason: String
    $waived: String
    $waiverReason: String
  ) {
    createBenefitsRecord(
      personId: $personId
      data: {
        planName: $planName
        planEndDate: $planEndDate
        effectiveDate: $effectiveDate
        coverageLevel: $coverageLevel
        totalPremium: $totalPremium
        employerPortion: $employerPortion
        employeePortion: $employeePortion
        deductionAmount: $deductionAmount
        terminationDate: $terminationDate
        terminationReason: $terminationReason
        waived: $waived
        waiverReason: $waiverReason
      }
    )
  }
`;

export const BENEFITS_RECORD_LIST_QUERY = gql`
  query getEmployeeBenefitsRecords($personId: String!) {
    getEmployeeBenefitsRecords(personId: $personId) {
      benefitsRecordId
      personId
      planName
      planEndDate
      effectiveDate
      coverageLevel
      totalPremium
      employerPortion
      employeePortion
      deductionAmount
      terminationDate
      terminationReason
      waived
      waiverReason      
    }
  }
`;

export const GET_BENEFITS_RECORD_QUERY = gql`
  query getEmployeeBenefitsRecordById($recordId: String!) {
    getEmployeeBenefitsRecordById(recordId: $recordId) {
      personId
      planName
      planEndDate
      effectiveDate
      coverageLevel
      totalPremium
      employerPortion
      employeePortion
      deductionAmount
      terminationDate
      terminationReason
      waived
      waiverReason      
    }
  }
`;

export const UPDATE_BENEFITS_RECORD = gql`
  mutation updateBenefitsRecord(
    $personId: String!
    $planName: String!
    $planEndDate: Date
    $effectiveDate: Date!
    $coverageLevel: String
    $totalPremium: String
    $employerPortion: String
    $employeePortion: String
    $deductionAmount: String
    $terminationDate: String
    $terminationReason: String
    $waived: String
    $waiverReason: String
  ) {
    updateBenefitsRecord(
      personId: $personId
      data: {
        planName: $planName
        planEndDate: $planEndDate
        effectiveDate: $effectiveDate
        coverageLevel: $coverageLevel
        totalPremium: $totalPremium
        employerPortion: $employerPortion
        employeePortion: $employeePortion
        deductionAmount: $deductionAmount
        terminationDate: $terminationDate
        terminationReason: $terminationReason
        waived: $waived
        waiverReason: $waiverReason
      }
    )
  }
`;

export const GET_BENEFITS_RECORD_HISTORY = gql`
  query getEmployeeBenefitsRecordHistoryById($recordId: String!) {
    getEmployeeBenefitsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        benefitsRecordId
        personId
        planName
        planEndDate
        effectiveDate
        coverageLevel
        totalPremium
        employerPortion
        employeePortion
        deductionAmount
        terminationDate
        terminationReason
        waived
        waiverReason        
        recordSource
      }
    }
  }
`;

export const DELETE_BENEFITS_RECORD = gql`
  mutation deleteBenefitsRecord($recordId: String!) {
    deleteBenefitsRecord(recordId: $recordId)
  }
`;
