import React, { Component } from "react";
import "./Common.scss";
export class Maintenance extends Component {
  render() {
    return (
      <div>
        <h1 className="text-center">Website Under Maintenance.</h1>
      </div>
    );
  }
}

export default Maintenance;
