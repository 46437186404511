import gql from "graphql-tag";

export const ADD_TRAINING_RECORD = gql`
  mutation createTrainingRecord(
    $personId: String!
    $trainingRecord: String
    $trainingType: String
    $className: String
    $instructor: String
    $type: String
    $enrollmentDate: Date
    $startDate: Date
    $endDate: Date
    $location: String
    $hours: String
    $units: String
    $completionDate: Date
    $expirationDate: Date
    $certificate: String
  ) {
    createTrainingRecord(
      personId: $personId
      data: {
        trainingRecord: $trainingRecord
        trainingType: $trainingType
        className: $className
        instructor: $instructor
        type: $type
        enrollmentDate: $enrollmentDate
        startDate: $startDate
        endDate: $endDate
        location: $location
        hours: $hours
        units: $units
        completionDate: $completionDate
        expirationDate: $expirationDate
        certificate: $certificate
      }
    )
  }
`;

export const TRAINING_RECORD_LIST_QUERY = gql`
  query getEmployeeTrainingRecords($personId: String!) {
    getEmployeeTrainingRecords(personId: $personId) {
      trainingRecordId
      personId
      trainingRecord
      trainingType
      className
      instructor
      type
      enrollmentDate
      startDate
      endDate
      location
      hours
      units
      completionDate
      expirationDate
      certificate
    }
  }
`;

export const GET_TRAINING_RECORD_QUERY = gql`
  query getEmployeeTrainingRecordById($recordId: String!) {
    getEmployeeTrainingRecordById(recordId: $recordId) {
      personId
      trainingRecord
      trainingType
      className
      instructor
      type
      enrollmentDate
      startDate
      endDate
      location
      hours
      units
      completionDate
      expirationDate
      certificate
    }
  }
`;

export const UPDATE_TRAINING_RECORD = gql`
  mutation updateTrainingRecord(
    $recordId: String!
    $trainingRecord: String
    $trainingType: String
    $className: String
    $instructor: String
    $type: String
    $enrollmentDate: Date
    $startDate: Date
    $endDate: Date
    $location: String
    $hours: String
    $units: String
    $completionDate: Date
    $expirationDate: Date
    $certificate: String
  ) {
    updateTrainingRecord(
      recordId: $recordId
      data: {
        trainingRecord: $trainingRecord
        trainingType: $trainingType
        className: $className
        instructor: $instructor
        type: $type
        enrollmentDate: $enrollmentDate
        startDate: $startDate
        endDate: $endDate
        location: $location
        hours: $hours
        units: $units
        completionDate: $completionDate
        expirationDate: $expirationDate
        certificate: $certificate
      }
    )
  }
`;

export const GET_TRAINING_RECORD_HISTORY = gql`
  query getEmployeeTrainingRecordHistoryById($recordId: String!) {
    getEmployeeTrainingRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        trainingRecordId
        personId
        trainingRecord
        trainingType
        className
        instructor
        type
        enrollmentDate
        startDate
        endDate
        location
        hours
        units
        completionDate
        expirationDate
        certificate

        recordSource
      }
    }
  }
`;

export const DELETE_TRAINING_RECORD = gql`
  mutation deleteTrainingRecord($recordId: String!) {
    deleteTrainingRecord(recordId: $recordId)
  }
`;
