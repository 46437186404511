import gql from "graphql-tag";

export const ADD_LEAVE_ABSENCE = gql`
  mutation createLeaveOrAbsenceRecord(
    $personId: String!
    $dateReceived: Date
    $dateApproved: Date
    $dateDesignationNoticeSent: Date
    $dateNoticeOfEligibilitySent: Date
    $noticeOfEligibilitySentBy: String
    $dateCertificationFormSent: Date
    $certificationFormSentBy: String
    $certificationFormToBeReturnedBy: String
    $dateCertificationFormReturned: Date
    $certificationFormReturnedBy: String
    $leaveStartDate: Date
    $estimatedReturnToWorkDate: Date
    $actualReturnToWorkDate: Date
    $hoursUsed: String
    $hoursRequested: String
    $certificationType: String
    $certificationSubType: String
    $leaveType: String
    $active: String
  ) {
    createLeaveOrAbsenceRecord(
      personId: $personId
      data: {
        dateReceived: $dateReceived
        dateApproved: $dateApproved
        dateDesignationNoticeSent: $dateDesignationNoticeSent
        dateNoticeOfEligibilitySent: $dateNoticeOfEligibilitySent
        noticeOfEligibilitySentBy: $noticeOfEligibilitySentBy
        dateCertificationFormSent: $dateCertificationFormSent
        certificationFormSentBy: $certificationFormSentBy
        certificationFormToBeReturnedBy: $certificationFormToBeReturnedBy
        dateCertificationFormReturned: $dateCertificationFormReturned
        certificationFormReturnedBy: $certificationFormReturnedBy
        leaveStartDate: $leaveStartDate
        estimatedReturnToWorkDate: $estimatedReturnToWorkDate
        actualReturnToWorkDate: $actualReturnToWorkDate
        hoursUsed: $hoursUsed
        hoursRequested: $hoursRequested
        certificationType: $certificationType
        certificationSubType: $certificationSubType
        leaveType: $leaveType
        active: $active
      }
    )
  }
`;

export const LEAVE_ABSENCE_LIST_QUERY = gql`
  query getEmployeeLeaveOrAbsenceRecords($personId: String!) {
    getEmployeeLeaveOrAbsenceRecords(personId: $personId) {
      leaveOrAbsenceRecordId
      personId
      dateReceived
      dateApproved
      dateDesignationNoticeSent
      dateNoticeOfEligibilitySent
      noticeOfEligibilitySentBy
      dateCertificationFormSent
      certificationFormSentBy
      certificationFormToBeReturnedBy
      dateCertificationFormReturned
      certificationFormReturnedBy
      leaveStartDate
      estimatedReturnToWorkDate
      actualReturnToWorkDate
      hoursUsed
      hoursRequested
      certificationType
      certificationSubType
      leaveType
      active
    }
  }
`;

export const GET_LEAVE_ABSENCE_QUERY = gql`
  query getEmployeeLeaveOrAbsenceRecordById($recordId: String!) {
    getEmployeeLeaveOrAbsenceRecordById(recordId: $recordId) {
      personId
      dateReceived
      dateApproved
      dateDesignationNoticeSent
      dateNoticeOfEligibilitySent
      noticeOfEligibilitySentBy
      dateCertificationFormSent
      certificationFormSentBy
      certificationFormToBeReturnedBy
      dateCertificationFormReturned
      certificationFormReturnedBy
      leaveStartDate
      estimatedReturnToWorkDate
      actualReturnToWorkDate
      hoursUsed
      hoursRequested
      certificationType
      certificationSubType
      leaveType
      active
    }
  }
`;

export const UPDATE_LEAVE_ABSENCE = gql`
  mutation updateLeaveOrAbsenceRecord(
    $personId: String!
    $dateReceived: Date!
    $dateApproved: Date
    $dateDesignationNoticeSent: Date
    $dateNoticeOfEligibilitySent: Date
    $noticeOfEligibilitySentBy: String
    $dateCertificationFormSent: Date
    $certificationFormSentBy: String
    $certificationFormToBeReturnedBy: String
    $dateCertificationFormReturned: Date
    $certificationFormReturnedBy: String
    $leaveStartDate: Date
    $estimatedReturnToWorkDate: Date
    $actualReturnToWorkDate: Date
    $hoursUsed: String
    $hoursRequested: String
    $certificationType: String
    $certificationSubType: String
    $leaveType: String
    $active: String
  ) {
    updateLeaveOrAbsenceRecord(
      personId: $personId
      data: {
        dateReceived: $dateReceived
        dateApproved: $dateApproved
        dateDesignationNoticeSent: $dateDesignationNoticeSent
        dateNoticeOfEligibilitySent: $dateNoticeOfEligibilitySent
        noticeOfEligibilitySentBy: $noticeOfEligibilitySentBy
        dateCertificationFormSent: $dateCertificationFormSent
        certificationFormSentBy: $certificationFormSentBy
        certificationFormToBeReturnedBy: $certificationFormToBeReturnedBy
        dateCertificationFormReturned: $dateCertificationFormReturned
        certificationFormReturnedBy: $certificationFormReturnedBy
        leaveStartDate: $leaveStartDate
        estimatedReturnToWorkDate: $estimatedReturnToWorkDate
        actualReturnToWorkDate: $actualReturnToWorkDate
        hoursUsed: $hoursUsed
        hoursRequested: $hoursRequested
        certificationType: $certificationType
        certificationSubType: $certificationSubType
        leaveType: $leaveType
        active: $active
      }
    )
  }
`;

export const GET_LEAVE_ABSENCE_HISTORY = gql`
  query getEmployeeLeaveOrAbsenceRecordHistoryById($recordId: String!) {
    getEmployeeLeaveOrAbsenceRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        dateReceived
        dateApproved
        dateDesignationNoticeSent
        dateNoticeOfEligibilitySent
        noticeOfEligibilitySentBy
        dateCertificationFormSent
        certificationFormSentBy
        certificationFormToBeReturnedBy
        dateCertificationFormReturned
        certificationFormReturnedBy
        leaveStartDate
        estimatedReturnToWorkDate
        actualReturnToWorkDate
        hoursUsed
        hoursRequested
        certificationType
        certificationSubType
        leaveType
        active
        recordSource
      }
    }
  }
`;

export const DELETE_LEAVE_ABSENCE = gql`
  mutation deleteLeaveOrAbsenceRecord($recordId: String!) {
    deleteLeaveOrAbsenceRecord(recordId: $recordId)
  }
`;
