import React, { Component } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

import Config from "./Config";
import Routes from "./components/common/Routes";
import { AUTH_USER_TOKEN_KEY } from "./utils/constants";

const history = createBrowserHistory();
const apolloCache = new InMemoryCache();
const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);

const uploadLink = createUploadLink({
  uri: Config.API_URL,
  headers: {
    "keep-alive": "true",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

const client = new ApolloClient({
  cache: apolloCache,
  link: uploadLink,
});

export default class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router history={history}>
          <Routes />
        </Router>
      </ApolloProvider>
    );
  }
}
