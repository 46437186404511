import gql from "graphql-tag";

export const ADD_EMPLOYMENT = gql`
  mutation createEmploymentRecord(
    $personId: String!
    $newHireReportDate: Date
    $lastReviewDate: Date
    $newHireReported: String
    $terminationType: String
    $lastPayRaise: String
    $lastPromotion: String
    $nextReviewDate: Date
    $trainingTrack: String
    $disabledVeteran: String
    $armedForcesServiceVeteran: String
    $vietnamEraVeteran: String
    $otherProtectedVeteran: String
    $costCenter4: String
    $costCenter5: String
    $lastPaidDate: Date
    $status: String
    $changeReason: String
    $jobClassification: String
    $eeoCode: String
    $cc1OrLocation: String
    $cc3OrDivision: String
    $cc2OrDepartment: String
    $isUnion: String
    $positionTitle: String
    $otherId: String
    $wcJobClassCode: String
    $wcType: String
    $employeeIsAVeteran: String
    $activeDutyReleaseDate: String
    $type: String
    $employeeID: String
    $benefitClass: String
    $workPhone: String
    $workEmail: String
    $badgeNumber: String
    $okToRehire: String
    $originalHireDate: Date
    $seniorityDate: Date
    $terminationDate: Date
    $hireDate: Date
    $reportsTo: String
    $payGroupName: String
    $terminationReason: String
  ) {
    createEmploymentRecord(
      personId: $personId
      data: {
        newHireReportDate: $newHireReportDate
        lastReviewDate: $lastReviewDate
        newHireReported: $newHireReported
        terminationType: $terminationType
        lastPayRaise: $lastPayRaise
        lastPromotion: $lastPromotion
        nextReviewDate: $nextReviewDate
        trainingTrack: $trainingTrack
        disabledVeteran: $disabledVeteran
        armedForcesServiceVeteran: $armedForcesServiceVeteran
        vietnamEraVeteran: $vietnamEraVeteran
        otherProtectedVeteran: $otherProtectedVeteran
        costCenter4: $costCenter4
        costCenter5: $costCenter5
        lastPaidDate: $lastPaidDate
        status: $status
        changeReason: $changeReason
        jobClassification: $jobClassification
        eeoCode: $eeoCode
        cc1OrLocation: $cc1OrLocation
        cc3OrDivision: $cc3OrDivision
        cc2OrDepartment: $cc2OrDepartment
        isUnion: $isUnion
        positionTitle: $positionTitle
        otherId: $otherId
        wcJobClassCode: $wcJobClassCode
        wcType: $wcType
        employeeIsAVeteran: $employeeIsAVeteran
        activeDutyReleaseDate: $activeDutyReleaseDate
        type: $type
        employeeID: $employeeID
        benefitClass: $benefitClass
        workPhone: $workPhone
        workEmail: $workEmail
        badgeNumber: $badgeNumber
        okToRehire: $okToRehire
        originalHireDate: $originalHireDate
        seniorityDate: $seniorityDate
        terminationDate: $terminationDate
        hireDate: $hireDate
        reportsTo: $reportsTo
        payGroupName: $payGroupName
        terminationReason: $terminationReason
      }
    )
  }
`;

export const EMPLOYMENT_LIST_QUERY = gql`
  query getEmployeeEmploymentRecords($personId: String!) {
    getEmployeeEmploymentRecords(personId: $personId) {
      employmentRecordId
      personId
      newHireReportDate
      lastReviewDate
      newHireReported
      terminationType
      lastPayRaise
      lastPromotion
      nextReviewDate
      trainingTrack
      disabledVeteran
      armedForcesServiceVeteran
      vietnamEraVeteran
      otherProtectedVeteran
      costCenter4
      costCenter5
      lastPaidDate
      status
      changeReason
      jobClassification
      eeoCode
      cc1OrLocation
      cc3OrDivision
      cc2OrDepartment
      isUnion
      positionTitle
      otherId
      wcJobClassCode
      wcType
      employeeIsAVeteran
      activeDutyReleaseDate
      type
      employeeID
      benefitClass
      workPhone
      workEmail
      badgeNumber
      okToRehire
      originalHireDate
      seniorityDate
      terminationDate
      hireDate
      reportsTo
      payGroupName
      terminationReason
    }
  }
`;

export const GET_EMPLOYMENT_QUERY = gql`
  query getEmployeeEmploymentRecordById($recordId: String!) {
    getEmployeeEmploymentRecordById(recordId: $recordId) {      
      personId
      newHireReportDate
      lastReviewDate
      newHireReported
      terminationType
      lastPayRaise
      lastPromotion
      nextReviewDate
      trainingTrack
      disabledVeteran
      armedForcesServiceVeteran
      vietnamEraVeteran
      otherProtectedVeteran
      costCenter4
      costCenter5
      lastPaidDate
      status
      changeReason
      jobClassification
      eeoCode
      cc1OrLocation
      cc3OrDivision
      cc2OrDepartment
      isUnion
      positionTitle
      otherId
      wcJobClassCode
      wcType
      employeeIsAVeteran
      activeDutyReleaseDate
      type
      employeeID
      benefitClass
      workPhone
      workEmail
      badgeNumber
      okToRehire
      originalHireDate
      seniorityDate
      terminationDate
      hireDate
      reportsTo
      payGroupName
      terminationReason
    }
  }
`;

export const UPDATE_EMPLOYMENT = gql`
  mutation updateEmploymentRecord(
    $personId: String!
    $newHireReportDate: Date
    $lastReviewDate: Date
    $newHireReported: String
    $terminationType: String
    $lastPayRaise: String
    $lastPromotion: String
    $nextReviewDate: Date
    $trainingTrack: String
    $disabledVeteran: String
    $armedForcesServiceVeteran: String
    $vietnamEraVeteran: String
    $otherProtectedVeteran: String
    $costCenter4: String
    $costCenter5: String
    $lastPaidDate: Date
    $status: String
    $changeReason: String
    $jobClassification: String
    $eeoCode: String
    $cc1OrLocation: String
    $cc3OrDivision: String
    $cc2OrDepartment: String
    $isUnion: String
    $positionTitle: String
    $otherId: String
    $wcJobClassCode: String
    $wcType: String
    $employeeIsAVeteran: String
    $activeDutyReleaseDate: String
    $type: String
    $employeeID: String
    $benefitClass: String
    $workPhone: String
    $workEmail: String
    $badgeNumber: String
    $okToRehire: String
    $originalHireDate: Date
    $seniorityDate: Date
    $terminationDate: Date
    $hireDate: Date
    $reportsTo: String
    $payGroupName: String
    $terminationReason: String
  ) {
    updateEmploymentRecord(
      personId: $personId
      data: {
        newHireReportDate: $newHireReportDate
        lastReviewDate: $lastReviewDate
        newHireReported: $newHireReported
        terminationType: $terminationType
        lastPayRaise: $lastPayRaise
        lastPromotion: $lastPromotion
        nextReviewDate: $nextReviewDate
        trainingTrack: $trainingTrack
        disabledVeteran: $disabledVeteran
        armedForcesServiceVeteran: $armedForcesServiceVeteran
        vietnamEraVeteran: $vietnamEraVeteran
        otherProtectedVeteran: $otherProtectedVeteran
        costCenter4: $costCenter4
        costCenter5: $costCenter5
        lastPaidDate: $lastPaidDate
        status: $status
        changeReason: $changeReason
        jobClassification: $jobClassification
        eeoCode: $eeoCode
        cc1OrLocation: $cc1OrLocation
        cc3OrDivision: $cc3OrDivision
        cc2OrDepartment: $cc2OrDepartment
        isUnion: $isUnion
        positionTitle: $positionTitle
        otherId: $otherId
        wcJobClassCode: $wcJobClassCode
        wcType: $wcType
        employeeIsAVeteran: $employeeIsAVeteran
        activeDutyReleaseDate: $activeDutyReleaseDate
        type: $type
        employeeID: $employeeID
        benefitClass: $benefitClass
        workPhone: $workPhone
        workEmail: $workEmail
        badgeNumber: $badgeNumber
        okToRehire: $okToRehire
        originalHireDate: $originalHireDate
        seniorityDate: $seniorityDate
        terminationDate: $terminationDate
        hireDate: $hireDate
        reportsTo: $reportsTo
        payGroupName: $payGroupName
        terminationReason: $terminationReason
      }
    )
  }
`;

export const GET_EMPLOYMENT_HISTORY = gql`
  query getEmployeeEmploymentRecordHistoryById($recordId: String!) {
    getEmployeeEmploymentRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        newHireReportDate
        lastReviewDate
        newHireReported
        terminationType
        lastPayRaise
        lastPromotion
        nextReviewDate
        trainingTrack
        disabledVeteran
        armedForcesServiceVeteran
        vietnamEraVeteran
        otherProtectedVeteran
        costCenter4
        costCenter5
        lastPaidDate
        status
        changeReason
        jobClassification
        eeoCode
        cc1OrLocation
        cc3OrDivision
        cc2OrDepartment
        isUnion

        positionTitle
        otherId
        wcJobClassCode
        wcType
        employeeIsAVeteran
        activeDutyReleaseDate
        type
        employeeID
        benefitClass
        workPhone
        workEmail
        badgeNumber
        okToRehire
        originalHireDate
        seniorityDate
        terminationDate
        hireDate
        reportsTo
        payGroupName
        terminationReason
        recordSource
      }
    }
  }
`;

export const DELETE_EMPLOYMENT = gql`
  mutation deleteEmploymentRecord($recordId: String!) {
    deleteEmploymentRecord(recordId: $recordId)
  }
`;
