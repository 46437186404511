import moment from "moment";
import { fieldNames } from "./fieldNames.json";
const jwtDecode = require("jwt-decode");

export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }

  try {
    const decodedJwt: any = jwtDecode(token);
    const isTokenValid = decodedJwt.exp >= Date.now() / 1000;

    if (!isTokenValid) {
      localStorage.clear();
    }

    return isTokenValid;
  } catch (e) {
    //console.log(e);
    return false;
  }
};

export const logout = () => {
  var grid_fields = localStorage.getItem("grid_fields");
  localStorage.clear();
  localStorage.setItem("grid_fields", grid_fields);
  window.location.href = "/";
};

export const authUserData = () => {
  let authData = localStorage.getItem("auth_data");
  return JSON.parse(authData);
};

export const redirectIfNotLoggedIn = () => {
  let authData = authUserData();
  if (authData === null) {
    window.location.href = "/login";
  }
};

export const redirectIfLoggedIn = () => {
  let authData = authUserData();
  if (authData !== null && authData !== undefined) {
    if (authData.sub !== "") {
      window.location.href = "/dashboard";
    }
  }
};

export const ImageUploadValidation = (file) => {
  let errorMsg = "";
  let isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    errorMsg = "You can only upload JPG/PNG file!";
  }

  let isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    errorMsg = "Image must smaller than 2MB!";
  }
  return errorMsg;
};

export const ToDateTime = (dateTimeValue) => {
  return moment(dateTimeValue).format("LLL");
};

export const CamelCaseToSpaceLetter = (inputString) => {
  let result = inputString.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const GetCustomizedFieldName = (name) => {
  return fieldNames[name] ? fieldNames[name] : name;
};

export const GetCustomizedAnalysisFieldName = (name) => {
  const [operation, column] = name.split("_");
  return fieldNames[column]
    ? operation.toUpperCase() + "_" + fieldNames[column]
    : name;
};
