import React, { Component } from "react";
import moment from "moment";
import { Col, Form, Input, DatePicker, Select } from "antd";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

const { Option } = Select;

export class InputField extends Component {
  render() {
    let fieldType;
    const {
      handleInputChange,
      name,
      title,
      requiredFieldArr,
      datepickerFields,
      handleDatepickerChange,
      statusField,
      handleSelectChange,
      stateValues,
    } = this.props;

    if (requiredFieldArr && requiredFieldArr.includes(name)) {
      fieldType = (
        <Col xs={24} sm={12} lg={12}>
          <label>{CamelCaseToSpaceLetter(title)}</label>
          <Form.Item
            name={name}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input field!",
              },
            ]}
          >
            <Input
              onChange={handleInputChange(name)}
              placeholder={CamelCaseToSpaceLetter(title)}
            />
          </Form.Item>
        </Col>
      );
    } else if (datepickerFields && datepickerFields.includes(name)) {
      fieldType = (
        <Col xs={24} sm={12} lg={12}>
          <label>{CamelCaseToSpaceLetter(title)}</label>
          <DatePicker
            format={"MM/DD/YYYY"}
            onChange={(date, dateString) =>
              handleDatepickerChange(date, dateString, name)
            }
            placeholder={CamelCaseToSpaceLetter(title)}
            style={{ width: "100%" }}
            value={stateValues[name] ? moment(stateValues[name]) : ""}
          />
        </Col>
      );
    } else if (statusField && statusField.includes(name)) {
      fieldType = (
        <Col xs={24} sm={12} lg={12}>
          <label>{CamelCaseToSpaceLetter(title)}</label>
          <Form.Item name={name}>
            <Select
              defaultValue="ACTIVE"
              style={{ width: "100%" }}
              onChange={(value) => handleSelectChange(value, name)}
            >
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          </Form.Item>
        </Col>
      );
    } else {
      fieldType = (
        <Col xs={24} sm={12} lg={12}>
          <label>{CamelCaseToSpaceLetter(title)}</label>
          <Form.Item name={name}>
            <Input
              onChange={handleInputChange(name)}
              placeholder={CamelCaseToSpaceLetter(title)}
            />
          </Form.Item>
        </Col>
      );
    }

    return <>{fieldType}</>;
  }
}

export default InputField;
