import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import HistoryFields from "../common/HistoryFields";

import { authUserData } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

import {
  GET_EMPLOYMENT_QUERY,
  GET_EMPLOYMENT_HISTORY,
} from "./EmploymentQuery";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;
class EmploymentDetail extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    historyData: [],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    if (recordId !== "") {
      this.getEmploymentDetail(recordId);
      this.setState({ recordId });
    }
  }

  getEmploymentDetail = (recordId) => {
    let detailResponse = "";
    this.setState({ apiLoading: true });

    this.props.client
      .mutate({
        mutation: GET_EMPLOYMENT_QUERY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        detailResponse = response;
        return this.getHistory(recordId);
      })
      .then((historydata) => {
        let historyObj = {
          apiLoading: false,
          historyData: historydata.data.getEmployeeEmploymentRecordHistoryById,
        };
        let stateObject = {
          ...historyObj,
          ...detailResponse.data.getEmployeeEmploymentRecordById,
        };
        this.setState(stateObject);
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  getHistory = (recordId) => {
    return this.props.client.mutate({
      mutation: GET_EMPLOYMENT_HISTORY,
      variables: { recordId: recordId },
    });
  };

  render() {
    const {
      apiLoading,
      btnLoading,
      authUserId,
      recordId,
      personId,
      historyData,
      ...fieldsObject
    } = this.state;

    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Employment Detail : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="#" onClick={this.props.history.goBack}>
                      Manage Employment
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Employment Detail</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper employeeDetail">
                <div className="employeeDetail_wraper">
                  <div className="heading">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col xs={24} align="end">
                        <Space>
                          <Link to={"/edit-employment/" + btoa(recordId)}>
                            <Button
                              type="primary"
                              htmlType="button"
                              size="small"
                              icon={<EditOutlined />}
                            >
                              Edit
                            </Button>
                          </Link>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Spin spinning={apiLoading}>
                    <Form ref={this.formRef} onFinish="">
                      <Row gutter={[15]}>
                        <HistoryFields
                          fieldsObject={fieldsObject}
                          historyData={historyData}
                          showHistoryButton={true}
                        />
                      </Row>
                      <Divider />
                      <Row
                        gutter={[15]}
                        align="end"
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="submit"
                              size="large"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(EmploymentDetail);
