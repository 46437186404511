import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { CSVDownloader } from "react-papaparse";
import { Table, Button, Spin, Space } from "antd";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SendReportEmail from "./SendReportEmail";

import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";
import {
  ToDateTime,
  GetCustomizedFieldName,
  GetCustomizedAnalysisFieldName,
} from "../../utils/Helpers";
import { VIEW_REPORT_QUERY } from "./ReportingToolQuery";

class Report extends Component {
  state = {
    showReport: false,
    apiLoading: false,
    reportFields: [],
    reportData: [],
    columnArr: [],
    analysisData: [],
    analysisColumnArr: [],
  };

  componentDidMount() {
    if (this.props.stateValues.preview) {
      this.generateReport(this.props.stateValues.previewReportResponse);
    } else {
      this.getReport(this.props.stateValues.reportId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stateValues.refreshCount !== prevProps.stateValues.refreshCount
    ) {
      this.getReport(this.props.stateValues.reportId);
    }
  }

  generateReport = (response) => {
    if (
      response.data.createReport.rows &&
      response.data.createReport.rows.length > 0
    ) {
      let colArr = Object.keys(response.data.createReport.rows[0]);
      let columnArr = [];
      colArr.forEach((element) => {
        columnArr.push({
          title: GetCustomizedFieldName(element),
          dataIndex: element,
          key: element,
        });
      });

      let analysisColumnArr = [];

      if (
        response.data.createReport.analysis &&
        response.data.createReport.analysis.length > 0
      ) {
        let analysisArr = Object.keys(response.data.createReport.analysis[0]);
        analysisArr.forEach((element) => {
          analysisColumnArr.push({
            title: GetCustomizedAnalysisFieldName(element),
            dataIndex: element,
            key: element,
          });
        });
      }

      this.setState({
        reportData: response.data.createReport.rows,
        analysisData: response.data.createReport.analysis,
        reportFields: Object.keys(response.data.createReport.rows[0]),
        apiLoading: false,
        columnArr,
        analysisColumnArr,
        reportGeneratedBy: response.data.createReport.reportGeneratedBy,
        reportGenerationDate: response.data.createReport.reportGenerationDate,
      });
    } else {
      this.setState({
        apiLoading: false,
      });
    }
  };

  getReport = (reportId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });

    client
      .mutate({
        mutation: VIEW_REPORT_QUERY,
        variables: { reportId },
      })
      .then((response) => {
        if (
          response.data.viewReport.rows &&
          response.data.viewReport.rows.length > 0
        ) {
          let colArr = Object.keys(response.data.viewReport.rows[0]);
          let columnArr = [];
          colArr.forEach((element) => {
            columnArr.push({
              title: GetCustomizedFieldName(element),
              dataIndex: element,
              key: element,
            });
          });

          let analysisColumnArr = [];

          if (
            response.data.viewReport.analysis &&
            response.data.viewReport.analysis.length > 0
          ) {
            let analysisArr = Object.keys(response.data.viewReport.analysis[0]);
            analysisArr.forEach((element) => {
              analysisColumnArr.push({
                title: GetCustomizedAnalysisFieldName(element),
                dataIndex: element,
                key: element,
              });
            });
          }

          this.setState({
            reportData: response.data.viewReport.rows,
            analysisData: response.data.viewReport.analysis,
            reportFields: Object.keys(response.data.viewReport.rows[0]),
            apiLoading: false,
            columnArr,
            analysisColumnArr,
            reportGeneratedBy: response.data.viewReport.reportGeneratedBy,
            reportGenerationDate: response.data.viewReport.reportGenerationDate,
          });
        } else {
          this.setState({
            apiLoading: false,
          });
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  exportPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
    });

    let cby = "Company Name: " + this.state.reportGeneratedBy;
    let cdate =
      "Report Generation Date: " + ToDateTime(this.state.reportGenerationDate);

    doc.autoTable({
      bodyStyles: { fontSize: 12, overflow: "linebreak" },
      headStyles: { fontSize: 12 },
      margin: { top: 0.3, left: 0.4 },
      body: [
        [
          {
            content: this.props.title,
            styles: { halign: "center", fontSize: "12", fontWeight: "800" },
          },
        ],
      ],
    });

    let displayArr = [];

    if (this.props.stateValues.displayOptions.companyName) {
      displayArr.push({
        content: cby,
        styles: { halign: "left", fontSize: "8", fontWeight: "500" },
      });
    }

    if (this.props.stateValues.displayOptions.reportGenerationDate) {
      displayArr.push({
        content: cdate,
        styles: { halign: "right", fontSize: "8", fontWeight: "500" },
      });
    }
    
    if (displayArr.length > 0) {
      doc.autoTable({
        bodyStyles: { fontSize: 8, overflow: "linebreak" },
        headStyles: { fontSize: 8 },
        margin: { left: 0.4 },
        body: [displayArr],
      });
    }

    let fsize = 11;
    if (this.state.columnArr.length > 15) {
      fsize = 4;
    } else if (
      this.state.columnArr.length <= 15 ||
      this.state.columnArr.length <= 8
    ) {
      fsize = 7;
    } else {
      fsize = 11;
    }

    doc.autoTable({
      bodyStyles: { fontSize: fsize, overflow: "linebreak" },
      headStyles: { fontSize: fsize },
      margin: { left: 0.4 },
      columns: this.state.columnArr,
      body: this.state.reportData,
    });

    doc.autoTable({
      bodyStyles: { fontSize: fsize, overflow: "linebreak" },
      headStyles: { fontSize: fsize },
      margin: { left: 0.4 },
      columns: this.state.analysisColumnArr,
      body: this.state.analysisData,
    });

    doc.save(this.props.title.replace(/ /g, "_") + ".pdf");
  };

  render() {
    const { title } = this.props;
    const { showReport, reportId } = this.props.stateValues;

    return (
      <>
        <Spin spinning={this.state.apiLoading}>
          {showReport ? (
            <React.Fragment>
              <div className="grid_heading export-button">
                {title ? title : ""}

                {this.state.reportData && this.state.reportData.length ? (
                  <Space>
                    {reportId !== null && reportId !== undefined ? (
                      <SendReportEmail
                        reportId={reportId}
                        reportTitle={title}
                      />
                    ) : (
                      ""
                    )}

                    <Button
                      icon={<FilePdfOutlined />}
                      size="small"
                      type="default"
                      className="exp-btn"
                      onClick={this.exportPDF}
                    >
                      Export to PDF
                    </Button>

                    <CSVDownloader
                      data={this.state.reportData}
                      filename={title.replace(/ /g, "_")}
                      type={"link"}
                    >
                      <Button
                        icon={<FileExcelOutlined />}
                        size="small"
                        type="default"
                        className="exp-btn"
                      >
                        Export to CSV
                      </Button>
                    </CSVDownloader>
                  </Space>
                ) : (
                  ""
                )}
              </div>
              <Table
                className="table_grid"
                columns={this.state.columnArr}
                dataSource={this.state.reportData}
                scroll={{ x: 970 }}
                pagination={false}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
              />
              {this.state.analysisColumnArr.length > 0 ? (
                <>
                  <br />
                  <br />
                  <Table
                    className="table_grid"
                    columns={this.state.analysisColumnArr}
                    dataSource={this.state.analysisData}
                    scroll={{ x: 970 }}
                    pagination={false}
                  />
                </>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
        </Spin>
      </>
    );
  }
}

export default withApollo(Report);
