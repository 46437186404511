import React from "react";
import { Auth } from "aws-amplify";
import { Row, Col, Input, Button, Form, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

class ForgotPassword extends React.Component {
  state = {
    btnLoading: false,
  };

  onFinish = async (values) => {
    this.setState({ btnLoading: true });

    Auth.forgotPassword(this.state.email)
      .then((user) => {
        SuccessNotificationMsg(
          "Verification Mail Sent Successfully!",
          "Please check your mail inbox, for verification code!"
        );

        this.setState({ btnLoading: false });
        this.props.history.push("/forgot-password-verification");
      })
      .catch((err) => {
        if (err.code !== undefined && err.code === "UserNotFoundException") {
          err.message = "Email id not registered with us.";
        }
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    return (
      <div className="authPage">
        <div className="container">
          <div className="login_wraper forgot_pass">
            <div className="login-box">
              <div className="pagename">
                <span>Forgot password</span>
              </div>
              <p className="text-center">
                Enter your e-mail address and we’ll send you a link to reset
                your password
              </p>

              <Form
                autoComplete="off"
                onFinish={this.onFinish}
                initialValues={
                  {
                    //email: props.stateValues.email,
                  }
                }
              >
                <Row>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid e-mail!",
                        },
                        {
                          required: true,
                          message: "Please input E-mail!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email address *"
                        onChange={this.handleInputChange("email")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]} className="bottomButtons">
                  <Col xs={24} align="center">
                    <Space>
                      <Button
                        type="secondary"
                        htmlType="button"
                        onClick={this.props.history.goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.btnLoading}
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
