import gql from "graphql-tag";

export const ADD_TAX_ELECTION = gql`
  mutation createTaxElectionsRecord(
    $personId: String!
    $federalTaxesWithholdingStatus: String
    $federalTaxesExemptions: String
    $federalTaxesAdditonalWithholdings: String
    $federalBlock: String
    $ssOrMedBlock: String
    $liveInState: String
    $workInState: String
    $stateTaxesWithholding: String
    $stateTaxesExemptions: String
    $stateTaxesAdditionalWithholding: String
    $taxBlock: String
    $suiOrsdiBlock: String
    $schoolDistrict: String
    $liveInCountry: String
    $workInCountry: String
    $localTaxesWithholdingStatus: String
    $localTaxesAdditionalWithholdings: String
  ) {
    createTaxElectionsRecord(
      personId: $personId
      data: {
        federalTaxesWithholdingStatus: $federalTaxesWithholdingStatus
        federalTaxesExemptions: $federalTaxesExemptions
        federalTaxesAdditonalWithholdings: $federalTaxesAdditonalWithholdings
        federalBlock: $federalBlock
        ssOrMedBlock: $ssOrMedBlock
        liveInState: $liveInState
        workInState: $workInState
        stateTaxesWithholding: $stateTaxesWithholding
        stateTaxesExemptions: $stateTaxesExemptions
        stateTaxesAdditionalWithholding: $stateTaxesAdditionalWithholding
        taxBlock: $taxBlock
        suiOrsdiBlock: $suiOrsdiBlock
        schoolDistrict: $schoolDistrict
        liveInCountry: $liveInCountry
        workInCountry: $workInCountry
        localTaxesWithholdingStatus: $localTaxesWithholdingStatus
        localTaxesAdditionalWithholdings: $localTaxesAdditionalWithholdings
      }
    )
  }
`;

export const TAX_ELECTION_LIST_QUERY = gql`
  query getEmployeeTaxElectionsRecords($personId: String!) {
    getEmployeeTaxElectionsRecords(personId: $personId) {
      taxElectionsId
      personId
      federalTaxesWithholdingStatus
      federalTaxesExemptions
      federalTaxesAdditonalWithholdings
      federalBlock
      ssOrMedBlock
      liveInState
      workInState
      stateTaxesWithholding
      stateTaxesExemptions
      stateTaxesAdditionalWithholding
      taxBlock
      suiOrsdiBlock
      schoolDistrict
      liveInCountry
      workInCountry
      localTaxesWithholdingStatus
      localTaxesAdditionalWithholdings
    }
  }
`;

export const GET_TAX_ELECTION_QUERY = gql`
  query getEmployeeTaxElectionsRecordById($recordId: String!) {
    getEmployeeTaxElectionsRecordById(recordId: $recordId) {
      personId
      federalTaxesWithholdingStatus
      federalTaxesExemptions
      federalTaxesAdditonalWithholdings
      federalBlock
      ssOrMedBlock
      liveInState
      workInState
      stateTaxesWithholding
      stateTaxesExemptions
      stateTaxesAdditionalWithholding
      taxBlock
      suiOrsdiBlock
      schoolDistrict
      liveInCountry
      workInCountry
      localTaxesWithholdingStatus
      localTaxesAdditionalWithholdings
    }
  }
`;

export const UPDATE_TAX_ELECTION = gql`
  mutation updateTaxElectionsRecord(
    $personId: String!
    $federalTaxesWithholdingStatus: String
    $federalTaxesExemptions: String
    $federalTaxesAdditonalWithholdings: String
    $federalBlock: String
    $ssOrMedBlock: String
    $liveInState: String
    $workInState: String
    $stateTaxesWithholding: String
    $stateTaxesExemptions: String
    $stateTaxesAdditionalWithholding: String
    $taxBlock: String
    $suiOrsdiBlock: String
    $schoolDistrict: String
    $liveInCountry: String
    $workInCountry: String
    $localTaxesWithholdingStatus: String
    $localTaxesAdditionalWithholdings: String
  ) {
    updateTaxElectionsRecord(
      personId: $personId
      data: {
        federalTaxesWithholdingStatus: $federalTaxesWithholdingStatus
        federalTaxesExemptions: $federalTaxesExemptions
        federalTaxesAdditonalWithholdings: $federalTaxesAdditonalWithholdings
        federalBlock: $federalBlock
        ssOrMedBlock: $ssOrMedBlock
        liveInState: $liveInState
        workInState: $workInState
        stateTaxesWithholding: $stateTaxesWithholding
        stateTaxesExemptions: $stateTaxesExemptions
        stateTaxesAdditionalWithholding: $stateTaxesAdditionalWithholding
        taxBlock: $taxBlock
        suiOrsdiBlock: $suiOrsdiBlock
        schoolDistrict: $schoolDistrict
        liveInCountry: $liveInCountry
        workInCountry: $workInCountry
        localTaxesWithholdingStatus: $localTaxesWithholdingStatus
        localTaxesAdditionalWithholdings: $localTaxesAdditionalWithholdings
      }
    )
  }
`;

export const GET_TAX_ELECTION_HISTORY = gql`
  query getEmployeeTaxElectionsRecordHistoryById($recordId: String!) {
    getEmployeeTaxElectionsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        federalTaxesWithholdingStatus
        federalTaxesExemptions
        federalTaxesAdditonalWithholdings
        federalBlock
        ssOrMedBlock
        liveInState
        workInState
        stateTaxesWithholding
        stateTaxesExemptions
        stateTaxesAdditionalWithholding
        taxBlock
        suiOrsdiBlock
        schoolDistrict
        liveInCountry
        workInCountry
        localTaxesWithholdingStatus
        localTaxesAdditionalWithholdings
        recordSource
      }
    }
  }
`;

export const DELETE_TAX_ELECTION = gql`
  mutation deleteTaxElectionsRecord($recordId: String!) {
    deleteTaxElectionsRecord(recordId: $recordId)
  }
`;
