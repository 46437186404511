import React from "react";
import { Auth } from "aws-amplify";
import { withApollo } from "react-apollo";
import { Row, Col, Input, Button, Form, Checkbox, Divider, Space } from "antd";
import Password from "antd/lib/input/Password";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
class ConsumerRegister extends React.Component {
  state = {
    btnLoading: false,
    newsletter: false,
    accept_terms_condition: true,
    city: "",
    country: "",
    webhookURL: "",
  };
  formRef = React.createRef();

  onFinish = async (values) => {
    let {
      firstName,
      lastName,
      password,
      email,
      city,
      country,
      webhookURL,
    } = this.state;

    let phoneNumber = "+1" + this.state.phoneNumber;
    this.setState({ btnLoading: true });

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${firstName} ${lastName}`,
        phone_number: phoneNumber,
        "custom:city": city,
        "custom:country": country,
        "custom:webhookURL": webhookURL,
      },
    })
      .then((response) => {
        //cognitoId: response.userSub;
        SuccessNotificationMsg(
          "Consumer registered successfully!",
          "Please check mail and verify your account!"
        );

        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        this.props.history.push("/verify-account", { email: this.state.email });
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({
          btnLoading: false,
        });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleCheckboxChange = (input) => (event) => {
    this.setState({ [input]: event.target.checked });
  };

  render() {
    return (
      <div className="authPage">
        <div className="container">
          <div class="reg_wraper">
            <div className="pagename">
              <span>Registration</span>
            </div>

            {/* <p>After registering, you will receive a confirmation mail.</p> */}

            <Form
              onFinish={this.onFinish}
              ref={this.formRef}
              autoComplete="off"
            >
              <Row gutter={[15]}>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name *"
                      onChange={this.handleInputChange("firstName")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name *"
                      onChange={this.handleInputChange("lastName")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid e-mail address!",
                      },
                      {
                        required: true,
                        message: "Please input e-mail address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address *"
                      onChange={this.handleInputChange("email")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp("^[0-9]{10}$"),
                        message: "Please input phone number!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Phone Number * (Example: 8175281117)"
                      onChange={this.handleInputChange("phoneNumber")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item name="country">
                    <Input
                      placeholder="Country"
                      onChange={this.handleInputChange("country")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item name="city">
                    <Input
                      placeholder="City"
                      onChange={this.handleInputChange("city")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input password!",
                      },
                      () => ({
                        validator(rule, value) {
                          if (
                            !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!_%*#?&])[A-Za-z\d@$!_%*#?&]{6,}$/.test(
                              value
                            )
                          ) {
                            return Promise.reject(
                              "Password should be minimum six characters, at least one letter and one number and one special character."
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Password
                      placeholder="Password *"
                      onChange={this.handleInputChange("password")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please input confirm password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Password
                      placeholder="Confirm Password *"
                      onChange={this.handleInputChange("confirm_password")}
                    />
                  </Form.Item>
                </Col>                
              </Row>

              <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 0]}>
                <Col xs={24} sm={12} lg={12} align="start">
                  <Form.Item
                    name="accept_terms_condition"
                    className="checkwrap"
                  >
                    <Checkbox
                      checked={this.state.accept_terms_condition}
                      onChange={this.handleCheckboxChange(
                        "accept_terms_condition"
                      )}
                    >
                      I accept the terms and conditions
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />

              <Row gutter={[15]} className="bottomButtons">
                <Col xs={24} align="end">
                  <Space>
                    <Button
                      type="secondary"
                      htmlType="button"
                      onClick={this.props.history.goBack}
                    >
                      Back
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.btnLoading}
                    >
                      Register
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(ConsumerRegister);
