import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  Row,
  Col,
  Input,
  Form,
  Modal,
  Button,
  Space,
  Divider,
} from "antd";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { EMAIL_REPORT } from "./ReportingToolQuery";

class SendReportEmail extends Component {
  state = {
    visible: false,
    btnLoading: false,
    reportId: this.props.reportId ? this.props.reportId : '',
    recipients: [{ email: "" }],
  };

  formRef = React.createRef();

  showModal = (e, node) => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = () => {
    let recipients = this.state.recipients.map((value) => value.email);
    this.setState({ btnLoading: true, recipients });

    this.props.client
      .mutate({
        mutation: EMAIL_REPORT,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Report mail sent successfully.");
        this.setState({ btnLoading: false });
        this.hideModal();
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  addRecipients = () => {
    this.setState(
      {
        recipients: [...this.state.recipients, { email: "" }],
      },
      () => {
        this.setFieldValue(this.state.recipients);
      }
    );
  };

  handleRecipients = (e, index) => {
    const newRecepList = [...this.state.recipients];
    newRecepList[index][e.target.name] = e.target.value;
    this.setState({ recipients: newRecepList });
  };

  removeRecipients = (index) => {
    this.setState(
      { recipients: this.state.recipients.filter((s, sidx) => index !== sidx) },
      () => {
        this.setFieldValue(this.state.recipients);
      }
    );
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["email" + objectKey] = objectValue.email;
      return null;
    });
    this.formRef.current.setFieldsValue(mcFieldObject);
  };

  render() {
    const { btnLoading, visible } = this.state;
    return (
      <>
        <Button
          icon={<MailOutlined />}
          size="small"
          type="default"
          className="exp-btn"
          onClick={() => this.showModal()}
        >
          Send E-Mail
        </Button>

        <Modal
          title={"Send Report Email : " + this.props.reportTitle}
          visible={visible}
          onCancel={this.hideModal}
          onOk={this.handleSubmit}
          header={false}
          footer={false}
          width={650}
          className="ReportScheduler"
        >
          <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            autoComplete="off"
          >
            <Row gutter={[15]}>
              <Col xs={24} sm={24} lg={24}>
                <label>Recipients *</label>

                {this.state.recipients.map((x, index) => {
                  return (
                    <Row gutter={[5]} className="addrow" key={index}>
                      <Col xs={22} sm={22} lg={22}>
                        <Form.Item
                          name={`email` + index}
                          rules={[
                            {
                              type: "email",
                              required: true,
                              whitespace: true,
                              message: "Please input email!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Email"
                            name="email"
                            onChange={(e) => this.handleRecipients(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={2} sm={2} lg={2}>
                        {index !== 0 ? (
                          <Button
                            type="secondary"
                            className="close"
                            size="small"
                            onClick={(id) => this.removeRecipients(index, x)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  type="link"
                  size="small"
                  onClick={(e) => this.addRecipients(e)}
                >
                  <PlusCircleOutlined /> Add Recipients
                </Button>
              </Col>
            </Row>

            <Divider />

            <Row gutter={[15]} className="bottomButtons">
              <Col xs={24} align="end">
                <Space className="scheduler-buttons">
                  <Button type="primary" htmlType="submit" loading={btnLoading}>
                    Send
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

export default withApollo(SendReportEmail);
