import gql from "graphql-tag";

export const GET_HISTORY_QUERY = gql`
  query getChangeHistory(
    $startDate: Date
    $endDate: Date
    $searchQuery: String
    $first: Int
    $offset: Int
    $sortBy: changeHistorySortInput
  ) {
    getChangeHistory(
      startDate: $startDate
      endDate: $endDate
      searchQuery: $searchQuery
      first: $first
      offset: $offset
      sortBy: $sortBy
    )
  }
`;
