import gql from "graphql-tag";

export const ADD_EMPLOYEE = gql`
  mutation createPersonalInformationRecord(
    $firstName: String
    $middleName: String
    $suffix: String
    $alias: String
    $salutation: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $homeEmail: String
    $homePhone: String
    $mobileNumber: String
    $ssnOrsin: String
    $birthDate: Date
    $gender: String
    $driverLicenceNumber: String
    $driverLicenceState: String
    $driverLicenceExpires: String
    $maritalStatus: String
    $lastName: String
    $countryOrRegion: String
    $licenceCountry: String
    $cellProviderType: String
    $legalSuffix: String
    $status: employeeStatus
  ) {
    createPersonalInformationRecord(
      data: {
        firstName: $firstName
        middleName: $middleName
        suffix: $suffix
        alias: $alias
        salutation: $salutation
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        homeEmail: $homeEmail
        homePhone: $homePhone
        mobileNumber: $mobileNumber
        ssnOrsin: $ssnOrsin
        birthDate: $birthDate
        gender: $gender
        driverLicenceNumber: $driverLicenceNumber
        driverLicenceState: $driverLicenceState
        driverLicenceExpires: $driverLicenceExpires
        maritalStatus: $maritalStatus
        lastName: $lastName
        countryOrRegion: $countryOrRegion
        licenceCountry: $licenceCountry
        cellProviderType: $cellProviderType
        legalSuffix: $legalSuffix
        status: $status
      }
    )
  }
`;

export const EMPLOYEE_LIST_QUERY = gql`
  query getAllEmployees(
    $first: Int
    $offset: Int
    $orderBy: sortInput
    $search: String
  ) {
    getAllEmployees(
      first: $first
      offset: $offset
      sortBy: $orderBy
      searchQuery: $search
    ) {
      count
      rows {
        personId
        firstName
        middleName
        lastName
        suffix
        alias
        salutation
        street1
        street2
        city
        stateOrProvince
        zipOrPostalCode
        homeEmail
        homePhone
        mobileNumber
        ssnOrsin
        birthDate
        gender
        driverLicenceNumber
        driverLicenceState
        driverLicenceExpires
        maritalStatus
        countryOrRegion
        licenceCountry
        cellProviderType
        legalSuffix
        status
      }
    }
  }
`;

export const GET_EMPLOYEE_QUERY = gql`
  query getEmployeePersonalInformationRecord($personId: String!) {
    getEmployeePersonalInformationRecord(personId: $personId) {
      personId
      firstName
      middleName
      lastName
      suffix
      alias
      salutation
      street1
      street2
      city
      stateOrProvince
      zipOrPostalCode
      homeEmail
      homePhone
      mobileNumber
      ssnOrsin
      birthDate
      gender
      driverLicenceNumber
      driverLicenceState
      driverLicenceExpires
      maritalStatus
      countryOrRegion
      licenceCountry
      cellProviderType
      legalSuffix
      status
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updatePersonalInformationRecord(
    $personId: String!
    $firstName: String
    $middleName: String
    $suffix: String
    $alias: String
    $salutation: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $homeEmail: String
    $homePhone: String
    $mobileNumber: String
    $ssnOrsin: String
    $birthDate: Date
    $gender: String
    $driverLicenceNumber: String
    $driverLicenceState: String
    $driverLicenceExpires: String
    $maritalStatus: String
    $lastName: String
    $countryOrRegion: String
    $licenceCountry: String
    $cellProviderType: String
    $legalSuffix: String
    $status: employeeStatus
  ) {
    updatePersonalInformationRecord(
      personId: $personId
      data: {
        firstName: $firstName
        middleName: $middleName
        suffix: $suffix
        alias: $alias
        salutation: $salutation
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        homeEmail: $homeEmail
        homePhone: $homePhone
        mobileNumber: $mobileNumber
        ssnOrsin: $ssnOrsin
        birthDate: $birthDate
        gender: $gender
        driverLicenceNumber: $driverLicenceNumber
        driverLicenceState: $driverLicenceState
        driverLicenceExpires: $driverLicenceExpires
        maritalStatus: $maritalStatus
        lastName: $lastName
        countryOrRegion: $countryOrRegion
        licenceCountry: $licenceCountry
        cellProviderType: $cellProviderType
        legalSuffix: $legalSuffix
        status: $status
      }
    )
  }
`;

export const IMPORT_EMPLOYEE = gql`
  mutation importData($file: Upload!) {
    importData(file: $file, recordType: PersonalInformation)
  }
`;

export const GET_EMPLOYEE_HISTORY = gql`
  query getEmployeePersonalInformationRecordHistory($personId: String!) {
    getEmployeePersonalInformationRecordHistory(personId: $personId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        firstName
        middleName
        lastName
        suffix
        alias
        salutation
        street1
        street2
        city
        stateOrProvince
        zipOrPostalCode
        homeEmail
        homePhone
        mobileNumber
        ssnOrsin
        birthDate
        gender
        driverLicenceNumber
        driverLicenceState
        driverLicenceExpires
        maritalStatus
        countryOrRegion
        licenceCountry
        cellProviderType
        legalSuffix
        status
        recordSource
      }
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation deletePersonalInformationRecord($personId: String!) {
    deletePersonalInformationRecord(personId: $personId)
  }
`;
