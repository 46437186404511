import gql from "graphql-tag";

export const GET_REPORT_SCHEDULER_QUERY = gql`
  query getReportExportScheduleById($reportId: String!) {
    getReportExportScheduleById(reportId: $reportId) {
      scheduleId
      reportId
      frequency
      startDate
      recipients
    }
  }
`;

export const ADD_EDIT_SCHEDULER_QUERY = gql`
  mutation createReportExportSchedule(
    $reportId: String!
    $frequency: reportEmailFrequency!
    $startDate: Date!
    $recipients: [String]!
  ) {
    createReportExportSchedule(
      reportId: $reportId
      frequency: $frequency
      startDate: $startDate
      recipients: $recipients
    )
  }
`;

export const DELETE_SCHEDULER_QUERY = gql`
  mutation deleteReportExportSchedule($reportId: String!) {
    deleteReportExportSchedule(reportId: $reportId)
  }
`;
