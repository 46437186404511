import gql from "graphql-tag";

export const ADD_DISCIPLINE_RECORD = gql`
  mutation createDisciplineRecord(
    $personId: String!
    $infractionType: String
    $disciplineCommunicationMethod: String
    $discussionDate: String
    $actionTaken: String
  ) {
    createDisciplineRecord(
      personId: $personId
      data: {
        infractionType: $infractionType
        disciplineCommunicationMethod: $disciplineCommunicationMethod
        discussionDate: $discussionDate
        actionTaken: $actionTaken
      }
    )
  }
`;

export const DISCIPLINE_RECORD_LIST_QUERY = gql`
  query getEmployeeDisciplineRecords($personId: String!) {
    getEmployeeDisciplineRecords(personId: $personId) {
      disciplineRecordId
      personId
      infractionType
      disciplineCommunicationMethod
      discussionDate
      actionTaken
    }
  }
`;

export const GET_DISCIPLINE_RECORD_QUERY = gql`
  query getEmployeeDisciplineRecordById($recordId: String!) {
    getEmployeeDisciplineRecordById(recordId: $recordId) {
      personId
      infractionType
      disciplineCommunicationMethod
      discussionDate
      actionTaken
    }
  }
`;

export const UPDATE_DISCIPLINE_RECORD = gql`
  mutation updateDisciplineRecord(
    $personId: String!
    $infractionType: String
    $disciplineCommunicationMethod: String
    $discussionDate: String!
    $actionTaken: String
  ) {
    updateDisciplineRecord(
      personId: $personId
      data: {
        infractionType: $infractionType
        disciplineCommunicationMethod: $disciplineCommunicationMethod
        discussionDate: $discussionDate
        actionTaken: $actionTaken
      }
    )
  }
`;

export const GET_DISCIPLINE_RECORD_HISTORY = gql`
  query getEmployeeDisciplineRecordHistoryById($recordId: String!) {
    getEmployeeDisciplineRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        disciplineRecordId
        personId
        infractionType
        disciplineCommunicationMethod
        discussionDate
        actionTaken
        recordSource
      }
    }
  }
`;

export const DELETE_DISCIPLINE_RECORD = gql`
  mutation deleteDisciplineRecord($recordId: String!) {
    deleteDisciplineRecord(recordId: $recordId)
  }
`;
