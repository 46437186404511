import gql from "graphql-tag";

export const ADD_TIMESHEET = gql`
  mutation createTimesheetRecord(
    $personId: String!
    $badgeID: String
    $otherID: String
    $startTime: String
    $endTime: String
    $lunchInMinutes: String
    $punchType: String
    $homeDivision: String
    $homeDivisionDescription: String
    $homeDepartment: String
    $homeDepartmentDescription: String
    $workDepartment: String
    $workDepartmentDescription: String
    $workJob: String
    $workJobDescription: String
    $workLevel4: String
    $workLevel4Description: String
    $workLevel5: String
    $workLevel5Description: String
    $startRound: String
    $endRound: String
    $regHours: String
    $otHours: String
    $dtHours: String
    $totalTime: String
    $dollars: String
    $schedule: String
    $shift: String
    $shiftDescription: String
    $shiftGroup: String
    $payRate: String
    $totalPayDollars: String
    $billRate: String
    $totalBillDollars: String
    $totalDollars: String
    $payType: String
    $payTypeDescription: String
    $payPeriodCode: String
    $payPeriodDescription: String
    $lastModified: String
    $accrueOT: String
    $regTCompensation: String
    $otCompensation: String
    $dtCompensation: String
    $startLocationNo: String
    $startLocationType: String
    $startLocationName: String
    $endLocationNo: String
    $endLocationType: String
    $endLocationName: String
  ) {
    createTimesheetRecord(
      personId: $personId
      data: {
        badgeID: $badgeID
        otherID: $otherID
        startTime: $startTime
        endTime: $endTime
        lunchInMinutes: $lunchInMinutes
        punchType: $punchType
        homeDivision: $homeDivision
        homeDivisionDescription: $homeDivisionDescription
        homeDepartment: $homeDepartment
        homeDepartmentDescription: $homeDepartmentDescription
        workDepartment: $workDepartment
        workDepartmentDescription: $workDepartmentDescription
        workJob: $workJob
        workJobDescription: $workJobDescription
        workLevel4: $workLevel4
        workLevel4Description: $workLevel4Description
        workLevel5: $workLevel5
        workLevel5Description: $workLevel5Description
        startRound: $startRound
        endRound: $endRound
        regHours: $regHours
        otHours: $otHours
        dtHours: $dtHours
        totalTime: $totalTime
        dollars: $dollars
        schedule: $schedule
        shift: $shift
        shiftDescription: $shiftDescription
        shiftGroup: $shiftGroup
        payRate: $payRate
        totalPayDollars: $totalPayDollars
        billRate: $billRate
        totalBillDollars: $totalBillDollars
        totalDollars: $totalDollars
        payType: $payType
        payTypeDescription: $payTypeDescription
        payPeriodCode: $payPeriodCode
        payPeriodDescription: $payPeriodDescription
        lastModified: $lastModified
        accrueOT: $accrueOT
        regTCompensation: $regTCompensation
        otCompensation: $otCompensation
        dtCompensation: $dtCompensation
        startLocationNo: $startLocationNo
        startLocationType: $startLocationType
        startLocationName: $startLocationName
        endLocationNo: $endLocationNo
        endLocationType: $endLocationType
        endLocationName: $endLocationName
      }
    )
  }
`;

export const TIMESHEET_LIST_QUERY = gql`
  query getEmployeeTimesheetRecords($personId: String!) {
    getEmployeeTimesheetRecords(personId: $personId) {
      timesheetRecordId
      personId
      badgeID
      otherID
      startTime
      endTime
      lunchInMinutes
      punchType
      homeDivision
      homeDivisionDescription
      homeDepartment
      homeDepartmentDescription
      workDepartment
      workDepartmentDescription
      workJob
      workJobDescription
      workLevel4
      workLevel4Description
      workLevel5
      workLevel5Description
      startRound
      endRound
      regHours
      otHours
      dtHours
      totalTime
      dollars
      schedule
      shift
      shiftDescription
      shiftGroup
      payRate
      totalPayDollars
      billRate
      totalBillDollars
      totalDollars
      payType
      payTypeDescription
      payPeriodCode
      payPeriodDescription
      lastModified
      accrueOT
      regTCompensation
      otCompensation
      dtCompensation
      startLocationNo
      startLocationType
      startLocationName
      endLocationNo
      endLocationType
      endLocationName
    }
  }
`;

export const GET_TIMESHEET_QUERY = gql`
  query getEmployeeTimesheetRecordById($recordId: String!) {
    getEmployeeTimesheetRecordById(recordId: $recordId) {
      personId
      badgeID
      otherID
      startTime
      endTime
      lunchInMinutes
      punchType
      homeDivision
      homeDivisionDescription
      homeDepartment
      homeDepartmentDescription
      workDepartment
      workDepartmentDescription
      workJob
      workJobDescription
      workLevel4
      workLevel4Description
      workLevel5
      workLevel5Description
      startRound
      endRound
      regHours
      otHours
      dtHours
      totalTime
      dollars
      schedule
      shift
      shiftDescription
      shiftGroup
      payRate
      totalPayDollars
      billRate
      totalBillDollars
      totalDollars
      payType
      payTypeDescription
      payPeriodCode
      payPeriodDescription
      lastModified
      accrueOT
      regTCompensation
      otCompensation
      dtCompensation
      startLocationNo
      startLocationType
      startLocationName
      endLocationNo
      endLocationType
      endLocationName
    }
  }
`;

export const UPDATE_TIMESHEET = gql`
  mutation updateTimesheetRecord(
    $personId: String!
    $badgeID: String
    $otherID: String
    $startTime: String!
    $endTime: String!
    $lunchInMinutes: String
    $punchType: String
    $homeDivision: String
    $homeDivisionDescription: String
    $homeDepartment: String
    $homeDepartmentDescription: String
    $workDepartment: String
    $workDepartmentDescription: String
    $workJob: String
    $workJobDescription: String
    $workLevel4: String
    $workLevel4Description: String
    $workLevel5: String
    $workLevel5Description: String
    $startRound: String
    $endRound: String
    $regHours: String
    $otHours: String
    $dtHours: String
    $totalTime: String
    $dollars: String
    $schedule: String
    $shift: String
    $shiftDescription: String
    $shiftGroup: String
    $payRate: String
    $totalPayDollars: String
    $billRate: String
    $totalBillDollars: String
    $totalDollars: String
    $payType: String
    $payTypeDescription: String
    $payPeriodCode: String
    $payPeriodDescription: String
    $lastModified: String
    $accrueOT: String
    $regTCompensation: String
    $otCompensation: String
    $dtCompensation: String
    $startLocationNo: String
    $startLocationType: String
    $startLocationName: String
    $endLocationNo: String
    $endLocationType: String
    $endLocationName: String
  ) {
    updateTimesheetRecord(
      personId: $personId
      data: {
        badgeID: $badgeID
        otherID: $otherID
        startTime: $startTime
        endTime: $endTime
        lunchInMinutes: $lunchInMinutes
        punchType: $punchType
        homeDivision: $homeDivision
        homeDivisionDescription: $homeDivisionDescription
        homeDepartment: $homeDepartment
        homeDepartmentDescription: $homeDepartmentDescription
        workDepartment: $workDepartment
        workDepartmentDescription: $workDepartmentDescription
        workJob: $workJob
        workJobDescription: $workJobDescription
        workLevel4: $workLevel4
        workLevel4Description: $workLevel4Description
        workLevel5: $workLevel5
        workLevel5Description: $workLevel5Description
        startRound: $startRound
        endRound: $endRound
        regHours: $regHours
        otHours: $otHours
        dtHours: $dtHours
        totalTime: $totalTime
        dollars: $dollars
        schedule: $schedule
        shift: $shift
        shiftDescription: $shiftDescription
        shiftGroup: $shiftGroup
        payRate: $payRate
        totalPayDollars: $totalPayDollars
        billRate: $billRate
        totalBillDollars: $totalBillDollars
        totalDollars: $totalDollars
        payType: $payType
        payTypeDescription: $payTypeDescription
        payPeriodCode: $payPeriodCode
        payPeriodDescription: $payPeriodDescription
        lastModified: $lastModified
        accrueOT: $accrueOT
        regTCompensation: $regTCompensation
        otCompensation: $otCompensation
        dtCompensation: $dtCompensation
        startLocationNo: $startLocationNo
        startLocationType: $startLocationType
        startLocationName: $startLocationName
        endLocationNo: $endLocationNo
        endLocationType: $endLocationType
        endLocationName: $endLocationName
      }
    )
  }
`;

export const GET_TIMESHEET_HISTORY = gql`
  query getEmployeeTimesheetRecordHistoryById($recordId: String!) {
    getEmployeeTimesheetRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        badgeID
        otherID
        startTime
        endTime
        lunchInMinutes
        punchType
        homeDivision
        homeDivisionDescription
        homeDepartment
        homeDepartmentDescription
        workDepartment
        workDepartmentDescription
        workJob
        workJobDescription
        workLevel4
        workLevel4Description
        workLevel5
        workLevel5Description
        startRound
        endRound
        regHours
        otHours
        dtHours
        totalTime
        dollars
        schedule
        shift
        shiftDescription
        shiftGroup
        payRate
        totalPayDollars
        billRate
        totalBillDollars
        totalDollars
        payType
        payTypeDescription
        payPeriodCode
        payPeriodDescription
        lastModified
        accrueOT
        regTCompensation
        otCompensation
        dtCompensation
        startLocationNo
        startLocationType
        startLocationName
        endLocationNo
        endLocationType
        endLocationName
        recordSource
      }
    }
  }
`;

export const DELETE_TIMESHEET = gql`
  mutation deleteTimesheetRecord($recordId: String!) {
    deleteTimesheetRecord(recordId: $recordId)
  }
`;
