import gql from "graphql-tag";

export const ADD_DEPENDENTS = gql`
  mutation createDependentsRecord(
    $personId: String!
    $firstName: String
    $middleName: String
    $suffix: String
    $alias: String
    $salutation: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $homeEmail: String
    $homePhone: String
    $mobileNumber: String
    $ssnOrsin: String
    $birthDate: Date
    $gender: String
    $relationship: String
    $imputedIncome: String
    $smoker: String
    $disabled: String
    $student: String
    $countryOrRegion: String
    $age: String
  ) {
    createDependentsRecord(
      personId: $personId
      data: {
        firstName: $firstName
        middleName: $middleName
        suffix: $suffix
        alias: $alias
        salutation: $salutation
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        homeEmail: $homeEmail
        homePhone: $homePhone
        mobileNumber: $mobileNumber
        ssnOrsin: $ssnOrsin
        birthDate: $birthDate
        gender: $gender
        relationship: $relationship
        imputedIncome: $imputedIncome
        smoker: $smoker
        disabled: $disabled
        student: $student
        countryOrRegion: $countryOrRegion
        age: $age
      }
    )
  }
`;

export const DEPENDENTS_LIST_QUERY = gql`
  query getEmployeeDependentsRecords($personId: String!) {
    getEmployeeDependentsRecords(personId: $personId) {
      dependentsId
      personId
      firstName
      middleName
      suffix
      alias
      salutation
      street1
      street2
      city
      stateOrProvince
      zipOrPostalCode
      homeEmail
      homePhone
      mobileNumber
      ssnOrsin
      birthDate
      gender
      relationship
      imputedIncome
      smoker
      disabled
      student
      countryOrRegion
      age
    }
  }
`;

export const GET_DEPENDENTS_QUERY = gql`
  query getEmployeeDependentsRecordById($recordId: String!) {
    getEmployeeDependentsRecordById(recordId: $recordId) {
      personId
      firstName
      middleName
      suffix
      alias
      salutation
      street1
      street2
      city
      stateOrProvince
      zipOrPostalCode
      homeEmail
      homePhone
      mobileNumber
      ssnOrsin
      birthDate
      gender
      relationship
      imputedIncome
      smoker
      disabled
      student
      countryOrRegion
      age
    }
  }
`;

export const UPDATE_DEPENDENTS = gql`
  mutation updateDependentsRecord(
    $personId: String!
    $firstName: String!
    $middleName: String
    $suffix: String
    $alias: String
    $salutation: String
    $street1: String
    $street2: String
    $city: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $homeEmail: String
    $homePhone: String
    $mobileNumber: String
    $ssnOrsin: String
    $birthDate: Date!
    $gender: String
    $relationship: String
    $imputedIncome: String
    $smoker: String
    $disabled: String
    $student: String
    $countryOrRegion: String
    $age: String
  ) {
    updateDependentsRecord(
      personId: $personId
      data: {
        firstName: $firstName
        middleName: $middleName
        suffix: $suffix
        alias: $alias
        salutation: $salutation
        street1: $street1
        street2: $street2
        city: $city
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        homeEmail: $homeEmail
        homePhone: $homePhone
        mobileNumber: $mobileNumber
        ssnOrsin: $ssnOrsin
        birthDate: $birthDate
        gender: $gender
        relationship: $relationship
        imputedIncome: $imputedIncome
        smoker: $smoker
        disabled: $disabled
        student: $student
        countryOrRegion: $countryOrRegion
        age: $age
      }
    )
  }
`;

export const GET_DEPENDENTS_HISTORY = gql`
  query getEmployeeDependentsRecordHistoryById($recordId: String!) {
    getEmployeeDependentsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        firstName
        middleName
        suffix
        alias
        salutation
        street1
        street2
        city
        stateOrProvince
        zipOrPostalCode
        homeEmail
        homePhone
        mobileNumber
        ssnOrsin
        birthDate
        gender
        relationship
        imputedIncome
        smoker
        disabled
        student
        countryOrRegion
        age
        recordSource
      }
    }
  }
`;

export const DELETE_DEPENDENTS = gql`
  mutation deleteDependentsRecord($recordId: String!) {
    deleteDependentsRecord(recordId: $recordId)
  }
`;
