import gql from "graphql-tag";

export const IMPORT_DATA = gql`
  mutation importData($file: Upload!, $recordType: recordsList!) {
    importData(file: $file, recordType: $recordType)
  }
`;

export const IMPORT_RECORDS = gql`
  mutation importRecords($records: Json!, $recordType: recordsList!) {
    importRecords(records: $records, recordType: $recordType)
  }
`;
