import gql from "graphql-tag";

export const ADD_SALARY_GRADE = gql`
  mutation createSalaryGradeRecord(
    $personId: String!
    $name: String
    $effectiveDate: String
    $minimum: String
    $midPoint: String
    $maximum: String
    $market: String
  ) {
    createSalaryGradeRecord(
      personId: $personId
      data: {
        name: $name
        effectiveDate: $effectiveDate
        minimum: $minimum
        midPoint: $midPoint
        maximum: $maximum
        market: $market
      }
    )
  }
`;

export const SALARY_GRADE_LIST_QUERY = gql`
  query getEmployeeSalaryGradeRecords($personId: String!) {
    getEmployeeSalaryGradeRecords(personId: $personId) {
      salaryGradeId
      personId
      name
      effectiveDate
      minimum
      midPoint
      maximum
      market
    }
  }
`;

export const GET_SALARY_GRADE_QUERY = gql`
  query getEmployeeSalaryGradeRecordById($recordId: String!) {
    getEmployeeSalaryGradeRecordById(recordId: $recordId) {
      personId
      name
      effectiveDate
      minimum
      midPoint
      maximum
      market
    }
  }
`;

export const UPDATE_SALARY_GRADE = gql`
  mutation updateSalaryGradeRecord(
    $recordId: String!
    $name: String
    $effectiveDate: String
    $minimum: String
    $midPoint: String
    $maximum: String
    $market: String
  ) {
    updateSalaryGradeRecord(
      recordId: $recordId
      data: {
        name: $name
        effectiveDate: $effectiveDate
        minimum: $minimum
        midPoint: $midPoint
        maximum: $maximum
        market: $market
      }
    )
  }
`;

export const GET_SALARY_GRADE_HISTORY = gql`
  query getEmployeeSalaryGradeRecordHistoryById($recordId: String!) {
    getEmployeeSalaryGradeRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        salaryGradeId
        personId
        name
        effectiveDate
        minimum
        midPoint
        maximum
        market

        recordSource
      }
    }
  }
`;

export const DELETE_SALARY_GRADE = gql`
  mutation deleteSalaryGradeRecord($recordId: String!) {
    deleteSalaryGradeRecord(recordId: $recordId)
  }
`;
