import gql from "graphql-tag";

export const ADD_CERTIFICATION_LICENCE = gql`
  mutation createCertificationAndLicenceRecord(
    $personId: String!
    $issueDate: Date
    $renewalDate: Date
    $type: String
    $name: String
    $expirationDate: String
    $school: String
    $certification: String
    $licenceNumber: String
    $licenceCountry: String
    $licenceState: String
    $certificationNumber: String
    $areaNumber: String
  ) {
    createCertificationAndLicenceRecord(
      personId: $personId
      data: {
        issueDate: $issueDate
        renewalDate: $renewalDate
        type: $type
        name: $name
        expirationDate: $expirationDate
        school: $school
        certification: $certification
        licenceNumber: $licenceNumber
        licenceCountry: $licenceCountry
        licenceState: $licenceState
        certificationNumber: $certificationNumber
        areaNumber: $areaNumber
      }
    )
  }
`;

export const CERTIFICATION_LICENCE_LIST_QUERY = gql`
  query getEmployeeCertificationAndLicenceRecords($personId: String!) {
    getEmployeeCertificationAndLicenceRecords(personId: $personId) {
      certificationAndLicenceRecordId
      personId
      issueDate
      renewalDate
      type
      name
      expirationDate
      school
      certification
      licenceNumber
      licenceCountry
      licenceState
      certificationNumber
      areaNumber
    }
  }
`;

export const GET_CERTIFICATION_LICENCE_QUERY = gql`
  query getEmployeeCertificationAndLicenceRecordById($recordId: String!) {
    getEmployeeCertificationAndLicenceRecordById(recordId: $recordId) {
      personId
      issueDate
      renewalDate
      type
      name
      expirationDate
      school
      certification
      licenceNumber
      licenceCountry
      licenceState
      certificationNumber
      areaNumber
    }
  }
`;

export const UPDATE_CERTIFICATION_LICENCE = gql`
  mutation updateCertificationAndLicenceRecord(
    $personId: String!
    $issueDate: Date!
    $renewalDate: Date
    $type: String
    $name: String!
    $expirationDate: String
    $school: String
    $certification: String
    $licenceNumber: String
    $licenceCountry: String
    $licenceState: String
    $certificationNumber: String
    $areaNumber: String
  ) {
    updateCertificationAndLicenceRecord(
      personId: $personId
      data: {
        issueDate: $issueDate
        renewalDate: $renewalDate
        type: $type
        name: $name
        expirationDate: $expirationDate
        school: $school
        certification: $certification
        licenceNumber: $licenceNumber
        licenceCountry: $licenceCountry
        licenceState: $licenceState
        certificationNumber: $certificationNumber
        areaNumber: $areaNumber
      }
    )
  }
`;

export const GET_CERTIFICATION_LICENCE_HISTORY = gql`
  query getEmployeeCertificationAndLicenceRecordHistoryById(
    $recordId: String!
  ) {
    getEmployeeCertificationAndLicenceRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        issueDate
        renewalDate
        type
        name
        expirationDate
        school
        certification
        licenceNumber
        licenceCountry
        licenceState
        certificationNumber
        areaNumber
        recordSource
      }
    }
  }
`;

export const DELETE_CERTIFICATION_LICENCE = gql`
  mutation deleteCertificationAndLicenceRecord($recordId: String!) {
    deleteCertificationAndLicenceRecord(recordId: $recordId)
  }
`;
