import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import config from "../../Config";
import OpenRoute from "./OpenRoute";
import PrivateRoute from "./PrivateRoute";

/* open Route Components */
//import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Login from "../authentication";
import ConsumerRegister from "../authentication/ConsumerRegister";
import VerifyAccount from "../authentication/VerifyAccount";
import ForgotPassword from "../authentication/ForgotPassword";
import ForgotPasswordVerification from "../authentication/ForgotPasswordVerification";

/* Auth Required Private Route Components */
import Dashboard from "../dashboard/Dashboard";
import MyAccount from "../profile/MyAccount";
import csvImport from "../csvImport";
import MapImport from "../csvImport/MapImport";
import changeHistory from "../changeHistory/ChronologicalHistory";

import ManageEmployee from "../employee/ManageEmployee";
import AddEmployee from "../employee/AddEmployee";
import EmployeeDetail from "../employee/EmployeeDetail";
import EmployeeHistory from "../employee/EmployeeHistory";

import ManageEmergencyContact from "../emergencyContact/ManageEmergencyContact";
import AddEmergencyContact from "../emergencyContact/AddEmergencyContact";
import EmergencyContactDetail from "../emergencyContact/EmergencyContactDetail";
import EmergencyContactHistory from "../emergencyContact/EmergencyContactHistory";

import ManageTaxElection from "../taxElection/ManageTaxElection";
import AddTaxElection from "../taxElection/AddTaxElection";
import TaxElectionDetail from "../taxElection/TaxElectionDetail";
import TaxElectionHistory from "../taxElection/TaxElectionHistory";

import ManageCompensation from "../compensation/ManageCompensation";
import AddCompensation from "../compensation/AddCompensation";
import CompensationDetail from "../compensation/CompensationDetail";
import CompensationHistory from "../compensation/CompensationHistory";

import ManagePayChange from "../payChange/ManagePayChange";
import AddPayChange from "../payChange/AddPayChange";
import PayChangeDetail from "../payChange/PayChangeDetail";
import PayChangeHistory from "../payChange/PayChangeHistory";

import ManageDependents from "../dependents/ManageDependents";
import AddDependents from "../dependents/AddDependents";
import DependentsDetail from "../dependents/DependentsDetail";
import DependentsHistory from "../dependents/DependentsHistory";

import ManageEmployment from "../employment/ManageEmployment";
import AddEmployment from "../employment/AddEmployment";
import EmploymentDetail from "../employment/EmploymentDetail";
import EmploymentHistory from "../employment/EmploymentHistory";

import ManageWorkAuthorization from "../workAuthorization/ManageWorkAuthorization";
import AddWorkAuthorization from "../workAuthorization/AddWorkAuthorization";
import WorkAuthorizationDetail from "../workAuthorization/WorkAuthorizationDetail";
import WorkAuthorizationHistory from "../workAuthorization/WorkAuthorizationHistory";

import ManageJobDuties from "../jobDuties/ManageJobDuties";
import AddJobDuties from "../jobDuties/AddJobDuties";
import JobDutiesDetail from "../jobDuties/JobDutiesDetail";
import JobDutiesHistory from "../jobDuties/JobDutiesHistory";

import ManageEducation from "../education/ManageEducation";
import AddEducation from "../education/AddEducation";
import EducationDetail from "../education/EducationDetail";
import EducationHistory from "../education/EducationHistory";

import ManageApplicant from "../applicant/ManageApplicant";
import AddApplicant from "../applicant/AddApplicant";
import ApplicantDetail from "../applicant/ApplicantDetail";
import ApplicantHistory from "../applicant/ApplicantHistory";

import ManageLeaveAbsence from "../leaveAbsence/ManageLeaveAbsence";
import AddLeaveAbsence from "../leaveAbsence/AddLeaveAbsence";
import LeaveAbsenceDetail from "../leaveAbsence/LeaveAbsenceDetail";
import LeaveAbsenceHistory from "../leaveAbsence/LeaveAbsenceHistory";

import ManageDirectDepositAccounts from "../directDepositAccounts/ManageDirectDepositAccounts";
import AddDirectDepositAccounts from "../directDepositAccounts/AddDirectDepositAccounts";
import DirectDepositAccountsDetail from "../directDepositAccounts/DirectDepositAccountsDetail";
import DirectDepositAccountsHistory from "../directDepositAccounts/DirectDepositAccountsHistory";

import ManageSalaryGrade from "../salaryGrade/ManageSalaryGrade";
import AddSalaryGrade from "../salaryGrade/AddSalaryGrade";
import SalaryGradeDetail from "../salaryGrade/SalaryGradeDetail";
import SalaryGradeHistory from "../salaryGrade/SalaryGradeHistory";

import ManageBenefitsRecord from "../benefitsRecord/ManageBenefitsRecord";
import AddBenefitsRecord from "../benefitsRecord/AddBenefitsRecord";
import BenefitsRecordDetail from "../benefitsRecord/BenefitsRecordDetail";
import BenefitsRecordHistory from "../benefitsRecord/BenefitsRecordHistory";

import ManageTrainingRecord from "../trainingRecord/ManageTrainingRecord";
import AddTrainingRecord from "../trainingRecord/AddTrainingRecord";
import TrainingRecordDetail from "../trainingRecord/TrainingRecordDetail";
import TrainingRecordHistory from "../trainingRecord/TrainingRecordHistory";

import ManageJobQualifications from "../jobQualifications/ManageJobQualifications";
import AddJobQualifications from "../jobQualifications/AddJobQualifications";
import JobQualificationsDetail from "../jobQualifications/JobQualificationsDetail";
import JobQualificationsHistory from "../jobQualifications/JobQualificationsHistory";

import ManageCertificationLicence from "../certificationLicence/ManageCertificationLicence";
import AddCertificationLicence from "../certificationLicence/AddCertificationLicence";
import CertificationLicenceDetail from "../certificationLicence/CertificationLicenceDetail";
import CertificationLicenceHistory from "../certificationLicence/CertificationLicenceHistory";

import ManageTimesheet from "../timesheet/ManageTimesheet";
import AddTimesheet from "../timesheet/AddTimesheet";
import TimesheetDetail from "../timesheet/TimesheetDetail";
import TimesheetHistory from "../timesheet/TimesheetHistory";

import ManageSafety from "../safety/ManageSafety";
import AddSafety from "../safety/AddSafety";
import SafetyDetail from "../safety/SafetyDetail";
import SafetyHistory from "../safety/SafetyHistory";

import ManageDisciplineRecord from "../disciplineRecord/ManageDisciplineRecord";
import AddDisciplineRecord from "../disciplineRecord/AddDisciplineRecord";
import DisciplineRecordDetail from "../disciplineRecord/DisciplineRecordDetail";
import DisciplineRecordHistory from "../disciplineRecord/DisciplineRecordHistory";

import ManageAttendanePoints from "../attendanePoints/ManageAttendanePoints";
import AddAttendanePoints from "../attendanePoints/AddAttendanePoints";
import AttendanePointsDetail from "../attendanePoints/AttendanePointsDetail";
import AttendanePointsHistory from "../attendanePoints/AttendanePointsHistory";

import ManageCobra from "../cobra/ManageCobra";
import AddCobra from "../cobra/AddCobra";
import CobraDetail from "../cobra/CobraDetail";
import CobraHistory from "../cobra/CobraHistory";

import ManagePaidTimeOff from "../paidTimeOff/ManagePaidTimeOff";
import AddPaidTimeOff from "../paidTimeOff/AddPaidTimeOff";
import PaidTimeOffDetail from "../paidTimeOff/PaidTimeOffDetail";
import PaidTimeOffHistory from "../paidTimeOff/PaidTimeOffHistory";

import CreateReport from "../reportingTool";
import ReportList from "../reportingTool/ReportList";
/* Other Common Route Components */
import Logout from "../authentication/Logout";
import Maintenance from "../common/Maintenance";
import Notfound from "../common/Notfound";

export default class Routes extends Component {
  render() {
    if (config.IS_MAINTENANCE_MODE) {
      return <Route path="*" component={Maintenance} />;
    }

    return (
      <Switch>
        <OpenRoute exact path="/" component={Login} />
        <OpenRoute exact path="/about-us" component={AboutUs} />
        <OpenRoute exact path="/login" component={Login} />
        <OpenRoute exact path="/register" component={ConsumerRegister} />
        <OpenRoute exact path="/verify-account" component={VerifyAccount} />
        <OpenRoute exact path="/forgot-password" component={ForgotPassword} />
        <OpenRoute
          exact
          path="/forgot-password-verification"
          component={ForgotPasswordVerification}
        />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/myaccount" component={MyAccount} />
        <PrivateRoute exact path="/import-csv-old" component={csvImport} />
        <PrivateRoute exact path="/import-csv" component={MapImport} />
        <PrivateRoute exact path="/change-history" component={changeHistory} />
        
        <PrivateRoute exact path="/manage-employee" component={ManageEmployee} />
        {/* <PrivateRoute exact path="/add-employee" component={AddEmployee} /> */}
        <PrivateRoute path="/edit-employee/:employeeId" component={AddEmployee} />        
        <PrivateRoute exact path="/employee-detail/:employeeId" component={EmployeeDetail} />
        <PrivateRoute exact path="/employee-history/:personId" component={EmployeeHistory} />

        <PrivateRoute exact path="/manage-emergency-contact" component={ManageEmergencyContact} />
        <PrivateRoute exact path="/add-emergency-contact" component={AddEmergencyContact} />
        <PrivateRoute path="/edit-emergency-contact/:recordId" component={AddEmergencyContact} />        
        <PrivateRoute exact path="/emergency-contact-detail/:recordId" component={EmergencyContactDetail} />
        <PrivateRoute exact path="/emergency-contact-history/:recordId" component={EmergencyContactHistory} />        

        <PrivateRoute exact path="/manage-tax-election" component={ManageTaxElection} />
        <PrivateRoute exact path="/add-tax-election" component={AddTaxElection} />
        <PrivateRoute path="/edit-tax-election/:recordId" component={AddTaxElection} />
        <PrivateRoute exact path="/tax-election-detail/:recordId" component={TaxElectionDetail} />
        <PrivateRoute exact path="/tax-election-history/:recordId" component={TaxElectionHistory} />

        <PrivateRoute exact path="/manage-compensation" component={ManageCompensation} />
        <PrivateRoute exact path="/add-compensation" component={AddCompensation} />
        <PrivateRoute path="/edit-compensation/:recordId" component={AddCompensation} />
        <PrivateRoute exact path="/compensation-detail/:recordId" component={CompensationDetail} />
        <PrivateRoute exact path="/compensation-history/:recordId" component={CompensationHistory} />

        <PrivateRoute exact path="/manage-pay-change" component={ManagePayChange} />
        <PrivateRoute exact path="/add-pay-change" component={AddPayChange} />
        <PrivateRoute path="/edit-pay-change/:recordId" component={AddPayChange} />
        <PrivateRoute exact path="/pay-change-detail/:recordId" component={PayChangeDetail} />
        <PrivateRoute exact path="/pay-change-history/:recordId" component={PayChangeHistory} />

        <PrivateRoute exact path="/manage-dependents" component={ManageDependents} />
        <PrivateRoute exact path="/add-dependents" component={AddDependents} />
        <PrivateRoute path="/edit-dependents/:recordId" component={AddDependents} />
        <PrivateRoute exact path="/dependents-detail/:recordId" component={DependentsDetail} />
        <PrivateRoute exact path="/dependents-history/:recordId" component={DependentsHistory} />

        <PrivateRoute exact path="/manage-employment" component={ManageEmployment} />
        <PrivateRoute exact path="/add-employment" component={AddEmployment} />
        <PrivateRoute path="/edit-employment/:recordId" component={AddEmployment} />
        <PrivateRoute exact path="/employment-detail/:recordId" component={EmploymentDetail} />
        <PrivateRoute exact path="/employment-history/:recordId" component={EmploymentHistory} />

        <PrivateRoute exact path="/manage-work-authorization" component={ManageWorkAuthorization} />
        <PrivateRoute exact path="/add-work-authorization" component={AddWorkAuthorization} />
        <PrivateRoute path="/edit-work-authorization/:recordId" component={AddWorkAuthorization} />
        <PrivateRoute exact path="/work-authorization-detail/:recordId" component={WorkAuthorizationDetail} />
        <PrivateRoute exact path="/work-authorization-history/:recordId" component={WorkAuthorizationHistory} />

        <PrivateRoute exact path="/manage-job-duties" component={ManageJobDuties} />
        <PrivateRoute exact path="/add-job-duties" component={AddJobDuties} />
        <PrivateRoute path="/edit-job-duties/:recordId" component={AddJobDuties} />
        <PrivateRoute exact path="/job-duties-detail/:recordId" component={JobDutiesDetail} />
        <PrivateRoute exact path="/job-duties-history/:recordId" component={JobDutiesHistory} />

        <PrivateRoute exact path="/manage-education" component={ManageEducation} />
        <PrivateRoute exact path="/add-education" component={AddEducation} />
        <PrivateRoute path="/edit-education/:recordId" component={AddEducation} />
        <PrivateRoute exact path="/education-detail/:recordId" component={EducationDetail} />
        <PrivateRoute exact path="/education-history/:recordId" component={EducationHistory} />

        <PrivateRoute exact path="/manage-applicant" component={ManageApplicant} />
        <PrivateRoute exact path="/add-applicant" component={AddApplicant} />
        <PrivateRoute path="/edit-applicant/:recordId" component={AddApplicant} />
        <PrivateRoute exact path="/applicant-detail/:recordId" component={ApplicantDetail} />
        <PrivateRoute exact path="/applicant-history/:recordId" component={ApplicantHistory} />

        <PrivateRoute exact path="/manage-leave-absence" component={ManageLeaveAbsence} />
        <PrivateRoute exact path="/add-leave-absence" component={AddLeaveAbsence} />
        <PrivateRoute path="/edit-leave-absence/:recordId" component={AddLeaveAbsence} />
        <PrivateRoute exact path="/leave-absence-detail/:recordId" component={LeaveAbsenceDetail} />
        <PrivateRoute exact path="/leave-absence-history/:recordId" component={LeaveAbsenceHistory} />

        <PrivateRoute exact path="/manage-direct-deposit-accounts" component={ManageDirectDepositAccounts} />
        <PrivateRoute exact path="/add-direct-deposit-accounts" component={AddDirectDepositAccounts} />
        <PrivateRoute path="/edit-direct-deposit-accounts/:recordId" component={AddDirectDepositAccounts} />
        <PrivateRoute exact path="/direct-deposit-accounts-detail/:recordId" component={DirectDepositAccountsDetail} />
        <PrivateRoute exact path="/direct-deposit-accounts-history/:recordId" component={DirectDepositAccountsHistory} />

        <PrivateRoute exact path="/manage-salary-grade" component={ManageSalaryGrade} />
        <PrivateRoute exact path="/add-salary-grade" component={AddSalaryGrade} />
        <PrivateRoute path="/edit-salary-grade/:recordId" component={AddSalaryGrade} />
        <PrivateRoute exact path="/salary-grade-detail/:recordId" component={SalaryGradeDetail} />
        <PrivateRoute exact path="/salary-grade-history/:recordId" component={SalaryGradeHistory} />

        <PrivateRoute exact path="/manage-benefits-record" component={ManageBenefitsRecord} />
        <PrivateRoute exact path="/add-benefits-record" component={AddBenefitsRecord} />
        <PrivateRoute path="/edit-benefits-record/:recordId" component={AddBenefitsRecord} />
        <PrivateRoute exact path="/benefits-record-detail/:recordId" component={BenefitsRecordDetail} />
        <PrivateRoute exact path="/benefits-record-history/:recordId" component={BenefitsRecordHistory} />

        <PrivateRoute exact path="/manage-training-record" component={ManageTrainingRecord} />
        <PrivateRoute exact path="/add-training-record" component={AddTrainingRecord} />
        <PrivateRoute path="/edit-training-record/:recordId" component={AddTrainingRecord} />
        <PrivateRoute exact path="/training-record-detail/:recordId" component={TrainingRecordDetail} />
        <PrivateRoute exact path="/training-record-history/:recordId" component={TrainingRecordHistory} />

        <PrivateRoute exact path="/manage-job-qualifications" component={ManageJobQualifications} />
        <PrivateRoute exact path="/add-job-qualifications" component={AddJobQualifications} />
        <PrivateRoute path="/edit-job-qualifications/:recordId" component={AddJobQualifications} />
        <PrivateRoute exact path="/job-qualifications-detail/:recordId" component={JobQualificationsDetail} />
        <PrivateRoute exact path="/job-qualifications-history/:recordId" component={JobQualificationsHistory} />

        <PrivateRoute exact path="/manage-certification-licence" component={ManageCertificationLicence} />
        <PrivateRoute exact path="/add-certification-licence" component={AddCertificationLicence} />
        <PrivateRoute path="/edit-certification-licence/:recordId" component={AddCertificationLicence} />
        <PrivateRoute exact path="/certification-licence-detail/:recordId" component={CertificationLicenceDetail} />
        <PrivateRoute exact path="/certification-licence-history/:recordId" component={CertificationLicenceHistory} />

        <PrivateRoute exact path="/manage-timesheet" component={ManageTimesheet} />
        <PrivateRoute exact path="/add-timesheet" component={AddTimesheet} />
        <PrivateRoute path="/edit-timesheet/:recordId" component={AddTimesheet} />
        <PrivateRoute exact path="/timesheet-detail/:recordId" component={TimesheetDetail} />
        <PrivateRoute exact path="/timesheet-history/:recordId" component={TimesheetHistory} />

        <PrivateRoute exact path="/manage-safety" component={ManageSafety} />
        <PrivateRoute exact path="/add-safety" component={AddSafety} />
        <PrivateRoute path="/edit-safety/:recordId" component={AddSafety} />
        <PrivateRoute exact path="/safety-detail/:recordId" component={SafetyDetail} />
        <PrivateRoute exact path="/safety-history/:recordId" component={SafetyHistory} />

        <PrivateRoute exact path="/manage-discipline-record" component={ManageDisciplineRecord} />
        <PrivateRoute exact path="/add-discipline-record" component={AddDisciplineRecord} />
        <PrivateRoute path="/edit-discipline-record/:recordId" component={AddDisciplineRecord} />
        <PrivateRoute exact path="/discipline-record-detail/:recordId" component={DisciplineRecordDetail} />
        <PrivateRoute exact path="/discipline-record-history/:recordId" component={DisciplineRecordHistory} />

        <PrivateRoute exact path="/manage-attendane-points" component={ManageAttendanePoints} />
        <PrivateRoute exact path="/add-attendane-points" component={AddAttendanePoints} />
        <PrivateRoute path="/edit-attendane-points/:recordId" component={AddAttendanePoints} />
        <PrivateRoute exact path="/attendane-points-detail/:recordId" component={AttendanePointsDetail} />
        <PrivateRoute exact path="/attendane-points-history/:recordId" component={AttendanePointsHistory} />

        <PrivateRoute exact path="/manage-cobra" component={ManageCobra} />
        <PrivateRoute exact path="/add-cobra" component={AddCobra} />
        <PrivateRoute path="/edit-cobra/:recordId" component={AddCobra} />
        <PrivateRoute exact path="/cobra-detail/:recordId" component={CobraDetail} />
        <PrivateRoute exact path="/cobra-history/:recordId" component={CobraHistory} />

        <PrivateRoute exact path="/manage-paid-time-off" component={ManagePaidTimeOff} />
        <PrivateRoute exact path="/add-paid-time-off" component={AddPaidTimeOff} />
        <PrivateRoute path="/edit-paid-time-off/:recordId" component={AddPaidTimeOff} />
        <PrivateRoute exact path="/paid-time-off-detail/:recordId" component={PaidTimeOffDetail} />
        <PrivateRoute exact path="/paid-time-off-history/:recordId" component={PaidTimeOffHistory} />

        <PrivateRoute exact path="/create-report" component={CreateReport} />
        <PrivateRoute exact path="/edit-report/:reportId" component={CreateReport} />
        <PrivateRoute exact path="/report-list" component={ReportList} />

        <OpenRoute exact path="/logout" component={Logout} />
        <OpenRoute path="*" component={Notfound} />
      </Switch>
    );
  }
}
