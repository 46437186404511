import React from "react";
import { Col, Row, Table } from "antd";

const columnsemp = [
  {
    title: "Name",
    render: (data) =>
      `${
        data.personalInformation.firstName +
        " " +
        data.personalInformation.lastName
      }`,
    key: "firstName",
  },
  {
    title: "Department",
    dataIndex: "cc2OrDepartment",
    key: "cc2OrDepartment",
  },
  {
    title: "Date",
    dataIndex: "seniorityDate",
    key: "seniorityDate",
  },
];

const EmployeeAnniversaryList = (props) => {
  return (
    <div className="empmonthwrap">
      <div className="headingrow">
        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
          <Col flex="auto">
            <h3>
              Employee Anniversaries this month
              <div className="count">{props.data ? props.data.length : 0}</div>
            </h3>
          </Col>
        </Row>
      </div>
      <Table
        className="table_grid"
        columns={columnsemp}
        dataSource={props.data ? props.data.slice(0, 3) : []}
        scroll={{ x: 270 }}
        pagination={false}
      />
    </div>
  );
};

export default EmployeeAnniversaryList;
