import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Col, Row, Form, Select, Button, Input, Space, Tooltip } from "antd";
import { DeleteOutlined, QuestionOutlined } from "@ant-design/icons";

import { GET_REPORT_FIELDS_QUERY } from "../reportingTool/ReportingToolQuery";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

import {
  GET_ALL_TEMPLATES,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
} from "./TemplateQuery";
const text = (
  <span>
    You may save this mapping configuration as a template. Next time, you can
    use that configuration template to save time.
  </span>
);
const { Option } = Select;
class MapFields extends Component {
  state = {
    apiLoading: false,
    templateId: null,
    templateList: [],
    fieldArr: [],
    deleteBtnLoading: false,
    saveBtnLoading: false,
  };

  fieldForm = React.createRef();

  componentDidMount() {
    this.getRecordFields();
    this.getTemplateList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.stateValues.selectedRecordValue !==
      this.props.stateValues.selectedRecordValue
    ) {
      this.getRecordFields();
      this.getTemplateList();
    }

    if (
      prevProps.stateValues.isFileUploaded !==
      this.props.stateValues.isFileUploaded
    ) {
      this.resetTemplate();
    }
  }

  resetTemplate() {
    this.setState({ templateId: null });
  }

  getRecordFields = () => {
    this.setState({ apiLoading: true });
    this.props.client
      .mutate({
        mutation: GET_REPORT_FIELDS_QUERY,
        variables: {
          records: this.props.stateValues.selectedRecordImportFileKey,
        },
      })
      .then((result) => {
        let fieldArr = [];

        if (
          result.data.getReportWriterFormData[0] &&
          result.data.getReportWriterFormData[0] !== undefined &&
          result.data.getReportWriterFormData[0].fields.length > 0
        ) {
          const fields = result.data.getReportWriterFormData[0].fields;
          fieldArr = fields.filter((x) => x.csvImport === true);
        }
        this.setState({ apiLoading: false, fieldArr, templateId: null });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  getTemplateList = () => {
    this.setState({ apiLoading: true });
    this.props.client
      .mutate({
        mutation: GET_ALL_TEMPLATES,
        variables: { searchQuery: this.props.stateValues.selectedRecordValue },
      })
      .then((result) => {
        this.setState({
          apiLoading: false,
          templateList: result.data.getCSVMappingTemplates,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleTemplateSelect = (e) => {
    let templateObj = this.state.templateList.find((o) => o.templateId === e);
    let mcFieldObject = {};

    if (templateObj.templateFields.length > 0) {
      templateObj.templateFields.map((field, x) => {
        mcFieldObject["field" + x] = field;
        return null;
      });
    }
    let selectedTempFields = templateObj.templateFields;
    this.props.setTemplateFields(mcFieldObject, selectedTempFields);

    this.setState({
      templateId: templateObj.templateId,
      templateFieldsArr: templateObj.templateFields,
    });
  };

  saveTemplate = () => {
    if (
      this.props.stateValues.templateName === "" ||
      this.props.stateValues.templateName === null
    ) {
      ErrorNotificationMsg("Please enter template name.");
      return false;
    }
    this.setState({ saveBtnLoading: true });
    this.props.client
      .mutate({
        mutation: CREATE_TEMPLATE,
        variables: {
          templateName: this.props.stateValues.templateName,
          recordName: this.props.stateValues.selectedRecordValue,
          templateFields: this.props.stateValues.masterArray,
        },
      })
      .then(() => {
        SuccessNotificationMsg("Success", "Template save successfully.");
        this.setState({
          saveBtnLoading: false,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ saveBtnLoading: false });
      });
  };

  deleteTemplate = () => {
    this.setState({ deleteBtnLoading: true });
    this.props.client
      .mutate({
        mutation: DELETE_TEMPLATE,
        variables: {
          templateId: this.state.templateId,
        },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Template deleted successfully.");
        this.setState({
          deleteBtnLoading: false,
          templateId: null,
          templateList: this.state.templateList.filter(
            (s) => this.state.templateId !== s.templateId
          ),
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ deleteBtnLoading: false });
      });
  };

  render() {
    const { isFileUploaded, fileReadData, btnLoading } = this.props.stateValues;
    const { handleMapFieldChange } = this.props;
    const {
      fieldArr,
      templateList,
      templateId,
      deleteBtnLoading,
      saveBtnLoading,
    } = this.state;

    if (!isFileUploaded) return null;

    return (
      <div className={btnLoading ? "mapfields-wrap noevent" : "mapfields-wrap"}>
        <div className="field-title">
          <span>Import Templates</span>
          <div className="tooltipwrp">
            <Tooltip placement="bottomRight" title={text}>
              <QuestionOutlined />
            </Tooltip>
          </div>
        </div>

        <Row gutter={[32]} className="filterwrp">
          <Col xs={24} md={12}>
            <Form.Item>
              <Space>
                <Select
                  showSearch
                  placeholder="Select template"
                  optionFilterProp="children"
                  onChange={(e) => this.handleTemplateSelect(e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={templateId ? templateId : null}
                >
                  {templateList.map((template) => (
                    <Option
                      value={template.templateId}
                      key={template.templateId}
                    >
                      {template.templateName}
                    </Option>
                  ))}
                </Select>

                {templateId && (
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={this.deleteTemplate}
                    loading={deleteBtnLoading}
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              {this.props.stateValues.manualFieldMap && (
                <Space className="right">
                  <Input
                    onChange={(e) => this.props.setTemplateName(e)}
                    placeholder="Enter template name"
                    value={
                      this.props.stateValues.templateName
                        ? this.props.stateValues.templateName
                        : null
                    }
                  />
                  <Button
                    type="primary"
                    htmlType="button"
                    loading={saveBtnLoading}
                    onClick={this.saveTemplate}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <div className="field-title">
          <span>Map Fields</span>
        </div>
        <Row gutter={[32]}>
          {fileReadData[0].data && fileReadData[0].data.length > 0
            ? fileReadData[0].data.map((fileField, x) => {
                return (
                  <Col xs={24} md={12} key={x}>
                    <div className="field-wrp">
                      <label>{fileField}</label>
                      <Form.Item name={"field" + x}>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          className="field-dropdown"
                          placeholder="Select Field"
                          onChange={(value) => handleMapFieldChange(x, value)}
                        >
                          {fieldArr.map((field, k) => {
                            let nameArr = field.fieldId.split(".");
                            return (
                              <Option key={field.fieldId} value={field.fieldId}>
                                {CamelCaseToSpaceLetter(nameArr[1])}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                );
              })
            : ""}
        </Row>
      </div>
    );
  }
}

export default withApollo(MapFields);
