import gql from "graphql-tag";

export const ADD_COBRA = gql`
  mutation createCOBRARecord(
    $personId: String!
    $electionStatus: String
    $qualifyingEvent: String
    $paidThrough: String
    $comments: String
    $street1: String
    $street2: String
    $city: String
    $state: String
    $zipCode: String
    $countryOrRegion: String
    $phone: String
    $electionStatus2: String
    $qualifyingEvent2: String
    $comments2: String
    $address: String
    $city2: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $countryOrRegion2: String
    $phone2: String
    $dateReceived: String
    $amount: String
    $checkNumber: String
    $comments3: String
    $dateSent: Date
    $notificationType: String
    $recipients: String
    $notificationMethod: String
  ) {
    createCOBRARecord(
      personId: $personId
      data: {
        electionStatus: $electionStatus
        qualifyingEvent: $qualifyingEvent
        paidThrough: $paidThrough
        comments: $comments
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zipCode: $zipCode
        countryOrRegion: $countryOrRegion
        phone: $phone
        electionStatus2: $electionStatus2
        qualifyingEvent2: $qualifyingEvent2
        comments2: $comments2
        address: $address
        city2: $city2
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        countryOrRegion2: $countryOrRegion2
        phone2: $phone2
        dateReceived: $dateReceived
        amount: $amount
        checkNumber: $checkNumber
        comments3: $comments3
        dateSent: $dateSent
        notificationType: $notificationType
        recipients: $recipients
        notificationMethod: $notificationMethod
      }
    )
  }
`;

export const COBRA_LIST_QUERY = gql`
  query getEmployeeCOBRARecords($personId: String!) {
    getEmployeeCOBRARecords(personId: $personId) {
      cobraRecordId
      personId
      electionStatus
      qualifyingEvent
      paidThrough
      comments
      street1
      street2
      city
      state
      zipCode
      countryOrRegion
      phone
      electionStatus2
      qualifyingEvent2
      comments2
      address
      city2
      stateOrProvince
      zipOrPostalCode
      countryOrRegion2
      phone2
      dateReceived
      amount
      checkNumber
      comments3
      dateSent
      notificationType
      recipients
      notificationMethod
    }
  }
`;

export const GET_COBRA_QUERY = gql`
  query getEmployeeCOBRARecordById($recordId: String!) {
    getEmployeeCOBRARecordById(recordId: $recordId) {
      personId
      electionStatus
      qualifyingEvent
      paidThrough
      comments
      street1
      street2
      city
      state
      zipCode
      countryOrRegion
      phone
      electionStatus2
      qualifyingEvent2
      comments2
      address
      city2
      stateOrProvince
      zipOrPostalCode
      countryOrRegion2
      phone2
      dateReceived
      amount
      checkNumber
      comments3
      dateSent
      notificationType
      recipients
      notificationMethod
    }
  }
`;

export const UPDATE_COBRA = gql`
  mutation updateCOBRARecord(
    $recordId: String!
    $electionStatus: String
    $qualifyingEvent: String
    $paidThrough: String
    $comments: String
    $street1: String
    $street2: String
    $city: String
    $state: String
    $zipCode: String
    $countryOrRegion: String
    $phone: String
    $electionStatus2: String
    $qualifyingEvent2: String
    $comments2: String
    $address: String
    $city2: String
    $stateOrProvince: String
    $zipOrPostalCode: String
    $countryOrRegion2: String
    $phone2: String
    $dateReceived: String
    $amount: String
    $checkNumber: String
    $comments3: String
    $dateSent: Date
    $notificationType: String
    $recipients: String
    $notificationMethod: String
  ) {
    updateCOBRARecord(
      recordId: $recordId
      data: {
        electionStatus: $electionStatus
        qualifyingEvent: $qualifyingEvent
        paidThrough: $paidThrough
        comments: $comments
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zipCode: $zipCode
        countryOrRegion: $countryOrRegion
        phone: $phone
        electionStatus2: $electionStatus2
        qualifyingEvent2: $qualifyingEvent2
        comments2: $comments2
        address: $address
        city2: $city2
        stateOrProvince: $stateOrProvince
        zipOrPostalCode: $zipOrPostalCode
        countryOrRegion2: $countryOrRegion2
        phone2: $phone2
        dateReceived: $dateReceived
        amount: $amount
        checkNumber: $checkNumber
        comments3: $comments3
        dateSent: $dateSent
        notificationType: $notificationType
        recipients: $recipients
        notificationMethod: $notificationMethod
      }
    )
  }
`;

export const GET_COBRA_HISTORY = gql`
  query getEmployeeCOBRARecordHistoryById($recordId: String!) {
    getEmployeeCOBRARecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        electionStatus
        qualifyingEvent
        paidThrough
        comments
        street1
        street2
        city
        state
        zipCode
        countryOrRegion
        phone
        electionStatus2
        qualifyingEvent2
        comments2
        address
        city2
        stateOrProvince
        zipOrPostalCode
        countryOrRegion2
        phone2
        dateReceived
        amount
        checkNumber
        comments3
        dateSent
        notificationType
        recipients
        notificationMethod
        recordSource
      }
    }
  }
`;

export const DELETE_COBRA = gql`
  mutation deleteCOBRARecord($recordId: String!) {
    deleteCOBRARecord(recordId: $recordId)
  }
`;
