import gql from "graphql-tag";

export const ADD_EDUCATION = gql`
  mutation createEducationRecord(
    $personId: String!
    $major: String
    $secondMajor: String
    $minor: String
    $schoolName: String
    $cc1OrLocation: String
    $educationLevel: String
    $startDate: String
    $graduated: String
    $graduationDate: Date
    $gpa: String
    $honoraryRecogniton: String
    $verificationDate: Date
  ) {
    createEducationRecord(
      personId: $personId
      data: {
        major: $major
        secondMajor: $secondMajor
        minor: $minor
        schoolName: $schoolName
        cc1OrLocation: $cc1OrLocation
        educationLevel: $educationLevel
        startDate: $startDate
        graduated: $graduated
        graduationDate: $graduationDate
        gpa: $gpa
        honoraryRecogniton: $honoraryRecogniton
        verificationDate: $verificationDate
      }
    )
  }
`;

export const EDUCATION_LIST_QUERY = gql`
  query getEmployeeEducationRecords($personId: String!) {
    getEmployeeEducationRecords(personId: $personId) {
      educationRecordId
      personId
      major
      secondMajor
      minor
      schoolName
      cc1OrLocation
      educationLevel
      startDate
      graduated
      graduationDate
      gpa
      honoraryRecogniton
      verificationDate
    }
  }
`;

export const GET_EDUCATION_QUERY = gql`
  query getEmployeeEducationRecordById($recordId: String!) {
    getEmployeeEducationRecordById(recordId: $recordId) {
      personId
      major
      secondMajor
      minor
      schoolName
      cc1OrLocation
      educationLevel
      startDate
      graduated
      graduationDate
      gpa
      honoraryRecogniton
      verificationDate
    }
  }
`;

export const UPDATE_EDUCATION = gql`
  mutation updateEducationRecord(
    $personId: String!
    $major: String
    $secondMajor: String
    $minor: String
    $schoolName: String
    $cc1OrLocation: String
    $educationLevel: String
    $startDate: String!
    $graduated: String
    $graduationDate: Date
    $gpa: String
    $honoraryRecogniton: String
    $verificationDate: Date
  ) {
    updateEducationRecord(
      personId: $personId
      data: {
        major: $major
        secondMajor: $secondMajor
        minor: $minor
        schoolName: $schoolName
        cc1OrLocation: $cc1OrLocation
        educationLevel: $educationLevel
        startDate: $startDate
        graduated: $graduated
        graduationDate: $graduationDate
        gpa: $gpa
        honoraryRecogniton: $honoraryRecogniton
        verificationDate: $verificationDate
      }
    )
  }
`;

export const GET_EDUCATION_HISTORY = gql`
  query getEmployeeEducationRecordHistoryById($recordId: String!) {
    getEmployeeEducationRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        major
        secondMajor
        minor
        schoolName
        cc1OrLocation
        educationLevel
        startDate
        graduated
        graduationDate
        gpa
        honoraryRecogniton
        verificationDate
        recordSource
      }
    }
  }
`;

export const DELETE_EDUCATION = gql`
  mutation deleteEducationRecord($recordId: String!) {
    deleteEducationRecord(recordId: $recordId)
  }
`;
