import React, { Component } from "react";
import footerlogo from "../images/logo-cn.png";

class LogoSmall extends Component {

    render() {
        return (
            <img alt="logo" style={{ width:"160px"}} src={footerlogo} ></img>
            // <h1 style={{color:"white", fontSize:"28px"}}>Canopy</h1>
        );
    }
}

export default LogoSmall;