import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { Col, Row, Layout, Breadcrumb, Spin, Timeline, Form } from "antd";

import HistoryDetailDrawer from "../common/HistoryDetailDrawer";
import { authUserData, ToDateTime } from "../../utils/Helpers";
import { ErrorNotificationMsg } from "../../utils/NotificationHelper";

import { GET_EMPLOYMENT_HISTORY } from "./EmploymentQuery";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;

class EmploymentHistory extends Component {
  state = {
    apiLoading: false,
    authUserId: authUserData().sub,
    historyData: [],
  };
  formRef = React.createRef();

  componentDidMount() {
    let recordId = this.props.match.params.recordId
      ? atob(this.props.match.params.recordId)
      : "";

    let personId = this.props.history.location.state
      ? this.props.history.location.state.personId
      : "";

    if (recordId !== "") {
      this.getHistory(recordId);
      this.setState({ recordId, personId });
    }
  }

  getHistory = (recordId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_EMPLOYMENT_HISTORY,
        variables: { recordId: recordId },
      })
      .then((response) => {
        this.setState({
          historyData: response.data.getEmployeeEmploymentRecordHistoryById,
        });
        this.setState({ apiLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { historyData, personId } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Employment History : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={{
                        pathname: "/manage-employment",
                        state: { personId: this.state.personId },
                      }}
                    >
                      Manage Employment
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Employment History</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper employeeDetail">
                <div className="employeeDetail_wraper">
                  <div className="heading">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col xs={24} align="end"></Col>
                    </Row>
                  </div>
                  <Spin spinning={this.state.apiLoading}>
                    {
                      <Timeline mode="alternate">
                        {historyData.length > 0
                          ? historyData.map((history, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  className="historycont"
                                >
                                  <Row gutter={[15]}>
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      className="vtitle"
                                    >
                                      <div className="vinline">
                                        <label>Version : </label>
                                        <Form.Item>
                                          {history.metadata.version}
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col xs={24} sm={10} md={10} lg={10}>
                                      <label>Transaction Id</label>
                                      <Form.Item>
                                        {history.metadata.txId}
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={8}>
                                      <label>Transaction Time</label>
                                      <Form.Item>
                                        {ToDateTime(history.metadata.txTime)}
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={6}>
                                      <label>Record Source</label>
                                      <Form.Item>
                                        {history.data.recordSource
                                          ? history.data.recordSource
                                          : ""}
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                      <label>Hash</label>
                                      <Form.Item>{history.hash}</Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                      <HistoryDetailDrawer
                                        historyData={history}
                                      />
                                    </Col>
                                  </Row>
                                </Timeline.Item>
                              );
                            })
                          : ""}
                      </Timeline>
                    }
                  </Spin>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(EmploymentHistory);
