import gql from "graphql-tag";

export const DASHBOARD_METRICS_QUERY = gql`
  query getDashboardMetrics {
    getDashboardMetrics {
      totalEmployees
      activeEmployees
      employeesOnLeave
      ytdNewHires
      ytdTerminations
      anniversariesThisMonth
      documentsExpiring
      employmentData
    }
  }
`;