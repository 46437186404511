import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { CSVReader } from "react-papaparse";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Breadcrumb,
  Select,
  Space,
} from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import MapFields from "./MapFields";
import recordTypes from "./recordTypes.json";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
  ImportErrorNotificationMsg,
} from "../../utils/NotificationHelper";
import { authUserData } from "../../utils/Helpers";
import "../dashboard/Dashboard.scss";
import { IMPORT_RECORDS } from "./ImportQuery";

const { Content } = Layout;
const { Option } = Select;
const buttonRef = React.createRef();

class MapImport extends Component {
  state = {
    btnLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    selectedRecordLabel: "Personal Information Record",
    selectedRecordValue: "PersonalInformation",
    selectedRecordFormatURL:
      "https://canopy-test-bucket.s3.us-east-2.amazonaws.com/sampleCSVHeaders/v2/personalInformation-header.csv",
    selectedRecordImportFileKey: "PersonalInformationHistory",

    isFileUploaded: false,
    fileReadData: [],
    templateName: null,
    manualFieldMap: false,
    masterArray: [],
  };

  formRef = React.createRef();

  onSelectChange = (e) => {
    let recordObj = recordTypes.find((o) => o.value === e);
    this.setState({
      selectedRecordLabel: recordObj.label,
      selectedRecordValue: recordObj.value,
      selectedRecordFormatURL: recordObj.formatURL,
      selectedRecordImportFileKey: recordObj.importFileKey,
    });
  };

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data) => {
    if (Array.isArray(data) && data[0].data && data[0].data.length > 1) {
      this.setState({
        isFileUploaded: true,
        fileReadData: data,
        masterArray: Array(data[0].data.length).fill(null),
      });
    } else {
      ErrorNotificationMsg("CSV file is invalid or blank.");
      return false;
    }
  };

  handleOnError = (err) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    this.formRef.current.resetFields();
    this.setState({
      isFileUploaded: false,
      fileReadData: [],
      templateName: null,
      masterArray: [],
      manualFieldMap: false,
    });
  };

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  handleMapFieldChange = (index, value) => {
    const newMasterArr = [...this.state.masterArray];
    newMasterArr[index] = value;

    this.setState({
      manualFieldMap: true,
      masterArray: newMasterArr,
    });
  };

  hasDuplicates(arr) {
    const sh = arr.filter(function (el) {
      return el != null;
    });
    return new Set(sh).size !== sh.length;
  }

  handleImportData = () => {
    this.setState({ btnLoading: true });
    let uniqueMapFieldIndexes = [];

    this.state.masterArray.map((val, index) => {
      if (val !== null) {
        uniqueMapFieldIndexes.push(index);
      }
      return null;
    });
    uniqueMapFieldIndexes.sort();

    let postData = [];
    let fileData = this.state.fileReadData;
    let columnArr = this.state.masterArray;

    if (uniqueMapFieldIndexes.length === 0) {
      ErrorNotificationMsg("Please map field first.");
      this.setState({ btnLoading: false });
      return false;
    }

    if (this.hasDuplicates(columnArr)) {
      ErrorNotificationMsg("Duplicate mapping detected. Map fields uniquely.");
      this.setState({ btnLoading: false });
      return false;
    }

    if (columnArr)
      fileData.forEach((row, index) => {
        if (index !== 0) {
          if (row.data && row.data[0] !== "") {
            let ss = {};
            columnArr.map((o, x) => {
              if (uniqueMapFieldIndexes.includes(x)) {
                let fieldnameArr = o.split(".");
                let fname = fieldnameArr[1];
                ss[fname] = row.data[x];
              }
              return null;
            });
            postData.push(ss);
          }
        }
      });

    if (postData.length > 0) {
      this.callImportAPI(postData);
    }
  };

  callImportAPI = (postData) => {
    const { client } = this.props;
    client
      .mutate({
        mutation: IMPORT_RECORDS,
        variables: {
          records: postData,
          recordType: this.state.selectedRecordValue,
        },
      })
      .then((response) => {
        if (response.data.importRecords.hasOwnProperty("errors")) {
          let errorsArr = response.data.importRecords.errors;
          errorsArr.forEach((error) => {
            ImportErrorNotificationMsg("Error", error);
            this.setState({ btnLoading: false });
          });
        } else {
          SuccessNotificationMsg("Success", "Data imported successfully.");
          this.setState({ btnLoading: false });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleBack() {
    window.location.reload();
  }

  setTemplateFields = (mcFieldObject, selectedTempFields) => {
    this.setState({ masterArray: selectedTempFields });
    this.formRef.current.setFieldsValue(mcFieldObject);
  };

  setTemplateName = (e) => {
    this.setState({ templateName: e.target.value });
  };

  handleTemplateSelect = (data) => {
    this.setState({ masterArray: data });
  };

  render() {
    const {
      btnLoading,
      isFileUploaded,
      selectedRecordLabel,
      selectedRecordFormatURL,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Import from CSV
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Import from CSV</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="quotewraper uploadwrap">
                  <Form onFinish={this.handleImportData} ref={this.formRef}>
                    <Row gutter={[10, 15, 10, 15]}>
                      <Col
                        xs={24}
                        sm={24}
                        lg={24}
                        className="uploadcol"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Select
                          className="upload-dropdown"
                          showSearch
                          disabled={this.state.isFileUploaded ? true : false}
                          style={{ width: 280 }}
                          defaultValue="PersonalInformation"
                          optionFilterProp="children"
                          onChange={(e) => this.onSelectChange(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {recordTypes.map((record) => (
                            <Option value={record.value} key={record.value}>
                              {record.label}
                            </Option>
                          ))}
                        </Select>
                        <Button
                          className="csv-btn"
                          icon={<UploadOutlined />}
                          onClick={this.handleOpenDialog}
                        >
                          CSV Upload
                        </Button>
                      </Col>
                    </Row>
                    <Row gutter={[15, 0]}>
                      <Col
                        xs={24}
                        sm={24}
                        lg={24}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="uploadedfilewrp">
                          <CSVReader
                            ref={buttonRef}
                            onFileLoad={this.handleOnFileLoad}
                            onError={this.handleOnError}
                            noClick
                            noDrag
                            onRemoveFile={this.handleOnRemoveFile}
                            className="csvuploadfileWraper"
                          >
                            {({ file }) => (
                              <aside className="uploadedfile">
                                <div>{file && file.name}</div>
                                {isFileUploaded ? (
                                  <Button
                                    type="secondary"
                                    htmlType="button"
                                    icon={<CloseOutlined />}
                                    onClick={this.handleRemoveFile}
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </aside>
                            )}
                          </CSVReader>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={[15]}>
                      <Col xs={24} sm={24} lg={24} className="uploadcol">
                        <label>
                          Download Sample CSV Header File For{" "}
                          {selectedRecordLabel} From{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkhere"
                            href={selectedRecordFormatURL}
                          >
                            Here.
                          </a>{" "}
                        </label>
                      </Col>
                    </Row>

                    <MapFields
                      stateValues={this.state}
                      handleMapFieldChange={this.handleMapFieldChange}
                      setTemplateFields={this.setTemplateFields}
                      setTemplateName={this.setTemplateName}
                      handleTemplateSelect={this.handleTemplateSelect}
                    />
                    <br />
                    <br />
                    {isFileUploaded ? (
                      <div className="mapbtnrow">
                        <Space>
                          <Button
                            type="secondary"
                            htmlType="button"
                            onClick={this.handleBack}
                          >
                            Back
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={btnLoading}
                          >
                            Continue
                          </Button>
                        </Space>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(MapImport);
