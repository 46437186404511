import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Drawer, Button } from "antd";
import { MenuOutlined, RightOutlined } from "@ant-design/icons";

const RecordMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const { history, personId } = props;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      {props.buttonStyle ? (
        <Button type="primary" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
      ) : (
        <span className="record-menu-link" onClick={showDrawer}>
          <MenuOutlined />
        </span>
      )}

      <Drawer
        className="record-menu-drawer"
        width={315}
        title="Record Menu"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <ul className="navigate">
          <li
            className={location.pathname === "/manage-employee" ? "active" : ""}
            onClick={() => history.push("/manage-employee")}
          >
            <RightOutlined />
            Manage Employee
          </li>

          <li
            className={
              location.pathname === "/manage-applicant" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-applicant", {
                personId,
              })
            }
          >
            <RightOutlined />
            Applicant Record
          </li>
          <li
            className={
              location.pathname === "/manage-attendane-points" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-attendane-points", {
                personId,
              })
            }
          >
            <RightOutlined />
            Attendane Points Record
          </li>

          <li
            className={
              location.pathname === "/manage-benefits-record" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-benefits-record", {
                personId,
              })
            }
          >
            <RightOutlined />
            Benefits Record
          </li>

          <li
            className={location.pathname === "/manage-cobra" ? "active" : ""}
            onClick={() =>
              history.push("/manage-cobra", {
                personId,
              })
            }
          >
            <RightOutlined />
            COBRA Record
          </li>
          <li
            className={
              location.pathname === "/manage-compensation" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-compensation", {
                personId,
              })
            }
          >
            <RightOutlined />
            Compensation Record
          </li>
          <li
            className={
              location.pathname === "/manage-certification-licence"
                ? "active"
                : ""
            }
            onClick={() =>
              history.push("/manage-certification-licence", {
                personId,
              })
            }
          >
            <RightOutlined />
            Certification and Licence Record
          </li>
          <li
            className={
              location.pathname === "/manage-dependents" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-dependents", {
                personId,
              })
            }
          >
            <RightOutlined />
            Dependents Record
          </li>
          <li
            className={
              location.pathname === "/manage-discipline-record" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-discipline-record", {
                personId,
              })
            }
          >
            <RightOutlined />
            Discipline Record
          </li>
          <li
            className={
              location.pathname === "/manage-direct-deposit-accounts"
                ? "active"
                : ""
            }
            onClick={() =>
              history.push("/manage-direct-deposit-accounts", {
                personId,
              })
            }
          >
            <RightOutlined />
            Direct Deposit Accounts Record
          </li>
          <li
            className={
              location.pathname === "/manage-emergency-contact" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-emergency-contact", {
                personId,
              })
            }
          >
            <RightOutlined />
            Emergency Contacts
          </li>
          <li
            className={
              location.pathname === "/manage-employment" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-employment", {
                personId,
              })
            }
          >
            <RightOutlined />
            Employment Record
          </li>
          <li
            className={
              location.pathname === "/manage-education" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-education", {
                personId,
              })
            }
          >
            <RightOutlined />
            Education Record
          </li>
          <li
            className={
              location.pathname === "/manage-job-duties" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-job-duties", {
                personId,
              })
            }
          >
            <RightOutlined />
            Job Duties Record
          </li>
          <li
            className={
              location.pathname === "/manage-job-qualifications" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-job-qualifications", {
                personId,
              })
            }
          >
            <RightOutlined />
            Job Qualifications Record
          </li>
          <li
            className={
              location.pathname === "/manage-leave-absence" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-leave-absence", {
                personId,
              })
            }
          >
            <RightOutlined />
            Leave or Absence Record
          </li>
          <li
            className={
              location.pathname === "/manage-pay-change" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-pay-change", {
                personId,
              })
            }
          >
            <RightOutlined />
            Pay Change Record
          </li>
          <li
            className={
              location.pathname === "/manage-paid-time-off" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-paid-time-off", {
                personId,
              })
            }
          >
            <RightOutlined />
            Paid Time Off Record
          </li>
          <li
            className={
              location.pathname === "/manage-salary-grade" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-salary-grade", {
                personId,
              })
            }
          >
            <RightOutlined />
            Salary Grade Record
          </li>
          <li
            className={location.pathname === "/manage-safety" ? "active" : ""}
            onClick={() =>
              history.push("/manage-safety", {
                personId,
              })
            }
          >
            <RightOutlined />
            Safety Record
          </li>
          <li
            className={
              location.pathname === "/manage-tax-election" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-tax-election", {
                personId,
              })
            }
          >
            <RightOutlined />
            Tax Elections
          </li>
          <li
            className={
              location.pathname === "/manage-timesheet" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-timesheet", {
                personId,
              })
            }
          >
            <RightOutlined />
            Timesheet Record
          </li>
          <li
            className={
              location.pathname === "/manage-training-record" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-training-record", {
                personId,
              })
            }
          >
            <RightOutlined />
            Training Record
          </li>
          <li
            className={
              location.pathname === "/manage-work-authorization" ? "active" : ""
            }
            onClick={() =>
              history.push("/manage-work-authorization", {
                personId,
              })
            }
          >
            <RightOutlined />
            Work Authorization Record
          </li>
        </ul>
      </Drawer>
    </React.Fragment>
  );
};

export default RecordMenu;
