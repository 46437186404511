import React, { Component } from "react";
import {
  Col,
  Row,
  Input,
  Checkbox,
  Select,
  Popconfirm,
  AutoComplete,
} from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

const { Option } = Select;
class ReportFilters extends Component {  

  onSelect = (value, option) => {
    this.setState({ userId: option.id });
  };

  render() {
    const { stateValues } = this.props;
    return (
      <>
        <Row gutter={{ xs: 0, sm: 0, md: 16, lg: 32 }} className="filterrow">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="col_2">
            {this.props.stateValues.reportViewSortBy.map((x, index) => {
              return (
                <Row gutter={[6]} key={index}>
                  <Col xs={24} sm={24} md={5} lg={3}>
                    {index === 0 ? (
                      <label className="lbl">Sorting Field:</label>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={5}>
                    <Select
                      showSearch
                      placeholder="Fields"
                      name="sortingField"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.props.handleSortingSelectChange(
                          "sortingField",
                          value,
                          index
                        );
                      }}
                      value={x.sortingField ? x.sortingField : null}
                    >
                      {stateValues.allFields.map((field, k) => {
                        let [tname, fname] = field.split(".");
                        return (
                          <Option key={field} value={field}>
                            {CamelCaseToSpaceLetter(
                              tname.substr(0, tname.length - 7)
                            ) +
                              " > " +
                              CamelCaseToSpaceLetter(fname)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={4}>
                    <Select
                      placeholder="Sort Order"
                      name="sortingOrder"
                      onChange={(value) => {
                        this.props.handleSortingSelectChange(
                          "sortingOrder",
                          value,
                          index
                        );
                      }}
                      value={x.sortingOrder ? x.sortingOrder : null}
                    >
                      <Option value="ASC">ASC</Option>
                      <Option value="DESC">DESC</Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={3} lg={2}>
                    {this.props.stateValues.reportViewSortBy &&
                    this.props.stateValues.reportViewSortBy.length < 2 ? (
                      <PlusCircleOutlined
                        className="plusicon"
                        onClick={(e) => this.props.addSorting(e)}
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}

                    <Popconfirm
                      className="action"
                      title="Are you sure to delete ?"
                      onConfirm={(id) => this.props.removeSorting(index, x)}
                      okText="Yes"
                      placement="left"
                    >
                      <CloseCircleOutlined className="plusicon" />
                    </Popconfirm>
                  </Col>
                </Row>
              );
            })}

            {this.props.stateValues.reportViewSortBy &&
            this.props.stateValues.reportViewSortBy.length === 0 ? (
              <Row gutter={[6]}>
                <Col xs={24} sm={24} md={5} lg={3}>
                  <label className="lbl">Sorting Field:</label>
                </Col>
                <Col xs={24} sm={24} md={3} lg={2}>
                  <PlusCircleOutlined
                    className="plusicon"
                    onClick={(e) => this.props.addSorting(e)}
                    style={{ marginRight: "5px" }}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row gutter={{ xs: 0, sm: 0, md: 16, lg: 32 }} className="filterrow">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="col_2">
            {this.props.appliedFilters.map((x, index) => {
              return (
                <Row gutter={[6]} key={index}>
                  <Col xs={24} sm={24} md={5} lg={3}>
                    {index === 0 ? <label className="lbl">Filter:</label> : ""}
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={3}>
                    <Select
                      showSearch
                      placeholder="Begin With"
                      name="startsWith"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.props.handleFilterSelectChange(
                          "startsWith",
                          value,
                          index
                        );
                      }}
                      value={x.startsWith ? x.startsWith : null}
                    >
                      <Option value="BLANK">Blank</Option>
                      <Option value="OPEN_BRACE">(</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={5}>
                    <Select
                      showSearch
                      placeholder="Fields"
                      name="filterField"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.props.handleFilterSelectChange(
                          "filterField",
                          value,
                          index
                        );
                      }}
                      value={x.filterField ? x.filterField : null}
                    >
                      {stateValues.allFields.map((field, k) => {
                        let [tname, fname] = field.split(".");
                        return (
                          <Option key={field} value={field}>
                            {CamelCaseToSpaceLetter(
                              tname.substr(0, tname.length - 7)
                            ) +
                              " > " +
                              CamelCaseToSpaceLetter(fname)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={4}>
                    <Select
                      placeholder="Operator"
                      name="operator"
                      onChange={(value) => {
                        this.props.handleFilterSelectChange(
                          "operator",
                          value,
                          index
                        );
                      }}
                      value={x.operator ? x.operator : null}
                    >
                      <Option value="EQ">Equal To</Option>
                      <Option value="NE">Not Equal To</Option>
                      <Option value="GT">Greater Than</Option>
                      <Option value="LT">Less Than</Option>
                      <Option value="GTE">Greater Than Equal To</Option>
                      <Option value="LTE">Less Than Equal To</Option>
                      <Option value="CONTAINS">Contains</Option>
                      <Option value="NOT_CONTAINS">Not Contains</Option>
                      <Option value="BEGINS_WITH">Begins With</Option>
                      <Option value="ENDS_WITH">Ends With</Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={4}>
                    <AutoComplete
                      onSelect={this.onSelect}                      
                      onFocus={(value) => this.props.onSearchFieldValue(value, index)}
                      onChange={(value) => {
                        this.props.handleFilterSelectChange(
                          "value",
                          value,
                          index
                        );
                      }}
                      value={x.value ? x.value : null}
                      options={this.props.stateValues.searchOptions}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    >
                      <Input size="large" placeholder="Select Value" />
                    </AutoComplete>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={3}>
                    <Select
                      placeholder="Condition"
                      name="endsWith"
                      onChange={(value) => {
                        this.props.handleFilterSelectChange(
                          "endsWith",
                          value,
                          index
                        );
                      }}
                      value={x.endsWith ? x.endsWith : null}
                    >
                      <Option value="BLANK">Blank</Option>
                      <Option value="AND">AND</Option>
                      <Option value="OR">OR</Option>

                      <Option value="CLOSED_BRACE">)</Option>
                      <Option value="CLOSED_BRACE_THEN_AND">) AND</Option>
                      <Option value="CLOSED_BRACE_THEN_OR">) OR</Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={3} lg={2}>
                    <PlusCircleOutlined
                      className="plusicon"
                      onClick={(e) => this.props.addFilter(e)}
                      style={{ marginRight: "5px" }}
                    />

                    <Popconfirm
                      className="action"
                      title="Are you sure to delete ?"
                      onConfirm={(id) => this.props.removeFilter(index, x)}
                      okText="Yes"
                      placement="left"
                    >
                      <CloseCircleOutlined className="plusicon" />
                    </Popconfirm>
                  </Col>
                </Row>
              );
            })}

            {this.props.stateValues.appliedFilters &&
            this.props.stateValues.appliedFilters.length === 0 ? (
              <Row gutter={[6]}>
                <Col xs={24} sm={24} md={5} lg={3}>
                  <label className="lbl">Filter:</label>
                </Col>
                <Col xs={24} sm={24} md={3} lg={2}>
                  <PlusCircleOutlined
                    className="plusicon"
                    onClick={(e) => this.props.addFilter(e)}
                    style={{ marginRight: "5px" }}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row gutter={{ xs: 0, sm: 0, md: 16, lg: 32 }} className="filterrow">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="col_2">
            {this.props.stateValues.analysis.map((x, index) => {
              return (
                <Row gutter={[6]} key={index}>
                  <Col xs={24} sm={24} md={5} lg={3}>
                    {index === 0 ? (
                      <label className="lbl">Analysis:</label>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={5}>
                    <Select
                      showSearch
                      placeholder="Fields"
                      name="field"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.props.handleAnalysisSelectChange(
                          "field",
                          value,
                          index
                        );
                      }}
                      value={x.field ? x.field : null}
                    >
                      {stateValues.allFields.map((field, k) => {
                        let [tname, fname] = field.split(".");
                        return (
                          <Option key={field} value={field}>
                            {CamelCaseToSpaceLetter(
                              tname.substr(0, tname.length - 7)
                            ) +
                              " > " +
                              CamelCaseToSpaceLetter(fname)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={4}>
                    <Select
                      placeholder="Operator"
                      name="operator"
                      onChange={(value) => {
                        this.props.handleAnalysisSelectChange(
                          "operator",
                          value,
                          index
                        );
                      }}
                      value={x.operator ? x.operator : null}
                    >
                      <Option value="SUM">Sum</Option>
                      <Option value="AVG">Average</Option>
                      <Option value="COUNT">Count</Option>
                      <Option value="MIN">Min</Option>
                      <Option value="MAX">Max</Option>
                    </Select>
                  </Col>

                  <Col xs={24} sm={24} md={3} lg={2}>
                    <PlusCircleOutlined
                      className="plusicon"
                      onClick={(e) => this.props.addAnalysis(e)}
                      style={{ marginRight: "5px" }}
                    />

                    <Popconfirm
                      className="action"
                      title="Are you sure to delete ?"
                      onConfirm={(id) => this.props.removeAnalysis(index, x)}
                      okText="Yes"
                      placement="left"
                    >
                      <CloseCircleOutlined className="plusicon" />
                    </Popconfirm>
                  </Col>
                </Row>
              );
            })}

            {this.props.stateValues.analysis &&
            this.props.stateValues.analysis.length === 0 ? (
              <Row gutter={[6]}>
                <Col xs={24} sm={24} md={5} lg={3}>
                  <label className="lbl">Analysis:</label>
                </Col>
                <Col xs={24} sm={24} md={3} lg={2}>
                  <PlusCircleOutlined
                    className="plusicon"
                    onClick={(e) => this.props.addAnalysis(e)}
                    style={{ marginRight: "5px" }}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row className="checkboxrow">
          <Col>
            <label className="lbl">Display Options:</label>
          </Col>
          <Col>
            <Checkbox
              onChange={this.props.handleCheckboxChange("companyName")}
              checked={stateValues.displayOptions.companyName}
            >
              Include Company Name
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={this.props.handleCheckboxChange("logo")}
              checked={stateValues.displayOptions.logo}
            >
              Add Logo
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={this.props.handleCheckboxChange("reportGenerationDate")}
              checked={stateValues.displayOptions.reportGenerationDate}
            >
              Report Generate Date
            </Checkbox>
          </Col>
          <Col>
            <Checkbox
              onChange={this.props.handleCheckboxChange("signatureBlock")}
              checked={stateValues.displayOptions.signatureBlock}
            >
              Add Signature Block
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  }
}

export default ReportFilters;
