import React from "react";
import { Auth } from "aws-amplify";
import { Row, Col, Input, Button, Form, Divider, Space } from "antd";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

class VerifyAccount extends React.Component {
  state = {
    btnLoading: false,
    btnResendLoading: false,
    email: "",
    code: "",
    isResendEnable: true,
    remainSeconds: 30,
    isFromLogin: false,
  };

  formRef = React.createRef();

  componentDidMount() {
    let emailProps = this.props.location.state
      ? this.props.location.state.email
      : "";

    if (emailProps !== "") {
      this.formRef.current.setFieldsValue({ email: emailProps });
      this.setState({ email: emailProps, isFromLogin: true });
    }
  }

  onFinish = async (values) => {
    this.setState({ btnLoading: true });
    Auth.confirmSignUp(this.state.email, this.state.code)
      .then(() => {
        SuccessNotificationMsg(
          "Account verified successfully!",
          "Redirecting you to login in a few!"
        );
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
        this.props.history.push("/login");
      })
      .catch((err) => {
        ErrorNotificationMsg(err.message);
        this.setState({
          btnLoading: false,
        });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  resendVerificationCode = async () => {
    if (this.state.email === "") {
      ErrorNotificationMsg("Please enter valid email address.");
      return false;
    }

    if (
      //eslint-disable-next-line
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      ErrorNotificationMsg("Please enter valid email address.");
      return false;
    }

    this.setState({ btnResendLoading: true });

    try {
      await Auth.resendSignUp(this.state.email);
      SuccessNotificationMsg("code resent successfully");
      this.setState({ isResendEnable: false, btnResendLoading: false });
      this.startTimer();
      setTimeout(() => this.setState({ isResendEnable: true }), 30000);
    } catch (err) {
      ErrorNotificationMsg(err.message);
      this.setState({ isResendEnable: true, btnResendLoading: false });
    }
  };

  startTimer() {
    this.interval = setInterval(this.countDown, 1000);
  }

  countDown = () => {
    let seconds = this.state.remainSeconds - 1;
    this.setState({ remainSeconds: seconds });

    if (seconds === 0) {
      clearInterval(this.interval);
      this.setState({ remainSeconds: 30 });
    }
  };

  render() {
    const { isResendEnable } = this.state;
    const showResendCodeClass = isResendEnable === true ? "" : "hide";
    const showResendWaitClass = isResendEnable === false ? "" : "hide";

    return (
      <div className="authPage">
        <div className="container">
          <div className="reg_wraper acverfiy">
            <div className="pagename"><span>Account Verification</span></div>
            <p>Please verify your email using verification code.</p>

            <Form
              onFinish={this.onFinish}
              ref={this.formRef}
              autoComplete="off"
            >
              <Row gutter={[15]}>
                <Col xs={24} className={this.state.isFromLogin ? "hide" : ""}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid e-mail address!",
                      },
                      {
                        required: true,
                        message: "Please input e-mail address!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email Address *"
                      onChange={this.handleInputChange("email")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Please input verification code!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Verification Code *"
                      onChange={this.handleInputChange("code")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <div
                    className={showResendCodeClass}
                    style={{ float: "right" }}
                  >
                    <Button
                      onClick={this.resendVerificationCode}
                      size="small"
                      loading={this.state.btnResendLoading}
                    >
                      Resend Code
                    </Button>
                  </div>
                </Col>
                <Col xs={24}>
                  <div className={showResendWaitClass}>
                    Wait {this.state.remainSeconds} seconds to resend code
                    again.
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[15]} className="bottomButtons">
                <Col xs={24} align="center">
                  <Space>
                    <Button
                      type="secondary"
                      htmlType="button"
                      onClick={this.props.history.goBack}
                    >
                      BACK
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.btnLoading}
                    >
                      SUBMIT
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyAccount;
