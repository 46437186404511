import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import {
  Col,
  Row,
  Layout,
  Button,
  Table,
  Space,
  Breadcrumb,
  Popconfirm,
} from "antd";

import {
  PlusCircleOutlined,
  EditOutlined,
  HistoryOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import RecordMenu from "../common/RecordMenu";

import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

import {
  TRAINING_RECORD_LIST_QUERY,
  DELETE_TRAINING_RECORD,
} from "./TrainingRecordQuery";
import "../dashboard/Dashboard.scss";

const { Content } = Layout;
class ManageTrainingRecord extends Component {
  state = {
    datas: [],
    loading: false,
  };

  componentDidMount() {
    let personId = this.props.history.location.state
      ? this.props.history.location.state.personId
      : "";

    if (personId === "") {
      window.location.href = "/manage-employee";
    }

    this.fetchTrainingRecordList({
      personId: personId,
    });
  }

  fetchTrainingRecordList = (params) => {
    this.setState({ loading: true, personId: params.personId });
    const { client } = this.props;

    client
      .query({
        query: TRAINING_RECORD_LIST_QUERY,
        variables: params,
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          loading: false,
          data: result.data.getEmployeeTrainingRecords,
        });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  confirmDelete = (recordId, props) => {
    const { client } = props;
    client
      .mutate({
        mutation: DELETE_TRAINING_RECORD,
        variables: { recordId: recordId },
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter((s) => recordId !== s.trainingRecordId),
        });
        SuccessNotificationMsg(
          "Success",
          "Training Record deleted successfully."
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ loading: false });
      });
  };

  render() {
    const { data, loading, personId } = this.state;
    const columns = [
      {
        title: "Employee No",
        render: (node) => (
          <Link to={"/employee-detail/" + btoa(node.personId)}>
            {node.personId}
          </Link>
        ),
        key: "personId",
        width: "15%",
      },
      {
        title: "Training Record",
        dataIndex: "trainingRecord",
        key: "trainingRecord",
        width: "17%",
      },
      {
        title: "Training Type",
        dataIndex: "trainingType",
        key: "trainingType",
        width: "15%",
      },
      {
        title: "Class Name",
        dataIndex: "className",
        key: "className",
        width: "15%",
      },
      {
        title: "Instructor",
        dataIndex: "instructor",
        key: "instructor",
        width: "15%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: "13%",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "10%",
        render: (text, node) => (
          <Space size="middle">
            <Link
              to={"/training-record-detail/" + btoa(node.trainingRecordId)}
              className="action"
              title="View"
            >
              <EyeOutlined />
            </Link>
            <Link
              to={"/edit-training-record/" + btoa(node.trainingRecordId)}
              className="action"
              title="Edit"
            >
              <EditOutlined />
            </Link>
            <Link
              to={{
                pathname:
                  "/training-record-history/" + btoa(node.trainingRecordId),
                state: { personId: node.personId },
              }}
              className="action"
              title="History"
            >
              <HistoryOutlined />
            </Link>
            <Popconfirm
              className="action"
              title="Are you sure to delete ?"
              onConfirm={(id) =>
                this.confirmDelete(node.trainingRecordId, this.props)
              }
              okText="Yes"
              placement="left"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                Manage Training : {personId}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Manage Training</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <div className="productList">
                  <div className="noProduct">
                    <Row
                      gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }}
                      align="middle"
                    >
                      <Col flex="auto"></Col>
                      <Col flex="auto" align="end">
                        <Space>
                          <Button
                            type="primary"
                            htmlType="button"
                            size="small"
                            icon={<PlusCircleOutlined />}
                            onClick={() =>
                              this.props.history.push("/add-training-record", {
                                personId: personId,
                              })
                            }
                          >
                            Add Training
                          </Button>

                          <RecordMenu
                            history={this.props.history}
                            personId={personId}
                            buttonStyle={true}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    className="table_grid"
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 970 }}
                    pagination={false}
                    loading={loading}
                    rowKey={(record) => record.trainingRecordId}
                  />
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(ManageTrainingRecord);
