import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Auth } from "aws-amplify";
import {
  AppstoreOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  HistoryOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import whitelogo from "../../images/login-logo-white.png";
import { authUserData } from "../../utils/Helpers";

const { Sider } = Layout;
const { SubMenu } = Menu;
class Sidebar extends Component {
  state = {
    authUser: "",
    currentRoute: "/dashboard",
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  async componentDidMount() {
    const tokens = await Auth.currentSession();
    const userData = tokens.getIdToken().payload;
    this.setState({
      authUser: userData,
    });
  }

  render() {
    const { name } = this.state.authUser;
    const { location } = this.props;

    return (
      <Sider width={256} breakpoint="lg" collapsedWidth={0} collapsible>
        <Link to="/dashboard" className="logo">
          <img className="logoW" alt="logo" src={whitelogo}></img>
        </Link>
        <div className="profilebar">{name}</div>
        <div className="sidebar_menu">
          <Menu
            onClick={this.handleClick}
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={["connected-application"]}
            mode="inline"
          >
            <Menu.Item key="/dashboard" icon={<AppstoreOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="/manage-employee" icon={<FileDoneOutlined />}>
              <Link to="/manage-employee">Employees</Link>
            </Menu.Item>
            <Menu.Item key="/change-history" icon={<HistoryOutlined />}>
              <Link to="/change-history">Change History</Link>
            </Menu.Item>
            <Menu.Item key="/import-csv" icon={<UploadOutlined />}>
              <Link to="/import-csv">CSV Import </Link>
            </Menu.Item>

            {authUserData() &&
              authUserData().email &&
              authUserData().email === "jake@yopmail.com" && (
                <SubMenu
                  key="connected-application"
                  icon={<AppstoreAddOutlined />}
                  title="Connected Applications"
                >
                  <Menu.Item key="core-hr">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://webhris-2688b.qa.webhris.com/LoginSSO/LoginSSOSubscriber.aspx?UN=aTmoogJxV2JQ5DV5jXqJ8w==&PWD=udlmmkcrLxLr4J7ElDzsiQ=="
                    >
                      Canopy Core HR
                    </a>
                  </Menu.Item>
                  <Menu.Item key="bmsi-payroll">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://payroll.qa.webhris.com/PayEmp_ListView2/?conn=Sagadahoc&usr=Admin"
                    >
                      BMSI Payroll
                    </a>
                  </Menu.Item>
                  <Menu.Item key="paychex">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://myappsimp.paychex.com/"
                    >
                      Paychex
                    </a>
                  </Menu.Item>
                </SubMenu>
              )}

            <Menu.Item key="/report-list" icon={<FileDoneOutlined />}>
              <Link to="/report-list">Reporting</Link>
            </Menu.Item>
            <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://canopyws.github.io/qldb-documentation/"
              >
                Help
              </a>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);
