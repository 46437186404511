import React, { Component } from "react";
import { withApollo } from "react-apollo";
import moment from "moment";
import {
  Row,
  Col,
  Input,
  Form,
  Modal,
  Select,  
  Button,
  Space,
  Divider,
  Spin,
  DatePicker,
} from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import {
  GET_REPORT_SCHEDULER_QUERY,
  ADD_EDIT_SCHEDULER_QUERY,
  DELETE_SCHEDULER_QUERY,
} from "./SchedulerQuery";

const { Option } = Select;

class AddScheduler extends Component {
  state = {
    visible: false,
    btnLoading: false,
    apiLoading: false,
    reportId: null,
    recipients: [{ email: "" }],
    startDate: moment(),
  };

  formRef = React.createRef();

  componentDidMount() {
    this.setState({ reportId: this.props.reportId }, () => {
      this.getReportScheduler(this.state.reportId);
    });
  }

  getReportScheduler = (reportId) => {
    this.setState({ apiLoading: true });
    this.props.client
      .mutate({
        mutation: GET_REPORT_SCHEDULER_QUERY,
        variables: { reportId },
      })
      .then((response) => {
        this.setState({
          apiLoading: false,
          ...response.data.getReportExportScheduleById,
        });

        if (
          response.data.getReportExportScheduleById &&
          response.data.getReportExportScheduleById !== null
        ) {
          let mcStatArr = [];
          let mcFieldObject = {};

          if (response.data.getReportExportScheduleById.recipients.length > 0) {
            response.data.getReportExportScheduleById.recipients.map(
              (objectValue, objectKey) => {
                mcStatArr.push({ email: objectValue });
                mcFieldObject["email" + objectKey] = objectValue;
                return null;
              }
            );
          }
          this.setState({ recipients: mcStatArr });
          this.formRef.current.setFieldsValue(mcFieldObject);

          this.formRef.current.setFieldsValue(
            response.data.getReportExportScheduleById
          );
        }
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleSubmit = () => {
    let recipients = this.state.recipients.map((value) => value.email);
    this.setState({ btnLoading: true, recipients });

    this.props.client
      .mutate({
        mutation: ADD_EDIT_SCHEDULER_QUERY,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Schedule save successfully.");
        this.props.hideModal();
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  handleSelectChange = (value) => {
    this.setState({ frequency: value });
  };

  handleDatepickerChange = (dates, dateStrings) => {
    this.setState({ startDate: dateStrings });
  };

  addRecipients = () => {
    this.setState(
      {
        recipients: [...this.state.recipients, { email: "" }],
      },
      () => {
        this.setFieldValue(this.state.recipients);
      }
    );
  };

  handleRecipients = (e, index) => {
    const newRecepList = [...this.state.recipients];
    newRecepList[index][e.target.name] = e.target.value;
    this.setState({ recipients: newRecepList });
  };

  removeRecipients = (index) => {
    this.setState(
      { recipients: this.state.recipients.filter((s, sidx) => index !== sidx) },
      () => {
        this.setFieldValue(this.state.recipients);
      }
    );
  };

  setFieldValue = (data) => {
    let mcStatArr = [];
    let mcFieldObject = {};

    data.map((objectValue, objectKey) => {
      mcStatArr.push(objectValue);
      mcFieldObject["email" + objectKey] = objectValue.email;
      return null;
    });
    this.formRef.current.setFieldsValue(mcFieldObject);
  };

  deleteSchedule = () => {
    this.setState({ apiLoading: true });
    this.props.client
      .mutate({
        mutation: DELETE_SCHEDULER_QUERY,
        variables: { reportId: this.state.reportId },
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Schedule deleted successfully.");
        this.props.hideModal();
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  render() {
    const { btnLoading, apiLoading } = this.state;
    return (
      <Modal
        title={"Report Scheduler : " + this.props.reportName}
        visible={true}
        onCancel={this.props.hideModal}
        onOk={this.handleSubmit}
        header={false}
        footer={false}
        width={650}
        className="ReportScheduler"
      >
        <Spin spinning={apiLoading}>
          <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            autoComplete="off"
          >
            <Row gutter={[15]}>
              <Col xs={24} sm={24} lg={24}>
                <label>Schedule Frequency *</label>
                <Form.Item
                  name="frequency"
                  rules={[
                    {
                      required: true,
                      message: "Please select schedule frequency!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Schedule Frequency"
                    onChange={this.handleSelectChange}
                  >
                    <Option value="DAILY">Daily</Option>
                    <Option value="WEEKLY">Weekly</Option>
                    <Option value="MONTHLY">Monthly</Option>
                    <Option value="QUARTERLY">Quarterly</Option>
                    <Option value="YEARLY">Yearly</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} lg={24}>
                <label>Start Date *</label>
                <Form.Item>
                  <DatePicker
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    onChange={(date, dateString) =>
                      this.handleDatepickerChange(date, dateString)
                    }
                    placeholder="Start Date"
                    style={{ width: "100%" }}
                    value={
                      this.state.startDate !== ""
                        ? moment(this.state.startDate)
                        : ""
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} lg={24}>
                <label>Recipients *</label>

                {this.state.recipients.map((x, index) => {
                  return (
                    <Row gutter={[5]} className="addrow" key={index}>
                      <Col xs={22} sm={22} lg={22}>
                        <Form.Item
                          name={`email` + index}
                          rules={[
                            {
                              type: "email",
                              required: true,
                              whitespace: true,
                              message: "Please input email!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Email"
                            name="email"
                            onChange={(e) => this.handleRecipients(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={2} sm={2} lg={2}>
                        {index !== 0 ? (
                          <Button
                            type="secondary"
                            className="close"
                            size="small"
                            onClick={(id) => this.removeRecipients(index, x)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  type="link"
                  size="small"
                  onClick={(e) => this.addRecipients(e)}
                >
                  <PlusCircleOutlined /> Add Recipients
                </Button>
              </Col>
            </Row>

            <Divider />

            <Row gutter={[15]} className="bottomButtons">
              <Col xs={24} align="end">
                <Space
                  className={
                    this.state.scheduleId && this.state.scheduleId !== undefined
                      ? ""
                      : "scheduler-buttons"
                  }
                >
                  {this.state.scheduleId &&
                  this.state.scheduleId !== undefined ? (
                    <Button type="primary" onClick={this.deleteSchedule}>
                      Delete Schedule
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button type="primary" htmlType="submit" loading={btnLoading}>
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default withApollo(AddScheduler);
