import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Menu, Dropdown } from "antd";
import { logout } from "../../utils/Helpers";

import "antd/dist/antd.css";
import "./Common.scss";

import Profilepic from "../../images/profilephoto.png";
import darklogo from "../../images/logo-cn.png";
import Sidebar from "../common/Sidebar";
import {
  UserOutlined,
  LogoutOutlined,
  BellOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";

const { Header, Content } = Layout;

const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/dashboard">
        <i className="menu-icon">
          <AppstoreOutlined />
        </i>{" "}
        Dashboard
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/myaccount">
        <i className="menu-icon">
          <UserOutlined />
        </i>{" "}
        My Account
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="#" onClick={() => logout()}>
        <i className="menu-icon">
          <LogoutOutlined />
        </i>{" "}
        Logout
      </Link>
    </Menu.Item>
  </Menu>
);

export class AdminLayout extends Component {
  state = {
    visible: false,
    // authUserId: authUserData().appusersId,
    profilepic: Profilepic,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Layout>
        <Sidebar />
        <Layout>
          <Header>
            <div className="container-fluid">
              <Row align="middle">
                <Col flex="auto">
                  <Link to="/dashboard" className="hlogo">
                    <img className="logoD" alt="logo" src={darklogo}></img>
                  </Link>
                </Col>
                <Col align="end" className="rightside">
                  <Link to="/dashboard" className="headerbtn">
                    <BellOutlined />
                  </Link>

                  <div className="profilebtn">
                    <Dropdown overlay={menu} placement="bottomRight">
                      <div className="imgd profile-placeholder">
                        <img
                          className="cardicon"
                          alt="icon"
                          src={this.state.profilepic}
                        ></img>
                      </div>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </div>
          </Header>

          <Content>{this.props.children}</Content>
        </Layout>
      </Layout>
    );
  }
}

export default AdminLayout;
