import gql from "graphql-tag";

export const GET_ALL_REPORT_QUERY = gql`
  query getAllReports(
    $first: Int
    $offset: Int
    $sortBy: sortInput
    $searchQuery: String
    $favouritesOnly: Boolean
  ) {
    getAllReports(
      first: $first
      offset: $offset
      sortBy: $sortBy
      searchQuery: $searchQuery
      favouritesOnly: $favouritesOnly
    ) {
      reportId
      reportName
      reportCategory
      favourite
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation deleteReport($reportId: String!) {
    deleteReport(reportId: $reportId)
  }
`;

export const COPY_REPORT = gql`
  mutation copyReportFrom($reportId: String!) {
    copyReportFrom(reportId: $reportId)
  }
`;

export const GET_REPORT_FIELDS_QUERY = gql`
  query getReportWriterFormData($records: [String]) {
    getReportWriterFormData(records: $records) {
      recordId
      recordName
      fields {
        fieldId
        fieldName
        csvImport
      }
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation createReport(
    $reportName: String!
    $preview: Boolean
    $reportCategory: String!
    $selectedFields: [selectedFieldsInput]
    $appliedFilters: [reportFiltersInput]
    $displayOptions: displayOptionsInput
    $reportViewSortBy: [sortInput]
    $analysis: [analysisInput]
  ) {
    createReport(
      preview: $preview
      data: {
        reportName: $reportName
        reportCategory: $reportCategory
        selectedFields: $selectedFields
        appliedFilters: $appliedFilters
        displayOptions: $displayOptions
        reportViewSortBy: $reportViewSortBy
        analysis: $analysis
      }
    )
  }
`;

export const GET_REPORT_BY_ID = gql`
  query getReportById($reportId: String!) {
    getReportById(reportId: $reportId) {
      reportId
      reportName
      reportCategory
      selectedFields
      appliedFilters
      displayOptions
      reportViewSortBy
      analysis
      reportCreatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation updateReport(
    $reportId: String!
    $reportName: String!
    $reportCategory: String!
    $selectedFields: [selectedFieldsInput]
    $appliedFilters: [reportFiltersInput]
    $displayOptions: displayOptionsInput
    $reportViewSortBy: [sortInput]
    $analysis: [analysisInput]
  ) {
    updateReport(
      reportId: $reportId
      data: {
        reportName: $reportName
        reportCategory: $reportCategory
        selectedFields: $selectedFields
        appliedFilters: $appliedFilters
        displayOptions: $displayOptions
        reportViewSortBy: $reportViewSortBy
        analysis: $analysis
      }
    )
  }
`;

export const VIEW_REPORT_QUERY = gql`
  query viewReport($reportId: String!) {
    viewReport(reportId: $reportId)
  }
`;

export const EMAIL_REPORT = gql`
  mutation emailReport($reportId: String!, $recipients: [String]!) {
    emailReport(reportId: $reportId, recipients: $recipients)
  }
`;

export const FAVOURITE_REPORT = gql`
  mutation updateReport($reportId: String!, $favourite: Boolean) {
    updateReport(reportId: $reportId, data: { favourite: $favourite })
  }
`;

export const GET_FIELD_RECOMMENDATIONS = gql`
  query getReportWriterFieldRecommendations($fieldId: String!) {
    getReportWriterFieldRecommendations(fieldId: $fieldId)
  }
`;
