import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import {
  Col,
  Row,
  Button,
  Form,
  Space,
  Upload,
  Input,
  Divider,
  Spin,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import "../dashboard/Dashboard.scss";
import profileImg from "../../images/profile-noimg.png";

class EditProfile extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    ImageLoading: false,
    isEditMode: false,
    authUserId: authUserData().appusersId,
    userProfileImg: profileImg,
  };
  formRef = React.createRef();

  componentDidMount() {
    let authUser = authUserData();
    let nameArr = authUser.name.split(" ");

    let stateObj = {
      firstName: nameArr[0] !== undefined ? nameArr[0] : "",
      lastName: nameArr[1] !== undefined ? nameArr[1] : "",
      email: authUser["email"],
      phoneNumber: authUser["phone_number"],
      city: authUser["custom:city"],
      country: authUser["custom:country"],
      webhookURL: authUser["custom:webhookURL"],
    };

    this.setState({ ...stateObj, ...authUser });
    this.formRef.current.setFieldsValue(stateObj);
  }

  onFinish = (values) => {
    this.setState({ btnLoading: true });
    let phoneNumber = "+1" + this.state.phoneNumber;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, {
          email: this.state.email,
          name: `${this.state.firstName} ${this.state.lastName}`,
          phone_number: phoneNumber,
          "custom:city": this.state.city,
          "custom:country": this.state.country,
          "custom:webhookURL": this.state.webhookURL,
        });
      })
      .then((data) => {
        let lstorage = localStorage.getItem("auth_data");
        let authjson = JSON.parse(lstorage);

        authjson.email = this.state.email;
        authjson.name = `${this.state.firstName} ${this.state.lastName}`;
        authjson.phone_number = this.state.phoneNumber;
        authjson["custom:webhookURL"] = this.state.webhookURL;
        authjson["custom:country"] = this.state.country;
        authjson["custom:city"] = this.state.city;

        localStorage.setItem("auth_data", JSON.stringify(authjson));

        SuccessNotificationMsg("Success!", "Profile updated successfully.");
        this.setState({ btnLoading: false, isEditMode: false });
      })
      .catch((err) => {
        ErrorNotificationMsg("Error", err.message);
        this.setState({ btnLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  setPageMode = () => {
    this.setState({ isEditMode: !this.state.isEditMode });
  };

  render() {
    const { isEditMode, userProfileImg, ImageLoading, btnLoading } = this.state;
    const showViewProfileClass = isEditMode === false ? "" : "hide";
    const showEditProfileClass = isEditMode === true ? "" : "hide";

    const uploadProps = {
      accept: "images/*",
      listType: "picture-card",
      showUploadList: false,
    };

    return (
      <div className="myprofile">
        <div className="heading">
          <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
            <Col flex="auto">
              <h3>Profile</h3>
            </Col>
            <Col flex="auto" align="end">
              <Space>
                <Button
                  type="primary"
                  htmlType="button"
                  size="small"
                  icon={
                    this.state.isEditMode ? (
                      <ArrowLeftOutlined />
                    ) : (
                      <EditOutlined />
                    )
                  }
                  onClick={this.setPageMode}
                >
                  {this.state.isEditMode ? "Back" : "Edit"}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div className={showViewProfileClass}>
          <Spin spinning={this.state.apiLoading}>
            <Row gutter={0} className="prflrow">
              <Col xs={24} flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={userProfileImg} alt="profile-pic" />
                    <Upload
                      {...uploadProps}
                      customRequest={this.uploadProfilePic}
                    >
                      {/* <Button icon={<EditOutlined />}></Button> */}
                    </Upload>
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>First Name</label>
                    <Form.Item>{this.state.firstName}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Last Name</label>
                    <Form.Item>{this.state.lastName}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>E-mail</label>
                    <Form.Item>
                      {" "}
                      <a href={"mailto:" + this.state.email}>
                        {this.state.email}
                      </a>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Phone number</label>
                    <Form.Item>{this.state.phone_number}</Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>City</label>
                    <Form.Item>
                      {this.state.city === undefined ? "-" : this.state.city}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Country</label>
                    <Form.Item>
                      {this.state.country === undefined
                        ? "-"
                        : this.state.country}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Webhook URL</label>
                    <Form.Item>
                      {this.state.webhookURL === undefined
                        ? "-"
                        : this.state.webhookURL}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Spin>
        </div>

        <div className={showEditProfileClass}>
          <Form onFinish={this.onFinish} ref={this.formRef}>
            <Row gutter={0} className="prflrow">
              <Col flex="auto" className="profileimg">
                <Spin
                  tip="Uploading.."
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                  spinning={ImageLoading}
                >
                  <div className="imgdiv">
                    <img src={userProfileImg} alt="edit-profile-pic" />
                  </div>
                </Spin>
              </Col>
              <Col flex="auto" className="prflcont">
                <Row gutter={[15]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>First Name *</label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input first name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("firstName")}
                        placeholder="Enter first name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Last Name *</label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input last name!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("lastName")}
                        placeholder="Enter last name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>E-mail *</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid e-mail address!",
                        },
                        {
                          required: true,
                          message: "Please input e-mail address!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("email")}
                        placeholder="Enter e-mail address"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Phone number *</label>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp("^[0-9]{10}$"),
                          message: "Please input phone number!",
                        },
                      ]}
                    >
                      <Input
                        onChange={this.handleInputChange("phoneNumber")}
                        placeholder="Enter phone number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>City</label>
                    <Form.Item name="city">
                      <Input
                        onChange={this.handleInputChange("city")}
                        placeholder="Enter city"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Country</label>
                    <Form.Item name="country">
                      <Input
                        onChange={this.handleInputChange("country")}
                        placeholder="Enter country"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <label>Webhook URL</label>
                    <Form.Item name="webhookURL">
                      <Input
                        onChange={this.handleInputChange("webhookURL")}
                        placeholder="Enter webhook URL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row
              gutter={[15]}
              align="end"
              justify="space-between"
              className="bottomButtons"
            >
              <Col></Col>
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    onClick={this.setPageMode}
                  >
                    BACK
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    loading={btnLoading}
                  >
                    SAVE
                  </Button>
                </Space>
              </Col>
            </Row>
            <br />
          </Form>
        </div>
      </div>
    );
  }
}

export default withApollo(EditProfile);
