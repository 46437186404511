import gql from "graphql-tag";

export const ADD_JOB_QUALIFICATIONS = gql`
  mutation createJobQualificationsRecord(
    $personId: String!
    $type: String
    $subjectArea: String
    $description: String
    $proficiency: String
    $years: String
    $lastUsed: String
    $mandatory: String
  ) {
    createJobQualificationsRecord(
      personId: $personId
      data: {
        type: $type
        subjectArea: $subjectArea
        description: $description
        proficiency: $proficiency
        years: $years
        lastUsed: $lastUsed
        mandatory: $mandatory
      }
    )
  }
`;

export const JOB_QUALIFICATIONS_LIST_QUERY = gql`
  query getEmployeeJobQualificationsRecords($personId: String!) {
    getEmployeeJobQualificationsRecords(personId: $personId) {
      jobQualificationsId
      personId
      type
      subjectArea
      description
      proficiency
      years
      lastUsed
      mandatory
    }
  }
`;

export const GET_JOB_QUALIFICATIONS_QUERY = gql`
  query getEmployeeJobQualificationsRecordById($recordId: String!) {
    getEmployeeJobQualificationsRecordById(recordId: $recordId) {
      personId
      type
      subjectArea
      description
      proficiency
      years
      lastUsed
      mandatory
    }
  }
`;

export const UPDATE_JOB_QUALIFICATIONS = gql`
  mutation updateJobQualificationsRecord(
    $personId: String!
    $type: String
    $subjectArea: String
    $description: String!
    $proficiency: String
    $years: String
    $lastUsed: String
    $mandatory: String
  ) {
    updateJobQualificationsRecord(
      personId: $personId
      data: {
        type: $type
        subjectArea: $subjectArea
        description: $description
        proficiency: $proficiency
        years: $years
        lastUsed: $lastUsed
        mandatory: $mandatory
      }
    )
  }
`;

export const GET_JOB_QUALIFICATIONS_HISTORY = gql`
  query getEmployeeJobQualificationsRecordHistoryById($recordId: String!) {
    getEmployeeJobQualificationsRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        jobQualificationsId
        personId
        type
        subjectArea
        description
        proficiency
        years
        lastUsed
        mandatory

        recordSource
      }
    }
  }
`;

export const DELETE_JOB_QUALIFICATIONS = gql`
  mutation deleteJobQualificationsRecord($recordId: String!) {
    deleteJobQualificationsRecord(recordId: $recordId)
  }
`;
