import gql from "graphql-tag";

export const ADD_PAID_TIME_OFF = gql`
  mutation createPaidTimeOffRecord(
    $personId: String!
    $startDate: Date
    $endDate: Date
    $leaveType: String
    $leaveStatus: String
    $reason: String
  ) {
    createPaidTimeOffRecord(
      personId: $personId
      data: {
        startDate: $startDate
        endDate: $endDate
        leaveType: $leaveType
        leaveStatus: $leaveStatus
        reason: $reason
      }
    )
  }
`;

export const PAID_TIME_OFF_LIST_QUERY = gql`
  query getEmployeePaidTimeOffRecords($personId: String!) {
    getEmployeePaidTimeOffRecords(personId: $personId) {
      paidTimeOffId
      personId
      startDate
      endDate
      leaveType
      leaveStatus
      reason
    }
  }
`;

export const GET_PAID_TIME_OFF_QUERY = gql`
  query getEmployeePaidTimeOffRecordById($recordId: String!) {
    getEmployeePaidTimeOffRecordById(recordId: $recordId) {
      personId
      startDate
      endDate
      leaveType
      leaveStatus
      reason
    }
  }
`;

export const UPDATE_PAID_TIME_OFF = gql`
  mutation updatePaidTimeOffRecord(
    $personId: String!
    $startDate: Date!
    $endDate: Date!
    $leaveType: String
    $leaveStatus: String
    $reason: String
  ) {
    updatePaidTimeOffRecord(
      personId: $personId
      data: {
        startDate: $startDate
        endDate: $endDate
        leaveType: $leaveType
        leaveStatus: $leaveStatus
        reason: $reason
      }
    )
  }
`;

export const GET_PAID_TIME_OFF_HISTORY = gql`
  query getEmployeePaidTimeOffRecordHistoryById($recordId: String!) {
    getEmployeePaidTimeOffRecordHistoryById(recordId: $recordId) {
      hash
      blockAddress
      metadata {
        id
        version
        txTime
        txId
      }
      data {
        startDate
        endDate
        leaveType
        leaveStatus
        reason
        recordSource
      }
    }
  }
`;

export const DELETE_PAID_TIME_OFF = gql`
  mutation deletePaidTimeOffRecord($recordId: String!) {
    deletePaidTimeOffRecord(recordId: $recordId)
  }
`;
