import React from "react";
import { Col, Form, Space } from "antd";
import HistoryDetail from "./HistoryDetail";
import { CamelCaseToSpaceLetter } from "../../utils/Helpers";

function HistoryFields(props) {
  let { fieldsObject, historyData, showHistoryButton } = props;
  delete fieldsObject.__typename;

  return (
    <React.Fragment>
      {Object.entries(fieldsObject).map(([key, value], i) => (
        <Col xs={24} sm={12} md={12} lg={12} xl={8} key={i}>
          <Space className="labelheader">
            <label>{CamelCaseToSpaceLetter(key)}</label>
            {showHistoryButton ? (
              <HistoryDetail title={key} historyData={historyData} />
            ) : (
              ""
            )}
          </Space>
          <Form.Item>{value === "" ? "-" : value}</Form.Item>
        </Col>
      ))}
    </React.Fragment>
  );
}

export default HistoryFields;
