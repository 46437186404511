import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Layout,
  Button,
  Form,
  Divider,
  Space,
  Breadcrumb,
  Spin,
} from "antd";
import InputField from "../common/InputField";

import "../dashboard/Dashboard.scss";
import { authUserData } from "../../utils/Helpers";
import {
  SuccessNotificationMsg,
  ErrorNotificationMsg,
} from "../../utils/NotificationHelper";

import {
  ADD_EMPLOYEE,
  GET_EMPLOYEE_QUERY,
  UPDATE_EMPLOYEE,
} from "./EmployeeQuery";

const { Content } = Layout;
class AddEmployee extends Component {
  state = {
    btnLoading: false,
    apiLoading: false,
    authUserId: authUserData().sub,
    currentRoute: this.props.location.pathname,
    fieldArr: [
      "firstName",
      "middleName",
      "lastName",
      "suffix",
      "alias",
      "salutation",
      "street1",
      "street2",
      "city",
      "stateOrProvince",
      "zipOrPostalCode",
      "homeEmail",
      "homePhone",
      "mobileNumber",
      "ssnOrsin",
      "birthDate",
      "gender",
      "driverLicenceNumber",
      "driverLicenceState",
      "driverLicenceExpires",
      "maritalStatus",
      "countryOrRegion",
      "licenceCountry",
      "cellProviderType",
      "legalSuffix",
      "status",
    ],
    statusField: ["status"],
  };
  formRef = React.createRef();

  componentDidMount() {
    let employeeId = this.props.match.params.employeeId
      ? atob(this.props.match.params.employeeId)
      : "";

    if (employeeId !== "") {
      this.getEmployeeDetail(employeeId);
      this.setState({ employeeId });
    }
  }

  onFinish = (values) => {
    if (this.state.personId === "" || this.state.personId === undefined) {
      this.saveEmployee();
    } else {
      this.updateEmployee();
    }
  };

  saveEmployee = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: ADD_EMPLOYEE,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg("Success", "Employee data saved successfully.");
        this.formRef.current.resetFields();
        this.setState({ btnLoading: false });
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  updateEmployee = () => {
    const { client } = this.props;
    this.setState({ btnLoading: true });

    client
      .mutate({
        mutation: UPDATE_EMPLOYEE,
        variables: this.state,
      })
      .then((result) => {
        SuccessNotificationMsg(
          "Success",
          "Employee data updated successfully."
        );
        this.formRef.current.resetFields();
        this.props.history.push("/manage-employee");
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ btnLoading: false });
      });
  };

  getEmployeeDetail = (employeeId) => {
    const { client } = this.props;
    this.setState({ apiLoading: true });
    client
      .mutate({
        mutation: GET_EMPLOYEE_QUERY,
        variables: { personId: employeeId },
      })
      .then((response) => {
        this.setState(response.data.getEmployeePersonalInformationRecord);
        this.setState({ apiLoading: false });
        this.formRef.current.setFieldsValue(
          response.data.getEmployeePersonalInformationRecord
        );
      })
      .catch((e) => {
        ErrorNotificationMsg("Error", e.message.replace("GraphQL error:", ""));
        this.setState({ apiLoading: false });
      });
  };

  handleInputChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSelectChange = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      fieldArr,
      currentRoute,
      apiLoading,
      btnLoading,
      statusField,
    } = this.state;
    return (
      <div className="contentpart">
        <Layout>
          <Content>
            <div className="right_content_col">
              <div className="pagename">
                {currentRoute === "/add-employee" ? "Add" : "Edit"} Employee
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/manage-employee">Manage Employee</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {currentRoute === "/add-employee" ? "Add" : "Edit"} Employee
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="content_wraper dashboardPage">
                <Spin spinning={apiLoading}>
                  <div className="quotewraper">
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                      <Row gutter={[15]}>
                        {fieldArr.map((field, i) => {
                          return (
                            <InputField
                              key={i}
                              title={field}
                              name={field}
                              handleInputChange={this.handleInputChange}
                              statusField={statusField}
                              handleSelectChange={this.handleSelectChange}
                            />
                          );
                        })}
                      </Row>

                      <Divider />
                      <Row
                        gutter={[15]}
                        justify="space-between"
                        className="bottomButtons"
                      >
                        <Col></Col>
                        <Col>
                          <Space>
                            <Button
                              type="secondary"
                              htmlType="button"
                              onClick={this.props.history.goBack}
                            >
                              BACK
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={btnLoading}
                            >
                              SUBMIT
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default withApollo(AddEmployee);
