import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Row, Col, Button } from "antd";
import { CamelCaseToSpaceLetter, ToDateTime } from "../../utils/Helpers";
import { PrinterOutlined } from "@ant-design/icons";

const pageStyle = `
  .print-item{
    margin-bottom: 10px;
    font-size:16px;
    padding-bottom:10px;
    border-bottom:1px solid #f1f1f1;
  }

  .print-title{
    font-size:22px;
    padding:10px;
    background:#f5f5f5;
    font-weight:bold;
    margin-bottom:16px;
    text-align:center;
  }

  h4{
    font-size:18px;
  }

  @media all {
    .page-break {
      display: none;
    }
 
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const HistoryDetailPrint = ({ historyData }) => {
  const componentRef = useRef();

  return (
    <React.Fragment>
      <ReactToPrint
        trigger={() => (
          <Row justify="end" gutter={16} >
            <Col >
              <Button className="print-btn"  icon={<PrinterOutlined />} size="small">
                Print
              </Button>
            </Col>
            </Row>          
        )}
        pageStyle={pageStyle}
        content={() => componentRef.current}
      />

      <div style={{ display: "none" }} >
        
        <div ref={componentRef}>
        <div className="print-title">Record Detail</div>
          <Row gutter={16} className="printhistory">
            <Col xs={24}>
              <h4>Version</h4>
              <div className="print-item">{historyData.metadata.version}</div>
            </Col>
            <Col xs={24}>
              <h4>Transaction Id</h4>
              <div className="print-item">{historyData.metadata.txId}</div>
            </Col>
            <Col xs={24}>
              <h4>Transaction Time</h4>
              <div className="print-item">{ToDateTime(historyData.metadata.txTime)}</div>
            </Col>
            <Col xs={24}>
              <h4>Hash</h4>
              <div className="print-item">{historyData.hash}</div>
            </Col>
            {Object.entries(historyData.data).map(([key, value], i) => (
              <Col xs={24} key={key}>
                <h4>{CamelCaseToSpaceLetter(key)}</h4>
                <div className="print-item">
                  {value === "" || value === "null" ? "-" : value}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
